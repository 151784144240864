import XiboLogin from "./views/Authentification/XiboLogin";
import DocHome from "./views/DocBoard/DocHome";
import DocAnnonces from "./views/DocBoard/DocAnnonces";
import DocLibrary from "./views/DocBoard/DocLibrary";
import AdHome from "./views/AdBoard/AdHome";
import AdBornes from "./views/AdBoard/AdBornes";

import AdminHome from "./views/Admin/AdminHome";
import AdminPrinter from "./views/Admin/AdminPrinter";

import PublicOrigami from "./views/Public/PublicOrigami";
import PublicStory from "./views/Public/PublicStory";
import DocCustomLibrary from "./views/DocBoard/DocCustomLibrary";
import Gestionnaire from "./views/Admin/Gestionnaire";
import Interfaces from "./views/Admin/Interfaces";
import LeadsPage from "./views/Admin/LeadsPage";
import AdminNotifs from "./views/Admin/AdminNotifs";
import AdminBornes from "./views/Admin/AdminBornes";
import AutoVideoDash from "./views/Admin/AdminAutoVideo";

const routes = [
  {
    path: "/xlogin",
    name: "XiboLogin",
    component: XiboLogin,
    layout: "/auth",
  },
  // ------------------------------------  DOCTOR  ------------------------------------
  {
    path: "/annonces",
    name: "Mes annonces",
    component: DocAnnonces,
    layout: "/docboard",
  },
  {
    path: "/library",
    name: "Catalogue d'annonces",
    component: DocLibrary,
    layout: "/docboard",
  },
  {
    path: "/librarycustom",
    name: "Catalogue personnalisé",
    component: DocCustomLibrary,
    layout: "/docboard",
  },
  {
    path: "/home",
    name: "Annonces Externes",
    component: DocHome,
    layout: "/docboard",
  },
  // ------------------------------------  ANNONCEUR  ------------------------------------
  {
    path: "/home",
    name: "Annonces",
    component: AdHome,
    layout: "/adboard",
  },
  {
    path: "/diffusion",
    name: "Lieux de diffusion",
    component: AdBornes,
    layout: "/adboard",
  },
  // ------------------------------------  ADMIN  ------------------------------------
  {
    path: "/gestionnaire",
    name: "Gestionnaire",
    component: Gestionnaire,
    layout: "/admin",
  },
  {
    path: "/home",
    name: "Annonces",
    component: AdminHome,
    layout: "/admin",
  },
  {
    path: "/autovideo",
    name: "Vidéo Auto",
    component: AutoVideoDash,
    layout: "/admin",
  },
  {
    path: "/interfaces",
    name: "Interfaces",
    component: Interfaces,
    layout: "/admin",
  },
  {
    path: "/paper",
    name: "Printers",
    component: AdminPrinter,
    layout: "/admin",
  },
  {
    path: "/notifs",
    name: "Notifs",
    component: AdminNotifs,
    layout: "/admin",
  },
  {
    path: "/kiosks",
    name: "Bornes Infos",
    component: AdminBornes,
    layout: "/admin",
  },
  {
    path: "/leads",
    name: "Leads",
    component: LeadsPage,
    layout: "/admin",
  },
  // ------------------------------------  PUBLIC ?????  ------------------------------------
  {
    path: "/origami",
    name: "Origami",
    component: PublicOrigami,
    layout: "/public",
  },
  {
    path: "/histoires",
    name: "story",
    component: PublicStory,
    layout: "/public",
  },
];

export default routes;

import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
  OverlayTrigger,
  Tooltip,
  Alert,
} from "react-bootstrap";
import { BsFillPlusCircleFill, BsPlus } from "react-icons/bs";
import { FaRegTrashAlt } from "react-icons/fa";
import { MdRefresh } from "react-icons/md";
import AutoVidServices from "../../services/AutoVideoServices";
import { RiVideoAddFill } from "react-icons/ri";

function BouclesTemp({ xiboVid, btemps, refreshBTemps }) {
  const [template, setTemplate] = useState(undefined);
  const [mode, setMode] = useState(undefined);
  const [selected, setSelected] = useState("");

  const [name, setName] = useState("");
  const [isUnique, setIsUnique] = useState(false);
  const [dynamic, setDynamic] = useState(true);
  const [dynRecurrence, setDynRecurrence] = useState("");
  const [videos, setVideos] = useState([]);
  const [dated, setDated] = useState(false);
  const [scheduledDates, setScheduledDates] = useState([]);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");

  const [sC, sSC] = useState(0);

  const recurrencies = ["week", "bi-month", "month"];

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (dynamic) {
      if (videos.length === 0) {
        setAlertMessage("Please add at least one video for dynamic template.");
        setAlertType("warning");
        setShowAlert(true);
        return;
      }

      for (let data of videos) {
        if (data === "") {
          setAlertMessage(
            "Please select at least one video for dynamic slot added, or delete it."
          );
          setAlertType("warning");
          setShowAlert(true);
          return;
        }
      }
    } else if (dated) {
      if (scheduledDates.length === 0) {
        setAlertMessage(
          "Please add at least one video and date, for dated template."
        );
        setAlertType("warning");
        setShowAlert(true);
        return;
      }
      for (let data of scheduledDates) {
        if (data.date === "" || data.video_xibo_id === "") {
          setAlertMessage(
            "Please select a date and a video for the event added."
          );
          setAlertType("warning");
          setShowAlert(true);
          return;
        }
      }
    }

    if (mode === "edit") {
      PatchBoucleTemp();
    } else {
      PostNewBoucleTemp();
    }
  };

  async function PostNewBoucleTemp() {
    const bodyContent = {
      name: name,
      is_unique: isUnique,
      dynamic: dynamic,
      dyn_rec: dynRecurrence,
      videos: dynamic ? videos : [],
      dated: dated,
      dated_sched: dated ? scheduledDates : [],
    };

    const created = await AutoVidServices.PostBoucleTemp(bodyContent);
    if (created.success) {
      setAlertMessage("Template created successfully");
      setAlertType("success");
      setShowAlert(true);

      refreshBTemps();
      resetSelection();
    } else {
      setAlertMessage("Failed to create template");
      setAlertType("danger");
      setShowAlert(true);
    }
  }

  async function PatchBoucleTemp() {
    let body = {};
    let original = btemps?.find((temp) => temp._id === template._id);

    if (original.name !== name) {
      body.name = name;
    }
    if (original.is_unique !== isUnique) {
      body.is_unique = isUnique;
    }

    if (original.dynamic !== dynamic) {
      body.dynamic = dynamic;
    }
    if (original.dyn_rec !== dynRecurrence) {
      body.dyn_rec = dynRecurrence;
    }
    if (original.videos !== videos) {
      body.videos = videos;
    }

    if (original.dated !== dated) {
      body.dated = dated;
    }
    if (original.dated_sched !== scheduledDates) {
      body.dated_sched = scheduledDates;
    }

    const update = await AutoVidServices.PatchBoucleTemp(template._id, body);

    if (update.success) {
      setAlertMessage("Template updated successfully");
      setAlertType("success");
      setShowAlert(true);

      refreshBTemps();
      resetSelection();
    } else {
      setAlertMessage("Failed to update template");
      setAlertType("danger");
      setShowAlert(true);
    }
  }

  async function DeleteBoucleTemp(e) {
    e.preventDefault();
    const deleted = await AutoVidServices.DeleteBoucleTemp(template._id);

    if (deleted.success) {
      setAlertMessage("Template deleted successfully");
      setAlertType("success");
      setShowAlert(true);

      refreshBTemps();
      resetSelection();
    } else {
      setAlertMessage(
        deleted.error?.response?.data?.message ||
          "Failed to delete template. Maybe in use by a config ?"
      );
      setAlertType("danger");
      setShowAlert(true);
    }
  }

  const resetSelection = () => {
    setSelected("");
    setTemplate(undefined);
    setName("");

    setIsUnique(false);
    setDynamic(true);
    setDated(false);
    setDynRecurrence("");
    setVideos([]);
    setScheduledDates([]);

    setMode(undefined);
  };

  const selectBoucleTemp = (e) => {
    const target = btemps?.find((template) => template.name === e.target.value);
    setTemplate(target);
    setSelected(target.name);

    setName(target.name);
    setIsUnique(target.is_unique);

    setDynamic(target.dynamic);
    setDynRecurrence(target.dyn_rec);
    setVideos(target.videos || []);

    setDated(target.dated);
    setScheduledDates(target.dated_sched || []);

    setMode("edit");
  };

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  }, [showAlert]);

  return (
    <Container className="forms-deco">
      <Container>
        <Form>
          <Form.Row>
            <Form.Group as={Col} controlId="xibo_controller">
              {mode !== "new" ? (
                <Form.Control
                  as="select"
                  onChange={selectBoucleTemp}
                  value={selected}
                >
                  <option value={""}> -- Select --</option>
                  {btemps?.map((template) => (
                    <option key={template._id} value={template.name}>
                      {template.name}
                    </option>
                  ))}
                </Form.Control>
              ) : (
                <></>
              )}
            </Form.Group>

            <Form.Group
              as={Col}
              xs={3}
              controlId="actions"
              className="text-right"
            >
              {mode ? (
                <>
                  <Button
                    style={{
                      borderRadius: "50%",
                      backgroundColor: "unset",
                      border: "none",
                      padding: "0px",
                      marginRight: "20px",
                    }}
                    onClick={resetSelection}
                  >
                    <MdRefresh size={28} color="grey" />
                  </Button>
                  {template && sC > 6 ? (
                    <Button
                      style={{
                        borderRadius: "50%",
                        backgroundColor: "unset",
                        border: "none",
                        padding: "0px",
                      }}
                      onClick={(e) => DeleteBoucleTemp(e)}
                    >
                      <FaRegTrashAlt size={28} color="grey" />
                    </Button>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Button
                  style={{
                    borderRadius: "50%",
                    backgroundColor: "unset",
                    border: "2px solid grey",
                    padding: "0px",
                  }}
                  onClick={() => setMode("new")}
                >
                  <BsPlus size={32} color="grey" />
                </Button>
              )}
            </Form.Group>
          </Form.Row>
        </Form>
      </Container>
      <Container>
        <Row>
          {mode ? (
            <Col md={6} className="mx-auto">
              <Form onSubmit={handleSubmit}>
                <Form.Group
                  className="mb-3"
                  as={Row}
                  style={{ alignContent: " center", alignItems: "center" }}
                >
                  <InputGroup
                    className="mb-3"
                    as={Col}
                    xs={8}
                    style={{ alignContent: " center", alignItems: "center" }}
                  >
                    <Form.Label
                      style={{ marginRight: "10px" }}
                      onClick={() => sSC(sC + 1)}
                    >
                      Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Enter template name"
                      required
                    />
                  </InputGroup>
                  <OverlayTrigger
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-unique`}>
                        Une boucle unique ne peut être
                        <strong> présente </strong>qu'une
                        <strong> seule fois</strong> par
                        <strong> borne</strong>.
                      </Tooltip>
                    }
                  >
                    <InputGroup
                      className="mb-3"
                      as={Col}
                      xs={4}
                      onClick={() => setIsUnique(!isUnique)}
                    >
                      <Form.Check
                        type="checkbox"
                        label="Is Unique?"
                        checked={isUnique}
                        readOnly
                        className="ml-auto mr-1"
                      />
                    </InputGroup>
                  </OverlayTrigger>
                </Form.Group>
                <Form.Row className="mb-3" style={{ flexWrap: "nowrap" }}>
                  <Form.Label column="sm" md={2} style={{ fontWeight: "700" }}>
                    Boucle Type
                  </Form.Label>
                  <Form.Group
                    // column="lg"
                    className="mb-3 flex flex-row"
                    style={{
                      justifyContent: "space-evenly",
                      width: "-webkit-fill-available",
                    }}
                    as={Row}
                  >
                    <OverlayTrigger
                      placement={"top"}
                      overlay={
                        <Tooltip id={`tooltip-dynamic`}>
                          Une <strong>boucle dynamique</strong> change
                          <strong>aléatoirement de contenu</strong> en fonction
                          de la <strong> récurrence</strong>.
                        </Tooltip>
                      }
                    >
                      <InputGroup
                        className="w-auto"
                        onClick={(e) => {
                          setDynamic(true);
                          setDated(false);
                          setScheduledDates([]);
                        }}
                      >
                        <Form.Check
                          type="checkbox"
                          checked={dynamic}
                          readOnly
                        />

                        <Form.Label style={{ fontWeight: "400" }}>
                          Dynamic
                        </Form.Label>
                      </InputGroup>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement={"top"}
                      overlay={
                        <Tooltip id={`tooltip-dynamic`}>
                          Une <strong>boucle dated change</strong> de{" "}
                          <strong>contenu</strong> en fonction des{" "}
                          <strong>dates de fin</strong> de{" "}
                          <strong>chaque vidéo</strong>.
                        </Tooltip>
                      }
                    >
                      <InputGroup
                        className="w-auto"
                        onClick={(e) => {
                          setDated(true);
                          setDynamic(false);
                          setVideos([]);
                          setDynRecurrence("");
                        }}
                      >
                        <Form.Check
                          type="checkbox"
                          //   label="Dated"
                          checked={dated}
                          readOnly
                        />
                        <Form.Label style={{ fontWeight: "400" }}>
                          Dated
                        </Form.Label>
                      </InputGroup>
                    </OverlayTrigger>
                  </Form.Group>
                </Form.Row>

                <Form.Group className="mb-4">
                  {dynamic && (
                    <>
                      <InputGroup className="mb-3">
                        <InputGroup.Text>Récurrence</InputGroup.Text>
                        <Form.Control
                          as="select"
                          onChange={(e) => setDynRecurrence(e.target.value)}
                          value={dynRecurrence}
                          required
                        >
                          <option value={""}> -- Select --</option>
                          {recurrencies.map((rec, index) => (
                            <option key={index} value={rec}>
                              {rec}
                            </option>
                          ))}
                        </Form.Control>
                      </InputGroup>
                    </>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" as={Row}>
                  <InputGroup className="mb-3" as={Col} xs={2}>
                    {(dated || dynamic) && (
                      <Button
                        onClick={() => {
                          if (dynamic) {
                            setVideos([...videos, ""]);
                          } else {
                            setScheduledDates([
                              ...scheduledDates,
                              { date: "", video_xibo_id: "" },
                            ]);
                          }
                        }}
                        className="mb-3"
                        style={{ height: "fit-content" }}
                      >
                        <RiVideoAddFill size={20} />
                      </Button>
                    )}
                  </InputGroup>
                  <InputGroup className="mb-3" as={Col} xs={10}>
                    {dynamic &&
                      videos.length > 0 &&
                      videos?.map((video, index) => (
                        <InputGroup className="mb-3" key={index}>
                          <Form.Control
                            as="select"
                            onChange={(e) => {
                              const newVideos = [...videos];
                              newVideos[index] = e.target.value;
                              setVideos(newVideos);
                            }}
                            value={video}
                            required
                          >
                            <option value={""}> -- Select --</option>
                            {/* {xibovideos.map((xibovideo) => ( */}
                            {xiboVid?.map((xibovideo) => (
                              <option key={xibovideo._id} value={xibovideo._id}>
                                {xibovideo.name}
                              </option>
                            ))}
                          </Form.Control>
                          <Button
                            variant="danger"
                            onClick={() => {
                              const newVideos = videos.filter(
                                (_, i) => i !== index
                              );
                              setVideos(newVideos);
                            }}
                          >
                            <FaRegTrashAlt color="white" />
                          </Button>
                        </InputGroup>
                      ))}

                    {dated &&
                      scheduledDates.map((date, index) => (
                        <InputGroup className="mb-3" key={index}>
                          <FormControl
                            type="date"
                            value={
                              date?.date
                                ? new Date(date?.date)
                                    .toISOString()
                                    .split("T")[0]
                                : ""
                            }
                            onChange={(e) => {
                              const newDates = [...scheduledDates];
                              newDates[index].date = e.target.value;
                              setScheduledDates(newDates);
                            }}
                            placeholder="Date de fin"
                            min={new Date().toISOString().split("T")[0]}
                            required
                          />
                          <Form.Control
                            as="select"
                            onChange={(e) => {
                              const newDates = [...scheduledDates];
                              newDates[index].video_xibo_id = e.target.value;
                              setScheduledDates(newDates);
                            }}
                            value={date.video_xibo_id}
                            required
                          >
                            <option value={""}> -- Select video --</option>
                            {xiboVid?.map((xibovideo) => (
                              <option key={xibovideo._id} value={xibovideo._id}>
                                {xibovideo.name}
                              </option>
                            ))}
                          </Form.Control>
                          <Button
                            variant="danger"
                            onClick={() => {
                              const newDates = scheduledDates.filter(
                                (_, i) => i !== index
                              );
                              setScheduledDates(newDates);
                            }}
                          >
                            <FaRegTrashAlt color="white" />
                          </Button>
                        </InputGroup>
                      ))}
                  </InputGroup>
                </Form.Group>
                <Form.Group
                  className="mb-3 mt-2"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Button variant="success" type="submit" className="flex-end">
                    {mode === "edit" ? "Modifier" : "Créer"}
                  </Button>
                </Form.Group>
              </Form>
            </Col>
          ) : (
            <></>
          )}
        </Row>
      </Container>
      {showAlert && (
        <div className="alert-container-central">
          <Alert id="Annonces_Alert" variant={alertType}>
            <b>{alertMessage}</b>
          </Alert>
        </div>
      )}
    </Container>
  );
}

export default BouclesTemp;

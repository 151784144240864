import axios from "axios";
// import { XapiURL, GestionURL, Xpress } from "../APIs/api-xibo";
import { XapiURL, GestionURL, Xpress, XpressNoApi } from "../APIs/api-xibo";
import { Alert } from "react-bootstrap";
import { useCookies } from "react-cookie";

export async function UploadImage(file, name) {
  const cookies = useCookies(["access_token"]);
  console.log(file);
  console.log(name);
  let formData = new FormData();
  formData.append("file", file);
  formData.append("name", name);
  console.log(formData);
  // return await Xpress.post("/upload", formData, {
  //     headers: {
  //     "Content-Type": "multipart/form-data",
  //     },
  // });

  const headers = {
    "Content-Type": "multipart/form-data",
    Autorization: `Bearer ${cookies.access_token}`,
  };
  console.log(headers);

  const Xpress = axios.create({
    baseURL: `${XapiURL}/api`,
    withCredentials: true,
    crossDomain: true,
    // origin: "https://gestion.lisaconnect.fr",
    origin: GestionURL,
  });

  try {
    const response = await Xpress.post("/bornes/images", formData, headers);
    console.log(response);
    if (response.status === 200) {
      return response.data;
    } else {
      return "Image not uploaded";
    }
  } catch (error) {
    // return Alert.error(error);
    console.log(error);
    return error;
  }
}

export async function GetAllInterfaces() {
  try {
    const response = await Xpress.get("/bornes/data/all");
    if (response.status === 200) {
      return { data: response.data.data, error: false };
    } else {
      return { data: "Interfaces not found", error: true };
    }
  } catch (error) {
    console.error(error);
    return { data: error, error: true };
  }
}

// export async function CreateInterface(data) {
//   try {
//     const response = await Xpress.post("/bornes/data", data);
//     if (response.status === 201) {
//       return { data: response.data, error: false };
//     } else {
//       return { data: "Interface not created", error: true };
//     }
//   } catch (error) {
//     console.error(error);
//     return { data: error, error: true };
//   }
// }

export async function DeleteInterface(id) {
  try {
    const response = await Xpress.delete(`/bornes/data/${id}`);
    if (response.status === 200) {
      return { data: response.data, error: false };
    } else {
      return { data: "Interface not deleted", error: true };
    }
  } catch (error) {
    console.error(error);
    return { data: error, error: true };
  }
}

export async function UploadLinkImg(fileInput, fileName) {
  const formData = new FormData();
  formData.append("file", fileInput);
  formData.append("name", fileName);
  try {
    const response = await Xpress.post("/bornes/images", formData);
    if (response.status === 200) {
      return { data: response.data, error: false };
    } else {
      return { data: "Image not uploaded", error: true };
    }
  } catch (error) {
    console.error(error);
    return { data: error, error: true };
  }
}

export async function PostInterfaceLinks(data) {
  const formData = new FormData();
  formData.append("ip", "");
  formData.append("RefClient", data[1].value);
  formData.append("NbLiens", data[2].value);
  formData.append("L1", data[3].value);
  formData.append("L2", data[5].value);
  formData.append("L3", data[7].value);
  formData.append("L4", data[9].value);
  formData.append("L5", data[11].value);
  formData.append("L6", data[13].value);
  formData.append("I1", data[4].value);
  formData.append("I2", data[6].value);
  formData.append("I3", data[8].value);
  formData.append("I4", data[10].value);
  formData.append("I5", data[12].value);
  formData.append("I6", data[14].value);
  formData.append("Sudoku", "");
  formData.append("Origami", "");
  formData.append("Histoire", "");

  try {
    const response = await Xpress.post(
      `/bornes/data/${data[0].value}`,
      formData
    );
    if (response.status === 200) {
      return { data: response.data, error: false };
    } else {
      return { data: "Interface not created", error: true };
    }
  } catch (error) {
    console.error(error);
    return { data: JSON.stringify(error), error: true };
  }
}

export async function ModifyInterfaceLinks(id, data) {
  const formDataMod = new FormData();
  formDataMod.append("id", id);
  formDataMod.append("ip", "");
  formDataMod.append("RefClient", data[1].value);
  formDataMod.append("NbLiens", data[2].value);
  formDataMod.append("L1", data[3].value);
  formDataMod.append("L2", data[5].value);
  formDataMod.append("L3", data[7].value);
  formDataMod.append("L4", data[9].value);
  formDataMod.append("L5", data[11].value);
  formDataMod.append("L6", data[13].value);
  formDataMod.append("I1", data[4].value);
  formDataMod.append("I2", data[6].value);
  formDataMod.append("I3", data[8].value);
  formDataMod.append("I4", data[10].value);
  formDataMod.append("I5", data[12].value);
  formDataMod.append("I6", data[14].value);
  formDataMod.append("Sudoku", "");
  formDataMod.append("Origami", "");
  formDataMod.append("Histoire", "");

  try {
    const response = await Xpress.put(
      `/bornes/data/${data[0].value}`,
      formDataMod
    );
    if (response.status === 200) {
      return { data: response.data, error: false };
    } else {
      console.error(response);
      return {
        data: "Something wrong happened. Refresh the page and check interface content",
        error: true,
      };
    }
  } catch (error) {
    console.error(error);
    return { data: JSON.stringify(error), error: true };
  }
}

export async function PostNewUrlsToKio(dataUrls) {
  let links = [];
  const format = (l) => {
    return {
      domain: l,
      isRegex: false,
      defaultActions: 0,
      pageList: [],
    };
  };

  dataUrls[3].value ? links.push(format(dataUrls[3].value)) : <></>;
  dataUrls[5].value ? links.push(format(dataUrls[5].value)) : <></>;
  dataUrls[7].value ? links.push(format(dataUrls[7].value)) : <></>;
  dataUrls[9].value ? links.push(format(dataUrls[9].value)) : <></>;
  dataUrls[11].value ? links.push(format(dataUrls[11].value)) : <></>;
  dataUrls[13].value ? links.push(format(dataUrls[13].value)) : <></>;

  if (links.length > 0) {
    const obj = {
      security: {
        domainList: links,
      },
    };
    try {
      const resp = await XpressNoApi.patch("/kioware", obj);
      if (resp.status === 200) {
        return { data: resp.data, error: false };
      } else {
        return { data: "Something went wrong", error: true };
      }
    } catch (error) {
      console.error(error);
      return { data: error, error: true };
    }
  } else {
    return { data: "No links to update", error: true };
  }
}

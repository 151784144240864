import React, { useEffect } from "react";
import Container from 'react-bootstrap/Container';


function TerminalView(props) {

  useEffect(() => {
  }, [])

  return (
        <Container>
      <h3 className="pubs-bornes-titles">{props.title}</h3>
      <div className="pubs-bornes-advs">{props.content}</div>

        </Container>
)}

export default TerminalView;
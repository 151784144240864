import React from "react";
import Modal from "react-bootstrap/Modal";
import Loader from "react-loader-spinner";

import "../assets/css/Docboard.css";

function LoadingModal(props) {
  return (
    <Modal
      show={props.show}
      backdrop="static"
      keyboard={false}
      centered
      dialogClassName="loader-modal-global"
      // style={{ width: "fit-content" }}
    >
      <Modal.Body className="body-modal" style={{ width: "120px" }}>
        <Loader
          className="center"
          type="TailSpin"
          color="#00BFFF"
          height={100}
          width={100}
        />
      </Modal.Body>
    </Modal>
  );
}

export default LoadingModal;

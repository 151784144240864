import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Switch, Route, Redirect } from "react-router-dom";

import routes from "../routes";
import NavTop from "../components/NavTop";

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    {/* const [data] = useCookies(["refresh_token"]); if (data.user.userTypeId ===
    "media_lisa") {<Redirect from="/admin" to="/admin/gestionnaire" />} else if
    (data.user.userTypeId === "sav_touch")
    {<Redirect from="/admin" to="/admin/gestionnaire" />} */}
    {/* <Redirect from="/admin" to="/admin/home" /> */}
    <Redirect from="/admin" to="/admin/gestionnaire" />
  </Switch>
);
function Admin(props) {
  const [cookies] = useCookies(["refresh_token"]);
  const [isOk, setOk] = useState(false);
  useEffect(() => {
    if (cookies.user === undefined) {
      props.history.push("/xauth");
    } else if (cookies.user.userName === "xibo_admin") {
      setOk(true);
    } else if (
      cookies.user.userName === "media_lisa" &&
      cookies.user.userTypeId === 1
    ) {
      setOk(true);
    } else if (
      cookies.user.userName === "anthony_lisa" &&
      cookies.user.userTypeId === 1
    ) {
      setOk(true);
    } else if (
      cookies.user.userName === "quentin_lisa" &&
      cookies.user.userTypeId === 1
    ) {
      setOk(true);
    } else {
      props.history.push("/");
    }
  }, []);

  // const switchRoutes = (
  //   <Switch>
  //     {routes.map((prop, key) => {
  //       if (prop.layout === "/admin") {
  //         return (
  //           <Route
  //             path={prop.layout + prop.path}
  //             component={prop.component}
  //             key={key}
  //           />
  //         );
  //       }
  //       return null;
  //     })}
  //     {/* {if (data.user.userTypeId === 1)
  //     {<Redirect from="/admin" to="/admin/home" />}} */}
  //     <Redirect from="/admin" to="/admin/home" />
  //   </Switch>
  // );

  const handleDisconnection = (e) => {
    document.cookie =
      "refresh_token=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie =
      "access_token=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "user=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    props.history.push("/logout");
  };

  return (
    <div>
      {isOk && (
        <div>
          <NavTop
            pageName={props.location.pathname}
            layout="/admin"
            onClick={(e) => handleDisconnection(e)}
          />
          <div>{switchRoutes}</div>
        </div>
      )}
    </div>
  );
}

export default Admin;

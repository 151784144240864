import React, { useState } from "react";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaQuestionCircle } from "react-icons/fa";
import { MdReport } from "react-icons/md";
import "../../assets/css/GoogleForms.scss";

export function BugForm() {
  return (
    <iframe
      src="https://docs.google.com/forms/d/e/1FAIpQLSehcTYb_5UbYBmJJ6NnpmvQ4BD32F7IZ5hBQxIUEmzBdJM0AQ/viewform?embedded=true"
      width="640"
      height="700"
      style={{ border: "none", margin: "0" }}
    >
      Chargement…
    </iframe>
  );
}

export function FeatureForm() {
  return (
    <iframe
      src="https://docs.google.com/forms/d/e/1FAIpQLSdEwKHeaLuK_N-8n3fBdpPWUYzGrrVL35g0YWAYY_A2pYNT8Q/viewform?embedded=true"
      width="640"
      height="700"
      style={{ border: "none", margin: "0" }}
      id="featureForm"
    >
      Chargement…
    </iframe>
  );
}

export function ModalForms() {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  function modalFeature() {
    return (
      <Modal show={show} onHide={handleClose} id="featureForm">
        <Modal.Header closeButton>
          <Modal.Title>Feature Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FeatureForm />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  function modalBug() {
    return (
      <Modal show={show2} onHide={handleClose2} id="bugForm">
        <Modal.Header closeButton>
          <Modal.Title>Bug Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BugForm />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose2}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <>
      <section className="GFActionsBtns">
        <div onClick={handleShow}>
          <OverlayTrigger
            key={1}
            placement={"top"}
            overlay={
              <Tooltip id={`tooltip-form-feature`}>Ask new Feature</Tooltip>
            }
          >
            <FaQuestionCircle size={24} color="#32bcf2" />
          </OverlayTrigger>
        </div>
        <div onClick={handleShow2}>
          <OverlayTrigger
            key={2}
            placement={"top"}
            overlay={<Tooltip id={`tooltip-form-bug`}>Bug Report</Tooltip>}
          >
            <MdReport size={30} color="#f03e6a" />
          </OverlayTrigger>
        </div>
      </section>
      <section className="modals-fullpage" id="targeted">
        {modalFeature()}
      </section>
      <section className="modals-fullpage">{modalBug()}</section>
    </>
  );
}

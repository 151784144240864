import React from "react";
import PrintView from "./PrintView";

function PublicOrigami(props) {
  return (
      <PrintView 
        contentType="origami"
        title="Touche l'origami que tu veux imprimer !"
      />
)}
export default PublicOrigami;
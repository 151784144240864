import React, { useEffect, useState } from "react";
import VideoSlider from "../../components/VideoSlider";
import "../../assets/css/Docboard.css";
import Xibo, {
  RefreshAccessToken,
  UpdateAccessState,
  XiboURL,
} from "../../APIs/api-xibo";
import LoadingModal from "../../components/LoadingModal";
import { useCookies } from "react-cookie";

function DocCustomLibrary(props) {
  const [annonces, setAnnonces] = useState([]);
  const [categories, setCategories] = useState([]);
  const [accessToken, setAccessToken] = useState("");
  const [cookies] = useCookies(["user"]);
  const [showLoading, setShowLoading] = useState({
    show: false,
    message: "",
  });
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    if (showLoading.message !== "") {
      setAlertMessage(showLoading.message);
      let show = showLoading.show;
      setShowLoading({ show: show, message: "" });
    }
  }, [showLoading]);

  useEffect(() => {
    UpdateAccessState(setAccessToken);
    RefreshAccessToken();
  }, []);

  useEffect(() => {
    if (accessToken != "") {
      setShowLoading({ show: true, message: "" });
      Xibo.get("/library?media=img__&start=1&length=1000").then(
        async (res) => {
          // console.log(res);
          let newAnnonces = [];
          let tags = [];
          Xibo.get(`/library?media=lib__&start=1&length=1000`).then(
            async (resVideo) => {
              // console.log(resVideo);
              for (let i = 0; i < res.data.length; i++) {
                if (res.data[i].name.split("__")[0] != "img") continue;
                let vidId = -1;
                let duration = -1;
                for (let j = 0; j < resVideo.data.length; j++) {
                  let arrName = resVideo.data[j].name.split("__");
                  if ("img__" + arrName[1] != res.data[i].name) continue;
                  vidId = resVideo.data[j].mediaId;
                  duration = resVideo.data[j].duration;
                  break;
                }

                let imgTags = res.data[i].tags.split(",");

                if (
                  res.data[i].groupsWithPermissions.length > 0 &&
                  res.data[i].groupsWithPermissions.includes(
                    cookies.user.userName
                  )
                ) {
                  // console.log(cookies.user);
                  for (let j = 0; j < imgTags.length; j++) {
                    if (!tags.includes(imgTags[j])) {
                      tags.push(imgTags[j]);
                    }
                  }
                  newAnnonces.push({
                    id: "imgAnnonce" + res.data[i].mediaId,
                    image:
                      `${XiboURL}/api/library/download/` +
                      res.data[i].mediaId +
                      "?access_token=" +
                      accessToken.split(" ")[1],
                    video:
                      `${XiboURL}/api/library/download/` +
                      vidId +
                      "?access_token=" +
                      accessToken.split(" ")[1],
                    videoId: vidId,
                    duration: duration,
                    title: res.data[i].name.split("__")[1],
                    data: res.data[i],
                  });
                }
              }
              setShowLoading({ show: false, message: "" });
              setAnnonces(newAnnonces);
              setCategories(tags);
              if (newAnnonces.length == 0) {
                document.getElementById("no-data-txt").style.display = "flex";
              } else {
                document.getElementById("no-data-txt").style.display = "none";
              }
            },
            (err) => console.log(err)
          );
        },
        (error) => console.log(error)
      );
    }
  }, [accessToken]);

  return (
    <div>
      <LoadingModal show={showLoading.show} />
      {categories.map((tag) => {
        let fittingAnonces = [];
        for (let i = 0; i < annonces.length; i++) {
          if (annonces[i].data.tags.split(",").includes(tag)) {
            fittingAnonces.push(annonces[i]);
          }
        }
        let title = tag.charAt(0).toUpperCase() + tag.slice(1);

        return (
          <VideoSlider key={tag} annonces={fittingAnonces} title={title} />
        );
      })}
      <div
        id="no-data-txt"
        style={{
          display: "none",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
          width: "100%",
        }}
      >
        Vous n'avez aucune vidéo personnalisée pour le moment. <br />
      </div>
    </div>
  );
}

export default DocCustomLibrary;

import React, { useState } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { GestionURL, Xpress } from "../../APIs/api-xibo";
import { FiFilePlus } from "react-icons/fi";

export default function UploadPrintFiles({ type }) {
  const cookies = useCookies(["access_token"]);

  const [validatedForm, setValidatedFom] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileInput, setFileInput] = useState();
  const [filePath, setFilePath] = useState("");
  const [showAlertSuccess, setShowAlertSuccess] = useState(false);
  const [showAlertFailed, setShowAlertFailed] = useState(false);
  const [errorDetails, setErrorDetails] = useState("");
  const [fileValid, setFileValid] = useState(false);
  const [fileInvalid, setFileInvalid] = useState(false);

  const fileNameHandler = (event) => {
    setFileName(event.target.value);
  };
  const fileDataHandler = (event) => {
    setFileInput(event.target.files[0]);
    if (fileName == "") {
      setFileName(event.target.files[0].name);
    }
    setFileValid(true);
  };

  const handleSubmitFile = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidatedFom(true);
    } else {
      event.preventDefault();
      SendFileForm();
    }
  };

  async function SendFileForm() {
    const formData = new FormData();
    formData.append("file", fileInput);
    formData.append("name", fileName);
    formData.append("access_token", cookies[0].access_token);
    let url = "";

    if (type === "file") {
      url = "/prints/upload/files";
    } else {
      url = "/prints/upload/icons";
    }

    try {
      const response = await Xpress.post(url, formData);
      if (response.status === 200 || response.status === 201) {
        setFilePath(response.data.url);
        setShowAlertSuccess(true);
      } else {
        setErrorDetails(response.data);
        setShowAlertFailed(true);
      }
    } catch (error) {
      console.error(error);
      setErrorDetails(error.response.data.message);
      setShowAlertFailed(true);
    }
  }

  return (
    <div>
      <h4>Upload un Print {type === "file" ? "File" : "Icon"}</h4>
      <Form
        noValidate
        validated={validatedForm}
        onSubmit={handleSubmitFile}
        id="UploadForm"
      >
        <Form.Row className="p-3">
          <Form.Group as={Row} controlId="IconeName">
            <Form.Label>
              Nom Print {type === "file" ? "File" : "Icon"}
            </Form.Label>
            <Form.Control
              required
              type="text"
              as="textarea"
              rows={1}
              size="sm"
              placeholder={type === "file" ? "nom_print.png" : "nom_icone.png"}
              onChange={fileNameHandler}
              value={fileName}
            />
            <Form.Text
              id="borneIdHelpBloc"
              style={{ fontSize: "x-small" }}
              muted
            >
              {type === "file" ? (
                <>
                  Laisser l'extension <b>.png</b> ou <b>.jpg</b> ou <b>.jpeg</b>{" "}
                  d'origine
                </>
              ) : (
                <>
                  Fichier <b>.png</b> obligatoire
                </>
              )}
            </Form.Text>
          </Form.Group>
          <Form.Group as={Row} controlId="IconFile" size="sm">
            {/* <Form.Control
              required
              type="file"
              size="sm"
              placeholder="print-sudoku.png"
              onChange={fileDataHandler}
            /> */}
            <Form.File
              id="formcheck-api-custom"
              custom
              // onChange={fileDataHandler}
            >
              <Form.File.Input
                onChange={fileDataHandler}
                isValid={fileValid}
                isInvalid={fileInvalid}
              />
              <Form.File.Label data-browse="file">Sélectionner</Form.File.Label>
              <Form.Control.Feedback type="valid">Done!</Form.Control.Feedback>
            </Form.File>
            {/* <Form.Text
              id="borneIdHelpBloc"
              style={{ fontSize: "x-small" }}
              muted
            >
              Chaque <b>nom de fichier</b> doit être <b>unique</b>
            </Form.Text> */}
            <Form.Control.Feedback type="invalid">
              Upload un print {type === "file" ? "file" : "icon"} valide
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>

        <Button type="submit" variant="success">
          Upload Print {type === "file" ? "File" : "Icon"}
        </Button>
      </Form>
      <section id="alerts-forms">
        <Alert
          show={showAlertFailed}
          key={"fileFail"}
          variant={"danger"}
          className="alertsForms"
        >
          <Alert.Heading>
            Print {type === "file" ? "file" : "icon"} not uploaded
          </Alert.Heading>
          <div>
            <br />
            <i> Something went wrong during upload... :'(</i> <br />
            <br />
            <br />
            {errorDetails !== "" ? (
              <>
                <u> Details :</u>
                <br />
                <br />
                {errorDetails}
              </>
            ) : (
              <>
                Try to : <br />
                - Check the file format (png). <br />
                - Refresh the page and try again. <br />- Log out from this
                dashboard and login another time
              </>
            )}
            <br />
            <br />
            <span style={{ display: "block", marginBottom: "1em" }}>
              Le nom de fichier ne doit contenir que les caractères suivants :
              <ul>
                <li>Caractères alphanumériques (a-z, A-Z, 0-9)</li>
                <li>Tiret (-)</li>
                <li>Underscore (_)</li>
                <li>Point (.)</li>
              </ul>
            </span>
            <span style={{ display: "block" }}>
              Les caractères suivants sont interdits :
              <ul>
                <li>
                  Caractères spéciaux (par exemple #, $, &, *, @, !, etc.)
                </li>
                <li>Espaces, tabulations, et autres caractères d'espacement</li>
                <li>
                  Caractères accentués et non-latins (par exemple é, è, ñ, ç,
                  caractères cyrilliques, chinois, etc.)
                </li>
                <li>Symboles et pictogrammes (y compris les emojis)</li>
                <li>Caractères de contrôle ASCII</li>
              </ul>
            </span>
            <br /> <br />
            If the problem persists, contact the{" "}
            <b>
              <a href="mailto: richard@lisaconnect.fr" target="_blank">
                administrator
              </a>
              .
            </b>
            <div className="d-flex justify-content-end">
              <Button
                onClick={() => setShowAlertFailed(false)}
                variant="outline-danger"
              >
                OK
              </Button>
            </div>
          </div>
        </Alert>
        <Alert
          show={showAlertSuccess}
          variant="success"
          className="alertsForms"
          id="AlertSuccess"
        >
          <Alert.Heading>
            Print {type === "file" ? "File" : "Icon"} successfully uploaded
          </Alert.Heading>
          <div>
            The print {type === "file" ? "file" : "icon"} is stored at url :
            <br />
            <a href={filePath} target="_blank">
              {filePath}
            </a>
          </div>
          <hr />
          <div className="d-flex justify-content-end">
            <Button
              onClick={() => {
                setShowAlertSuccess(false);
                window.location.href = `${GestionURL}/admin/interfaces`;
              }}
              variant="outline-success"
            >
              OK
            </Button>
          </div>
        </Alert>
      </section>
    </div>
  );
}

import React, { useEffect, useState, useRef } from "react";
import {
  Form,
  Button,
  OverlayTrigger,
  Tooltip,
  Container,
  Col,
  Alert,
  InputGroup,
  Row,
  Modal,
  ListGroup,
} from "react-bootstrap";
import { Xpress } from "../../APIs/api-xibo";
import {
  BsPhone,
  BsGeo,
  BsFillTrashFill,
  BsArrowCounterclockwise,
  BsPlus,
  BsTrashFill,
  BsCheck,
  BsExclamationOctagon,
} from "react-icons/bs";

import { rotateImg } from "../../services/Utils";
import axios from "axios";

function BornesFormSimply() {
  const [allContacts, setAllContact] = useState([]);
  const [showAlertSuccess, setShowAlertSuccess] = useState(false);
  const [showAlertFailed, setShowAlertFailed] = useState(false);
  const [lastError, setLastError] = useState(null);
  const [invalidFields, setInvalidFields] = useState({});

  const [showForm, setShowForm] = useState(false); // To control the display of FormContact
  const [isEditMode, setIsEditMode] = useState(false); // To determine if the form is in edit mode or create mode
  const [selectedContactId, setSelectedContactId] = useState("empty"); // To store the selected contact's ID

  const [modalShow, setModalShow] = useState(false);

  // ------------------------------------------------

  const [borneRef, setBorneRef] = useState("");
  const [etablissement, setEtablissement] = useState("");
  const [refClient, setRefClient] = useState("");
  const [adresse, setAdresse] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  // let searchTimer = null; // Variable pour stocker le timer
  const searchTimerRef = useRef(null); // Utilisation de useRef pour le timer

  const emptyFormData = {
    borne: "",
    etablissement: "",
    refclient: "",
    adresse: "",
    latitude: "",
    longitude: "",
  };

  const [formData, setFormData] = useState(emptyFormData);

  const [query, setQuery] = useState("");
  const [filteredAddresses, setFilteredAddresses] = useState([]);

  const searchAddress = async (query) => {
    if (typeof query !== "string" || !query.trim()) return;

    try {
      const { data } = await axios.get(
        `https://nominatim.openstreetmap.org/search?addressdetails=1&q=${query}&format=json&countrycodes=fr`
      );
      setFilteredAddresses(data);
    } catch (error) {
      console.error("Error while fetching addresses:", error);
    }
  };

  const handleSearch = (e) => {
    const newQuery = e.target.value;
    // setQuery(newQuery);
    setFormData((prevState) => ({
      ...prevState,
      adresse: newQuery,
    }));
    if (newQuery.length > 2) {
      clearTimeout(searchTimerRef.current); // Annuler le timer précédent
      searchTimerRef.current = setTimeout(() => searchAddress(newQuery), 500); // Définir un nouveau timer
    }
  };

  const regexPatterns = {
    borne: /^[a-zA-Z]-[a-zA-Z0-9]{3}-[0-9]{6}$/,
    refclient: /^.{4,}$/,
    email: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/,
    telephone: /^(?:\d{10}|\d{2}[-.\s]\d{2}[-.\s]\d{2}[-.\s]\d{2}[-.\s]\d{2})$/,
  };

  function checkDataInputs() {
    let isValid = true;
    let newInvalidFields = {};

    // Check borne
    if (!regexPatterns.borne.test(formData.borne)) {
      console.log("borne", formData.borne);
      isValid = false;
      newInvalidFields.borne = true;
    }

    // Check refclient
    if (!regexPatterns.refclient.test(formData.refclient)) {
      console.log("refclient", formData.refclient);
      isValid = false;
      newInvalidFields.refclient = true;
    }

    setInvalidFields(newInvalidFields);
    console.log("isValid", isValid);
    return isValid;
  }

  const resetForm = () => {
    setShowForm(false);
    setIsEditMode(false);
    setSelectedContactId("");
    setInvalidFields({});
    setFormData(emptyFormData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const keys = name.split(".");

    if (keys.length === 1) {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else if (keys.length === 2) {
      const [parentKey, childKey] = keys;
      setFormData((prevState) => ({
        ...prevState,
        [parentKey]: {
          ...prevState[parentKey],
          [childKey]: value,
        },
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // TODO: Make an API call to your backend to save the data
    if (checkDataInputs() == true) {
      console.log("isEditMode", isEditMode);
      if (!isEditMode) {
        try {
          const resp = await Xpress.post(
            `/leads/bornes/${formData.borne}`,
            formData
          );
          console.log("resp.data POST", resp.data);
          if (resp.status == 299) {
            console.log("299", resp.data);
            setLastError(resp.data.error);
            setShowAlertFailed(true);
          } else {
            // update the list of contacts
            setAllContact((prevState) => [...prevState, resp.data.data]);
            setShowAlertSuccess(true);
            resetForm();
          }
        } catch (error) {
          console.error(error);
          setLastError(error.message || error);
          setShowAlertFailed(true);
        }
      } else {
        try {
          const resp = await Xpress.put(
            `/leads/bornes/${formData.borne}`,
            formData
          );
          console.log("resp.data", resp.data);
          if (resp.status == 299) {
            console.log("299", resp.data);
            setLastError(resp.data.error);
            setShowAlertFailed(true);
          } else {
            // update the value of the contact in the list
            const newAllContacts = allContacts.map((contact) => {
              if (contact._id === resp.data.data._id) {
                return resp.data.data;
              }
              return contact;
            });
            setAllContact(newAllContacts);
            setShowAlertSuccess(true);
            resetForm();
          }
        } catch (error) {
          console.error(error);
          setLastError(error.message || error);
          setShowAlertFailed(true);
        }
      }
    }
  };

  const handleSelect = (event) => {
    const selectedId = event.target.value;
    if (selectedId) {
      const selectedContact = allContacts.find(
        (contact) => contact._id === selectedId
      );
      setFormData(selectedContact);
      setIsEditMode(true);
      setShowForm(true);
      setSelectedContactId(selectedId);
    }
  };

  // const handleDelete = async (event) => {
  //   event.preventDefault();

  //   // Verifier que la borne n'est pas utilisée dans une notif ouverte

  //   try {
  //     const resp = await Xpress.delete(`/printer/${formData.borne}`);
  //     console.log("resp.data", resp.data);
  //     if (resp.status == 299) {
  //       console.log("299", resp.data);
  //       setModalShow(false);
  //       setLastError(resp.data.error);
  //       setShowAlertFailed(true);
  //     } else {
  //       // update the list of contacts
  //       const newAllContacts = allContacts.filter(
  //         (contact) => contact._id !== formData._id
  //       );
  //       setAllContact(newAllContacts);
  //       setModalShow(false);
  //       setShowAlertSuccess(true);
  //       resetForm();
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     setLastError(error.message || error);
  //     setShowAlertFailed(true);
  //   }
  // };

  // function MyVerticallyCenteredModal(props) {
  //   return (
  //     <Modal
  //       {...props}
  //       size="lg"
  //       aria-labelledby="contained-modal-title-vcenter"
  //       centered
  //     >
  //       <Modal.Header closeButton>
  //         <Modal.Title id="contained-modal-title-vcenter">
  //           Suppresion du contact - {formData.borne}
  //         </Modal.Title>
  //       </Modal.Header>
  //       <Modal.Body>
  //         <h5>Êtes-vous certain de vouloir supprimer ce contact ?</h5>
  //         <br />
  //         <p>
  //           Cette action est <b> irréversible</b>. Vous ne pourrez pas récupérer
  //           les données.
  //         </p>
  //         <p>
  //           Un <b>contact associé </b>à une <b>borne existante</b> ne peut{" "}
  //           <b>pas être supprimé</b>.
  //         </p>
  //       </Modal.Body>
  //       <Modal.Footer>
  //         <Button onClick={handleDelete} variant="danger">
  //           Delete
  //         </Button>
  //         <Button onClick={props.onHide}>Close</Button>
  //       </Modal.Footer>
  //     </Modal>
  //   );
  // }

  useEffect(() => {
    const callAsynch = async () => {
      try {
        const resp = await Xpress.get("/leads/bornes");
        console.log("resp.data", resp.data);
        setAllContact(resp.data.data);
      } catch (error) {
        console.error(error);
        setLastError(error.message || error);
        setShowAlertFailed(true);
      }
    };
    callAsynch();
  }, []);

  useEffect(() => {}, [query]);

  return (
    <>
      <section className="contact-actions-tools">
        <Row style={{ margin: "0" }} className="contact-form-actions">
          <Col xs="4">
            <Button
              // variant="outline-info"
              variant={
                showForm && isEditMode === false ? "info" : "outline-info"
              }
              style={{ margin: "auto" }}
              onClick={() => {
                if (showForm) {
                  resetForm();
                } else {
                  setFormData(emptyFormData);
                  setIsEditMode(false);
                  setShowForm(true);
                  setSelectedContactId("");
                  invalidFields != {} && setInvalidFields({});
                }
              }}
            >
              <BsPlus style={{ marginRight: "10px" }} />
              Nouvelle Borne
            </Button>
          </Col>

          <Col style={{ alignSelf: "center" }}>
            <Form.Group
              controlId="borneSelect"
              className="interfSectionsH3"
              style={{ margin: 0 }}
            >
              <InputGroup size="sm">
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    {/* <Form.Label size="sm">Numéro de la borne</Form.Label> */}
                    N° borne
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  required
                  as="select"
                  // defaultValue=""
                  size="sm"
                  onChange={handleSelect}
                  value={selectedContactId}
                >
                  <option key={"empty"} value={""}></option>
                  {allContacts.map((borne) => {
                    return (
                      <option key={borne._id} value={borne._id}>
                        {borne.borne}
                      </option>
                    );
                  })}
                </Form.Control>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col xs="1" style={{ alignSelf: "center" }}>
            {showForm ? (
              <Button size="sm" onClick={resetForm} variant="warning">
                <BsArrowCounterclockwise />
              </Button>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </section>
      <section></section>
      {showForm && (
        <Form className="contact-form-actions" onSubmit={handleSubmit}>
          <div
            style={{
              padding: "1vw 2vw",
              border: "1px solid orange",
              borderRadius: "8px",
              marginBottom: "16px",
              backgroundColor: "#f9f9f9",
            }}
          >
            <div style={{ marginBottom: "5px" }}>
              <BsExclamationOctagon
                color="orange"
                style={{ marginRight: "5px" }}
              />
              <b>Informations importantes</b>
            </div>
            <div style={{ fontSize: "smaller", padding: "0 2vw" }}>
              - Le nom de borne doit être <u>unique</u>.
              <br />- Le nom de borne renseigné doit <u>correspondre</u> au{" "}
              <u>nom réel</u> de la borne (<i>à la majuscule près</i>).
            </div>
          </div>
          <Form.Row>
            <Form.Group as={Col} controlId="borne">
              <Form.Label>Borne *</Form.Label>
              <InputGroup hasValidation>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <BsGeo />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  name="borne"
                  placeholder="ex: B-2v1-220000"
                  value={formData.borne}
                  onChange={handleChange}
                  isInvalid={invalidFields.borne}
                  disabled={isEditMode}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Format Borne incorrecte. Format type: B-2v1-220000
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} controlId="refclient">
              <Form.Label>Ref Client (Axonaut) *</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  name="refclient"
                  placeholder="ex: 13270481 - Visible dans l'url d'Axonaut"
                  value={formData.refclient}
                  onChange={handleChange}
                  isInvalid={invalidFields.refclient}
                  aria-describedby="refClientHelpBlock"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Format RefClient incorrecte. Longeur min 4 caractères.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} xs={4} controlId="etablissement">
              <Form.Label>Etablissement *</Form.Label>
              <Form.Control
                type="text"
                name="etablissement"
                placeholder="ex: Clinique Tzanck"
                value={formData.etablissement}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group as={Col} controlId="etablissement">
              <Form.Label>Adresse *</Form.Label>
              <Form.Control
                type="text"
                placeholder="Adresse de la borne"
                // value={query}
                value={formData.adresse}
                onChange={handleSearch}
                required
              />
              <ListGroup>
                {filteredAddresses.map((address, index) => (
                  <ListGroup.Item key={index}>
                    <span
                      onClick={() => {
                        console.log("--------- address", address);
                        // setQuery(address.display_name);
                        setFormData((prevState) => ({
                          ...prevState,
                          adresse: address.display_name,
                          latitude: address.lat,
                          longitude: address.lon,
                        }));
                        setFilteredAddresses([]);
                      }}
                    >
                      {address.display_name}
                    </span>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Form.Group>
          </Form.Row>

          <Container></Container>

          <Container style={{ textAlign: "center" }}>
            {/* <Button
              variant={"outline-danger"}
              className="mr-5"
              onClick={() => setModalShow(true)}
            >
              <BsTrashFill /> Supprimer le contact ?
            </Button> */}
            <Button variant="success" type="submit">
              <BsCheck /> Submit
            </Button>
          </Container>
        </Form>
      )}
      {showAlertFailed ? (
        <section
          className="alerts-forms"
          onClick={() => {
            setShowAlertFailed(false);
            setLastError(null);
          }}
        >
          <Alert
            show={showAlertFailed}
            key={"fileFail"}
            variant={"danger"}
            className="alertsForms"
          >
            <Alert.Heading>Erreur lors de l'enregistrement</Alert.Heading>
            <p>
              <br />
              <div>
                Error details : <b>{lastError}</b>
              </div>
              <br />
            </p>
            <hr />
            <p>
              If the problem persists, contact the{" "}
              <b>
                <a href="mailto: richard@lisaconnect.fr" target="_blank">
                  administrator
                </a>
                .
              </b>
              <div className="d-flex justify-content-end">
                <Button
                  onClick={() => {
                    setShowAlertFailed(false);
                    setLastError(null);
                  }}
                  variant="outline-danger"
                >
                  OK
                </Button>
              </div>
            </p>
          </Alert>
        </section>
      ) : (
        <></>
      )}
      {showAlertSuccess ? (
        <section
          className="alerts-forms"
          onClick={() => setShowAlertSuccess(false)}
        >
          <Alert
            show={showAlertSuccess}
            variant="success"
            className="alertsForms"
            id="AlertSuccessFile"
          >
            <Alert.Heading>Borne's Informations success</Alert.Heading>
            <hr />
            <div className="d-flex justify-content-center">
              <Button
                onClick={() => setShowAlertSuccess(false)}
                variant="outline-success"
                size="sm"
                className="btn-icon-list"
              >
                OK
              </Button>
            </div>
          </Alert>
        </section>
      ) : (
        <></>
      )}
      {/* <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      /> */}
    </>
  );
}

export default BornesFormSimply;

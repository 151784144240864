import React, { useEffect, useState } from "react";
import VideoSlider from "../../components/VideoSlider";
import "../../assets/css/Docboard.css";
import Xibo, {
  RefreshAccessToken,
  UpdateAccessState,
  XiboURL,
} from "../../APIs/api-xibo";
import { useCookies } from "react-cookie";
import LoadingModal from "../../components/LoadingModal";
import { BsFillTrashFill, BsSearch } from "react-icons/bs";
import { Badge, Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";

function DocLibrary(props) {
  const [annonces, setAnnonces] = useState([]);
  const [allAnnonces, setAllAnnonces] = useState([]);
  const [categories, setCategories] = useState([]);
  const [inputV, setInputV] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [cookies] = useCookies(["user"]);
  const [showLoading, setShowLoading] = useState({
    show: false,
    message: "",
  });
  const [alertMessage, setAlertMessage] = useState("");
  const [filterIcn, setFilterIcn] = useState(false);

  // const orderedCats = [
  //   "Jeunesse",
  //   "Dépistage / Journée Santé / Événements",
  //   "Messages Institutionnels des Cabinets",
  //   "Vaccination",
  //   "MST",
  //   "Printemps / Été",
  //   "Automne / Hiver",
  //   "Addiction Tabac / Drogue / Jeux",
  //   "Sommeil / santé mental",
  //   "Alimentation",
  //   "Sport",
  //   "Diabète",
  //   "Bucco-dentaire",
  //   "Optique / Audition",
  //   "Radiologie",
  //   "Journée Mondiale",
  //   "Prévention Généraliste",
  //   "Lisa & Mois",
  //   "Pensées positives",
  //   "Associations",
  // ];

  const orderedCats = [
    "Jeunesse",
    "Dépistage / Journée Santé / Événements Santé",
    "Messages Institutionnels établissements",
    "Vaccination",
    "MST",
    "Addiction Alcool / Tabac / Drogue / Jeux",
    "Sommeil / Santé mentale",
    "Alimentation",
    "Sport",
    "Diabète",
    "Bucco-dentaire",
    "Ophtalmologie / Audition",
    "Radiologie",
    "Printemps / Été",
    "Automne / Hiver",
    "Prévention Généraliste",
    "Environnement",
    "JMS",
    "Lisa & Mois",
    "Pensées positives",
    "Associations",
  ];

  const hiddenCategories = [
    "Automne / Hiver",
    // "Printemps / Été",
    "Catégorie à masquer 2",
  ];

  const [allCategories, setAllCategories] = useState(
    mergeOrderedAndRemainingCategories(orderedCats, categories)
  );

  const [originalCategories, setOriginalCategories] = useState(
    mergeOrderedAndRemainingCategories(orderedCats, categories)
  );

  useEffect(() => {
    if (showLoading.message !== "") {
      setAlertMessage(showLoading.message);
      let show = showLoading.show;
      setShowLoading({ show: show, message: "" });
    }
  }, [showLoading]);

  useEffect(() => {
    UpdateAccessState(setAccessToken);
    RefreshAccessToken();
  }, []);

  useEffect(() => {
    if (accessToken != "") {
      setShowLoading({ show: true, message: "" });
      Xibo.get("/library?media=img__&start=1&length=1000").then(
        (res) => {
          let newAnnonces = [];
          let tags = [];
          Xibo.get("/library?media=lib__&start=1&length=1000").then(
            (resVideo) => {
              for (let i = 0; i < res.data.length; i++) {
                if (res.data[i].name.split("__")[0] != "img") continue;
                let vidId = -1;
                let duration = -1;
                for (let j = 0; j < resVideo.data.length; j++) {
                  let arrName = resVideo.data[j].name.split("__");
                  if ("img__" + arrName[1] != res.data[i].name) continue;
                  vidId = resVideo.data[j].mediaId;
                  duration = resVideo.data[j].duration;
                  break;
                }

                let imgTags = res.data[i].tags.split(",");

                for (let j = 0; j < imgTags.length; j++) {
                  if (!tags.includes(imgTags[j])) {
                    tags.push(imgTags[j]);
                  }
                }
                let finalDateCreated =
                  res.data[i].createdDt > resVideo.data[i].createdDt
                    ? res.data[i].createdDt
                    : resVideo.data[i].createdDt;

                let finalDateModified =
                  res.data[i].modifiedDt > resVideo.data[i].modifiedDt
                    ? res.data[i].modifiedDt
                    : resVideo.data[i].modifiedDt;

                newAnnonces.push({
                  id: "imgAnnonce" + res.data[i].mediaId,
                  image:
                    `${XiboURL}/api/library/download/` +
                    res.data[i].mediaId +
                    "?access_token=" +
                    accessToken.split(" ")[1],
                  video:
                    `${XiboURL}/api/library/download/` +
                    vidId +
                    "?access_token=" +
                    accessToken.split(" ")[1],
                  videoId: vidId,
                  duration: duration,
                  title: res.data[i].name.split("__")[1],
                  data: res.data[i],
                  createdDt: finalDateCreated,
                  modifiedDt: finalDateModified,
                });
              }
              setShowLoading({ show: false, message: "" });
              setAnnonces(newAnnonces);
              setAllAnnonces(newAnnonces);
              setCategories(tags);
              setAllCategories(tags);
              setOriginalCategories(tags);
            },
            (err) => console.log(err)
          );
        },
        (error) => console.log(error)
      );
    }
  }, [accessToken]);

  function mergeOrderedAndRemainingCategories(orderedCats, categories) {
    const lisaAndMoisIndex = orderedCats.indexOf("Lisa & Mois");
    const remainingCategories = categories.filter(
      (category) => !orderedCats.includes(category)
    );

    return [
      ...orderedCats.slice(0, lisaAndMoisIndex),
      ...remainingCategories,
      ...orderedCats.slice(lisaAndMoisIndex),
    ];
  }

  function sortByDate(a, b) {
    const aDate = new Date(
      a.modifiedDt > a.createdDt ? a.modifiedDt : a.createdDt
    );
    const bDate = new Date(
      b.modifiedDt > b.createdDt ? b.modifiedDt : b.createdDt
    );

    if (aDate < bDate) {
      return 1;
    } else if (aDate > bDate) {
      return -1;
    } else {
      return 0;
    }
  }

  function filterByVideoName(annonces, input) {
    return annonces.filter(
      (annonce) => annonce.title.toLowerCase().includes(input.toLowerCase())
      // ||
      // annonce.data.tags.toLowerCase().includes(input.toLowerCase())
    );
  }

  function inputChanging(e) {
    if (e.target.value.length === 0) {
      setAnnonces(allAnnonces);
      setInputV("");
    } else {
      let input = e.target.value;
      setInputV(input);
      let filteredAnnonces = filterByVideoName(allAnnonces, input);
      setAnnonces(filteredAnnonces);
    }
  }

  const handleSelect = (event) => {
    event.preventDefault();
    if (
      event.target.value !== "Filtrer par Catégorie" &&
      event.target.value !== "empty"
    ) {
      setAllCategories(
        originalCategories.filter((category) => category === event.target.value)
      );
    } else {
      setAllCategories(originalCategories);
    }
  };

  useEffect(() => {});

  return (
    <div>
      <div className="icn-filter">
        <OverlayTrigger
          key={"bottom"}
          placement={"bottom"}
          overlay={
            <Tooltip id={`tooltip-${"bottom"}`}>
              Rechercher et filtrer les vidéos
            </Tooltip>
          }
        >
          <Badge variant="success" className="newBadge">
            New
          </Badge>
        </OverlayTrigger>
        <BsSearch
          size={34}
          onClick={() => setFilterIcn(!filterIcn)}
          color={filterIcn ? "black" : "grey"}
        />
      </div>
      <div id="filter-zone" className={filterIcn ? "" : "hide"}>
        <span>
          <input
            placeholder="Rechercher une vidéo par mot-clés"
            onChange={inputChanging}
            id="searchinput"
          ></input>{" "}
          <Button
            size="sm"
            variant="danger"
            style={inputV !== "" ? {} : { visibility: "hidden" }}
            onClick={(e) => {
              setAnnonces(allAnnonces);
              document.getElementById("searchinput").value = "";
              setInputV("");
            }}
          >
            <BsFillTrashFill />
          </Button>
        </span>
        <Form.Group controlId="borneSelect" className="interfSectionsH3">
          <Form.Control
            required
            as="select"
            defaultValue=""
            size="sm"
            onChange={handleSelect}
          >
            <option
              key={"empty"}
              value={"empty"}
              style={{ textAlign: "center" }}
            >
              Filtrer par Catégorie
            </option>
            {originalCategories.map((tag, index) => {
              return (
                <option key={index + "-" + tag} value={tag}>
                  {tag}
                </option>
              );
            })}
          </Form.Control>
          <Button
            size="sm"
            variant="danger"
            style={
              allCategories !== originalCategories
                ? {}
                : { visibility: "hidden" }
            }
            onClick={(e) => {
              document.getElementById("borneSelect").value = "empty";
              setAllCategories(originalCategories);
            }}
          >
            <BsFillTrashFill />
          </Button>
        </Form.Group>
      </div>
      <LoadingModal show={showLoading.show} />
      {allCategories.map((tag) => {
        // Vérifie si la catégorie doit être masquée
        if (hiddenCategories.includes(tag)) {
          return null;
        }
        let fittingAnonces = annonces
          .filter((annonce) => annonce.data.tags.split(",").includes(tag))
          .sort(sortByDate);

        if (fittingAnonces.length > 0) {
          let title = tag.charAt(0).toUpperCase() + tag.slice(1);
          return (
            <VideoSlider key={tag} annonces={fittingAnonces} title={title} />
          );
        } else {
          return null;
        }
      })}
    </div>
  );
}

export default DocLibrary;

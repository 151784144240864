import React from "react";
import { useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import "../../assets/css/Interfaces.scss";
import { Container, Tabs, Tab } from "react-bootstrap";
import "../../assets/css/Gestionnaire.css";
import { useCookies } from "react-cookie";

import { ModalForms } from "../../components/GoogleForms/GoogleForm";
import XiboVideoForm from "../../components/VideoAuto/VideosXiboForm";
import BouclesTemp from "../../components/VideoAuto/BouclesTemp";
import AutoVidServices from "../../services/AutoVideoServices";
// import { RefreshAccessToken } from "../../APIs/api-xibo-auto";
import { RefreshAccessToken, RefreshAccessToken2 } from "../../APIs/api-xibo";

function AutoVideoDash(props) {
  const [Ccookies] = useCookies(["user"]);

  const [key, setKey] = useState("templates");

  const [xibovideos, setXibovideos] = useState([]);
  const [xibomedias, setXiboMedias] = useState([]);
  const [templates, setTemplates] = useState([]);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");

  async function RefreshXiboVideo() {
    const newData = await AutoVidServices.GetAllVideoXibo();

    if (newData.error) {
      console.error("error", newData.error);
      setAlertMessage("Failed to get data VideoXibo");
      setAlertType("danger");
      setShowAlert(true);
    } else if (newData.data) {
      setXibovideos(newData.data);
    }
  }

  async function RefreshXiboMedias() {
    const newData = await AutoVidServices.GetAllXiboMedias();

    if (newData.error) {
      console.error("error", newData.error);
      setAlertMessage("Failed to get data XiboMedias");
      setAlertType("danger");
      setShowAlert(true);
    } else if (newData.data) {
      setXiboMedias(newData.data);
    }
  }

  async function RefreshBouclesTemp() {
    const newData = await AutoVidServices.GetAllBoucleTemps();

    if (newData.error) {
      console.error("error", newData.error);
      setAlertMessage(
        "Failed to get BoucleTemplate data. Please refresh the page."
      );
      setAlertType("danger");
      setShowAlert(true);
    } else if (newData.data) {
      setTemplates(newData.data);
    }
  }

  const refreshXiboVid = async () => RefreshXiboVideo();
  const refreshXiboMed = async () => RefreshXiboMedias();
  const refreshXiboBoucles = async () => RefreshBouclesTemp();

  // useEffect(() => {
  //   console.log("Ccookies.user", Ccookies.user);
  //   if (Ccookies.user.userTypeId === 1) {
  //     // Valid !
  //     console.log("Valid !");
  //   }
  //   if (Ccookies.user === undefined) {
  //     props.history.push("/xauth");
  //   } else if (Ccookies.user.userName === "xibo_admin") {
  //   } else if (Ccookies.user.userName == "media_lisa") {
  //   } else if (Ccookies.user.groups[0].group === "Annonceurs") {
  //     props.history.push("/adboard/home");
  //   } else if (Ccookies.user.groups[0].group !== "Medecins") {
  //     props.history.push("/xauth");
  //   }

  //   RefreshXiboMedias();
  //   RefreshXiboVideo();
  //   RefreshBouclesTemp();
  // }, []);

  // useEffect(async () => {
  //   // if (cookies[0]["access_token"] === undefined) {
  //   //   RefreshAccessToken().then(() => {
  //   //     GetInterfaces();
  //   //   });
  //   // }
  //   if (Ccookies.user === undefined) {
  //     props.history.push("/xauth");
  //   } else if (Ccookies.user.userName === "xibo_admin") {
  //   } else if (Ccookies.user.userName === "media_lisa") {
  //   } else if (Ccookies.user.groups[0].group === "Annonceurs") {
  //     props.history.push("/adboard/home");
  //   } else if (Ccookies.user.groups[0].group !== "Medecins") {
  //     props.history.push("/xauth");
  //   }
  // });

  async function GetAllData() {
    RefreshBouclesTemp();
    RefreshXiboVideo();
    RefreshXiboMedias();
  }

  useEffect(() => {
    if (
      Ccookies.user === undefined ||
      Ccookies["access_token"] == undefined ||
      Ccookies["access_token"] == "" ||
      !Ccookies["access_token"]
    ) {
      props.history.push("/xauth");
    } else if (Ccookies?.user?.userTypeId === 1) {
    } else if (Ccookies?.user?.groups[0]?.group === "Annonceurs") {
      props.history.push("/adboard/home");
    } else if (Ccookies?.user?.groups[0]?.group !== "Medecins") {
      props.history.push("/xauth");
    }
  });

  useEffect(() => {
    // if (
    //   Ccookies["access_token"] == undefined ||
    //   Ccookies["access_token"] == "" ||
    //   !Ccookies["access_token"]
    // ) {
    //   console.log("No access token");
    //   RefreshAccessToken2().then((rep) => {
    //     if (rep) GetAllData();
    //     else {
    //       setAlertMessage(
    //         "Failed to get access token. Please refresh the page."
    //       );
    //       setAlertType("danger");
    //       setShowAlert(true);
    //     }
    //   });
    // } else {
    //   GetAllData();
    // }
    GetAllData();
  }, []);

  // ----------------------------------- NEW -------------------------------------------------------

  return (
    <Container id="interfacesPage">
      <h1 className="mt-1 mb-3 text-left">Auto Vidéo Dashboard</h1>
      {/* <h2 className="">Configuration</h2> */}
      <Container className="mr-2" style={{ marginBottom: "100px" }}>
        <Tabs
          defaultActiveKey="templates"
          id="uncontrolled-tab-auto-video"
          className="justify-content-center mb-3 uncontrolled-tab-interf-prints"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tab
            eventKey="xibo_video"
            title="Xibo Vidéos"
            className={key == "xibo_video" ? "color-black" : "color-grey"}
          >
            <Container>
              <XiboVideoForm
                xiboVid={xibovideos}
                xiboMed={xibomedias}
                refreshXiboVid={refreshXiboVid}
              />
            </Container>
          </Tab>
          <Tab eventKey="templates" title="Boucles Templates">
            <Container>
              <BouclesTemp
                xiboVid={xibovideos}
                btemps={templates}
                refreshBTemps={refreshXiboBoucles}
              />
            </Container>
          </Tab>
        </Tabs>
      </Container>

      {/* --------------- ALERTS SECTION --------------- */}
      {showAlert && (
        <div className="alert-container-central">
          <Alert id="Annonces_Alert" variant={alertType}>
            <b>{alertMessage}</b>
          </Alert>
        </div>
      )}
      <ModalForms />
    </Container>
  );
}

export default AutoVideoDash;

import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import "../assets/css/Annonces.scss";
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Container,
  Row,
} from "react-bootstrap";
import Xibo, { Xpress, UpdateAccessState, XiboURL } from "../APIs/api-xibo";
import LoadingModal from "./LoadingModal";

import ConfirmationModal from "./ConfirmationModal";
import VideoPlayer from "./VideoPlayer";
import InputModal from "./InputModal";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import AnnoncesAuto from "./AnnoncesAuto";
import DisplaysProofs from "../services/DisplaysProofs";
import StopDisplaysProofs from "../services/StopDisplaysProofs";
import AnnonceTools from "./AnnonceTools";
import { BsFillInfoCircleFill, BsTrash } from "react-icons/bs";
import VideoCardAnnonces from "./VideoCardAnnonces";
import VideoAuto from "./Doc/VideoAuto";

const VideoReorder = (props) => {
  const [cookies] = useCookies(["refresh_token"]);
  const [originalVideos, setOriginalVideos] = useState([]);
  const [videos, setVideos] = useState([]);
  const [isOrderChanged, setIsOrderChanged] = useState(false);
  const [orderHasChanged, setOrderHasChanged] = useState(false);

  const [showAlert, setShowAltert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [showLoading, setShowLoading] = useState(false);

  // olds variables

  const [upload, setUpload] = useState(new Array(10));
  // const [showLoading, setShowLoading] = useState({ show: false, message: "" });
  const [showLoading2, setShowLoading2] = useState({
    show: false,
    message: "",
  });
  const [showConfirm, setShowConfirm] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [statutAttenteDelete, setStatutAttenteDelete] = useState("");
  const [eventClickDelete, setEventClickDelete] = useState(null);
  const [currIndex, setCurrIndex] = useState(-1);
  const [inputValue, setInputValue] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [MediaName, setMediaName] = useState("");

  const [changeOrderMod, setChangeOrderMod] = useState(false);
  const [videoOrder, setVideoOrder] = useState();

  const [toolView, setToolView] = useState("main");
  const [boucleTemplates, setBouclesTemplates] = useState([]);

  // ------------------------------------OLD FUNCTIONS INTEGRATION -------------------------------

  async function CheckDisplayOrders(data) {
    let resDisplay = await Xibo.get("/display");
    let displays = resDisplay.data;
    for (let index = 0; index < displays.length; index++) {
      const scheduled = await Xibo.get(
        `/schedule/${displays[index].displayGroupId}/events?date=${dateNow()}`
      ).then((res) => {
        for (let i = 0; i < res.data.events.length - 1; i++) {
          const currentEvent = res.data.events[i];
          const nextEvent = res.data.events[i + 1];
          const ecart = nextEvent.displayOrder - currentEvent.displayOrder;

          if (
            currentEvent.displayOrder % 2 === 0 &&
            nextEvent.displayOrder % 2 === 0
          ) {
            // on verifie que l'écart entre les deux displayOrder n'est pas supérieur à 2
            if (ecart > 2) {
              alert(
                "Anomalie d'affichage détectée ! \n Veuillez contacter l'administrateur."
              );
            } else {
              // on display le LC default layout
            }
            //  break;
          }
        }
      });
    }
  }

  async function getTemplates() {
    // get themes
    try {
      const resData = await Xpress.get(`/auto/templates`);

      setBouclesTemplates(resData.data);
    } catch (error) {
      console.log(error);
      alert("Erreur lors de la récupération des templates ");
    }
  }

  const handleClickDelete = () => {
    setShowConfirm(false);
    setShowLoading2({ show: true, message: "" });
    let e = eventClickDelete;
    console.log(e);
    let statutAttente = statutAttenteDelete;
    console.log(statutAttente);
    let layoutId = e.split("_")[0];
    let mediaId = e.split("_")[1];

    const matchedEvent = videos.find((video) => video.layoutId == layoutId);

    console.log("Matched event", matchedEvent);

    function setError(texte = "Erreur lors de la suppression de la vidéo.") {
      setShowLoading2({ show: false, message: "" });
      setAlertMessage(texte);
      setAlertType("warning");
      setShowAltert(true);
    }

    // Suppresion du layout qui display sur la borne (piste pour delete media annonceur)
    Xibo.delete("/layout/" + layoutId).then(
      async (res) => {
        // Regarder dans les layouts en cours de diffusion s'il y a deux layout.displayOrder
        CheckDisplayOrders(e);

        let finalfunction = await StopDisplaysProofs(
          mediaId,
          MediaName,
          cookies
        );
        if (finalfunction === true) {
          // Xibo.get("/library?mediaId=" + mediaId).then(
          //   (resGetMedia) => {
          //     console.log(resGetMedia);
          //     if (
          //       resGetMedia &&
          //       resGetMedia.data &&
          //       resGetMedia.data.length > 0 &&
          //       resGetMedia.data[0].ownerId == cookies.user.userId
          //     ) {
          //       Xibo.delete("library/" + mediaId).then(
          //         (res) => {
          //           afterDeleteAnnonce(statutAttente, layoutId);
          //         },
          //         (err) => console.log(err)
          //       );
          //     } else {
          //       afterDeleteAnnonce(statutAttente, layoutId);
          //     }
          //   },
          //   (err) => console.log(err)
          // );

          const medOwner = await Xibo.get("/library?mediaId=" + mediaId);

          if (
            medOwner &&
            medOwner.data &&
            medOwner.data.length > 0 &&
            medOwner.data[0].ownerId == cookies.user.userId
          ) {
            try {
              const layoutDelet = await Xibo.delete("library/" + mediaId);
            } catch (error) {
              console.error("Error deleting media", error);
              setError("Erreur lors de la suppression de votre vidéo.");
              return;
            }
          } else if (
            matchedEvent.description &&
            matchedEvent.description.includes("autoVideo")
          ) {
            try {
              const res = await Xpress.delete(
                `/auto/config/layout/${layoutId}`
              );
              if (res.status === 200) {
                console.log("Auto video deleted", res);
              }
            } catch (error) {
              console.error(
                "Error deleting autoVideo boucle config. But layout is deleted.",
                error
              );
              setError(
                "Error deleting autoVideo boucle config. But layout is deleted."
              );
              return;
            }
          }

          afterDeleteAnnonce(statutAttente, layoutId);

          // check if description includes "videoAuto"
        } else {
          setError(
            "Erreur lors de l'enregistrement de votre ordre d'arrêt de diffusion."
          );
        }
      },
      (err) => {
        console.log(err);
        setError("Erreur lors de l'arrêt de la diffusion.");
      }
    );
  };

  const afterDeleteAnnonce = async (statutAttente, layoutId) => {
    if (statutAttente) {
      let rowId = null;
      const resDataSet = await Xibo.get("/dataset/data/2?length=1000");
      for (let i = 0; i < resDataSet.data.length; i++) {
        let row = resDataSet.data[i];
        if (row["Layout id"] == layoutId) {
          rowId = row.id;
          break;
        }
      }
      if (rowId != null) {
        await Xibo.delete("/dataset/data/2/" + rowId);
      }
    }
    setShowLoading2({ show: false, message: "" });
    // alert("Annonce supprimée.");
    // setup l'alerte de success
    setAlertMessage("Annonce supprimée avec succès !");
    setAlertType("success");
    setShowAltert(true);

    getLayouts();
  };

  const libraryUpload = async (layoutIndex, medName, media) => {
    let mediaName = "";
    inputValue == ""
      ? (mediaName = medName.split(".")[0])
      : (mediaName = inputValue);
    let layoutName =
      layoutIndex + 1 + "__-__" + mediaName + "__" + cookies.user.userId;
    const formData = new FormData();
    formData.append("files", media);
    formData.append("name", layoutName);
    // formData.append("widgetToDt", "2023-01-16 00:01:00");
    // formData.append("expires", "2023-01-14 00:01:00");

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    setShowLoading({ show: true, message: "" });

    try {
      const response = await Xibo.post("/library", formData, config);
      let description = "";
      props.annonceur
        ? (description = "en attente")
        : (description = "médecin");
      const formDataLayout = new FormData();
      formDataLayout.append("name", layoutName);
      formDataLayout.append("description", description);
      formDataLayout.append("resolutionId", 3);
      // formDataLayout.append("fromDt", 1673610841);
      // formDataLayout.append("toDt", 1673952061);

      const config2 = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };

      let permissions = await Xibo.get(
        "/user/permissions/media/" + response.data.files[0].mediaId
      );
      let permId = 0;
      for (let i = 0; i < permissions.data.length; i++) {
        if (permissions.data[i].group == "Users") {
          permId = permissions.data[i].groupId;
        }
      }

      let paramsPerm = new URLSearchParams();
      paramsPerm.append(`groupIds[${permId}][view]`, 1);

      await Xibo.post(
        "/user/permissions/media/" + response.data.files[0].mediaId,
        paramsPerm,
        config2
      );

      console.log("Following paramsPerm data : ");
      console.log(paramsPerm);

      const resLayout = await Xibo.post("/layout", formDataLayout, config);

      console.log("Following formDataLayout data : ");
      console.log(formDataLayout);
      console.log(formDataLayout.resolutionId);
      console.log(resLayout);

      const resLayoutbyParentId = await Xibo.get(
        "/layout?parentId=" +
          resLayout.data.layoutId +
          "&embed=regions,playlists"
      );

      const paramsBackground = new URLSearchParams();
      paramsBackground.append("backgroundColor", "#ffffff");
      const resChangeLayoutBackground = await Xibo.put(
        "/layout/background/" + resLayoutbyParentId.data[0].layoutId,
        paramsBackground,
        config2
      );

      console.log("Following resChangeLayoutBackground data : ");
      console.log(resChangeLayoutBackground);

      const formDataPlaylistAssign = new FormData();

      let duration = 18;
      response.data.files[0].duration <= 18
        ? (duration = response.data.files[0].duration)
        : (duration = 18);
      formDataPlaylistAssign.append("media[]", response.data.files[0].mediaId);
      formDataPlaylistAssign.append("duration", duration);
      const resPlaylistAssign = await Xibo.post(
        "/playlist/library/assign/" +
          resLayoutbyParentId.data[0].regions[0].regionPlaylist.playlistId,
        formDataPlaylistAssign,
        config
      );

      console.log("Following resPlaylistAssign data : ");
      console.log(resPlaylistAssign);

      const paramsPublish = new URLSearchParams();
      paramsPublish.append("publishNow", 1);
      const resPublishLayout = await Xibo.put(
        "/layout/publish/" + resLayoutbyParentId.data[0].parentId,
        paramsPublish,
        config2
      );

      console.log("Following resPublishLayout data : ");
      console.log(resPublishLayout);

      if (props.annonceur) {
        /*     Permet de catégoriser l'annonce en annonceur et demande la validation ADMIN      */
        const getBornes = await Xibo.get("/display");

        console.log("Following getBornes data : ");
        console.log(getBornes);

        let userBornes = "";
        for (let i = 0; i < getBornes.data.length; i++) {
          userBornes += getBornes.data[i].displayGroupId;
          if (i + 1 < getBornes.data.length) {
            userBornes += ";";
          }
        }
        // console.log("new user bornes : " + userBornes);

        const formDataSet = new FormData();
        formDataSet.append("dataSetColumnId_3", cookies.user.userId);
        formDataSet.append(
          "dataSetColumnId_7",
          cookies.user.lastName + " " + cookies.user.firstName
        );
        formDataSet.append("dataSetColumnId_5", layoutName);
        formDataSet.append("dataSetColumnId_6", resPublishLayout.data.layoutId);
        formDataSet.append("dataSetColumnId_4", new Date());
        formDataSet.append("dataSetColumnId_8", cookies.user.email);
        formDataSet.append("dataSetColumnId_9", userBornes);

        const resDataSet = await Xibo.post(
          "dataset/data/2",
          formDataSet,
          config
        );

        const formStats = new FormData();
        formStats.append("enableStat", "On");
        const responseStats = await Xibo.put(
          "/library/setenablestat/" + response.data.files[0].mediaId,
          formStats,
          config
        );

        // console.log("Following resDataSet data : ");
        // console.log(resDataSet);
      } else {
        //Fonctionnelle pour une diffusion globale des vidéos sur toutes les bornes du médecin, revoir le système pour une diffusion par borne
        let resDisplay = await Xibo.get("/display");

        let groupIds = [];
        let indexVideo = layoutIndex + 1;

        //formule pour toujours avoir un displayOrder impair
        let displayOrder = indexVideo * 2 - 1;
        for (let i = 0; i < resDisplay.data.length; i++) {
          groupIds.push(resDisplay.data[i].displayGroupId);
        }
        console.log(groupIds);
        const formDataSchedule = new FormData();
        formDataSchedule.append("eventTypeId", 1);
        formDataSchedule.append("displayOrder", displayOrder);
        formDataSchedule.append("isPriority", 0);
        formDataSchedule.append("campaignId", resPublishLayout.data.campaignId);
        formDataSchedule.append("displayGroupIds[]", groupIds);
        formDataSchedule.append("dayPartId", 2);
        await Xibo.post("/schedule", formDataSchedule, config);

        console.log("Following formDataSchedule data : ");
        console.log(formDataSchedule);
      }
      setShowLoading({ show: false, message: "Vidéo importée avec succès" });
      getLayouts();

      // console.log(response);
    } catch (error) {
      setShowLoading({ show: false, message: "Erreur innattendue." });
      console.log(error);
      console.log(error.message);
      console.log(error.response);
    }
  };
  function dateNow() {
    let dateNow = new Date();
    let minutes = ("0" + dateNow.getMinutes()).slice(-2);
    let seconds = ("0" + dateNow.getSeconds()).slice(-2);
    let stringDateNow =
      dateNow.getFullYear() +
      "-" +
      dateNow.getMonth() +
      "-" +
      dateNow.getDate() +
      " " +
      dateNow.getHours() +
      ":" +
      minutes +
      ":" +
      seconds;
    console.log(stringDateNow);
    return stringDateNow;
  }

  const handleChange = (e) => {
    let cloneUpload = upload.slice();
    let index = parseInt(e.target.id);
    cloneUpload[index] = e.target.files[0];
    setUpload(cloneUpload);
    //cutVid(e.target.files?.item(0));
  };

  const handleClickInput = (e) => {
    let index = parseInt(e.target.id);
    if (upload[index] == undefined || upload[index] == null) {
      alert("Veuillez sélectionner une vidéo en cliquant le bouton Parcourir.");
      return;
    }
    if (upload[index].type != "video/mp4") {
      alert("Seules les vidéos au format mp4 sont acceptées.");
      return;
    }
    setCurrIndex(index);
    let newInputValue = upload[index].name.split(".")[0].substring(0, 40);
    setInputValue(newInputValue);
    setShowInput(true);
    //libraryUpload(index, upload[index].name, upload[index]);
  };

  const closeConfirm = () => {
    setShowConfirm(false);
  };

  const closeInput = () => {
    setShowInput(false);
  };

  const changeInput = (e) => {
    setInputValue(e.target.value);
  };

  // ------------------------------------NEW FUNCTIONS -------------------------------

  const getSchedules = async () => {
    const res = await Xibo.get("/schedule");

    if (res && res.status === 200) {
      console.log("Schedules", res.data);
      if (res.data.length > 10) {
        alert("Alert .Too many layouts");
      }
    }
  };

  const getLayouts = async () => {
    Xibo.get(
      "/layout?embed=regions,playlists,widgets&retired=0&start=0&length=500"
    ).then((res) => {
      let retLayout = [];
      if (res.status == 200) {
        if (res.data.length > 10) {
          alert("Alert .Too many layouts");
        }
        for (let i = 0; i < res.data.length; i++) {
          if (
            res.data[i].publishedStatus == "Published" &&
            res.data[i].ownerId == cookies.user.userId
          ) {
            retLayout.push(res.data[i]);
          } else if (
            res.data[i].publishedStatus == "Published" &&
            res.data[i].ownerId != cookies.user.userId
          ) {
            // ajouter auto = true dans res.data[i]
            console.log("res.data[i]", res.data[i]);
            retLayout.push({ ...res.data[i], auto: true });
          }
        }

        let finalLayout = [];

        for (let i = 0; i < props.numberOfLayouts; i++) {
          let key = i + 1;
          let match = false;
          for (let j = 0; j < retLayout.length; j++) {
            let layoutName = retLayout[j].layout.split("__");
            let layoutIndex = layoutName[0];
            if (!match && layoutIndex == key) {
              finalLayout.push(retLayout[j]);
              match = true;
            }
          }
          if (!match) {
            finalLayout.push({
              layout: key + " - Aucune vidéo",
              id: i,
              media: [{ id: i, name: "" }],
            });
          }
        }
        setVideos(finalLayout);
        setOriginalVideos(finalLayout);
      }
    });
  };

  const saveOrderAPI = async () => {
    let dispToMod = [];

    for (let i = 0; i < videos.length; i++) {
      const splitName = videos[i].layout.split("__");
      if (!videos[i].layoutId || Number(splitName[0]) == i + 1) {
        continue;
      } else {
        const vidOrder = i * 2 + 1;
        dispToMod.push({
          nameOrder: i + 1,
          newRealOrder: vidOrder,
          video: videos[i],
        });
      }
    }

    if (dispToMod.length === 0) {
      setAlertType("info");
      setAlertMessage(
        "Aucun changement d'ordre effectué sur vos vidéos !\n\nVeuillez changer l'ordre des vidéos avant de sauvegarder !"
      );
      setShowAltert(true);
      return;
    }

    setShowLoading(true);

    try {
      const updResp = await Xpress.patch("/displays", dispToMod);
      setShowLoading(false);
      if (updResp.status === 200) {
        setAlertType("success");
        setAlertMessage("Ordre des vidéos sauvegardé avec success !");
        setShowAltert(true);
      } else if (updResp.status === 206 || updResp.status === 207) {
        setAlertType("warning");
        setAlertMessage("Ordre des vidéos sauvegardé avec des erreurs !");
        setShowAltert(true);
      }
      setToolView("main");
      setOrderHasChanged(false);
    } catch (error) {
      setShowLoading(false);
      console.error("Error updating layout", error);
      if (error.status === 409) {
        setAlertType("danger");
        setAlertMessage(
          "Erreur lors de la sauvegarde de l'ordre des vidéos ! \nTout à échoué !"
        );
        setShowAltert(true);
      } else {
        setAlertType("danger");
        setAlertMessage(
          "Erreur lors de la sauvegarde de l'ordre des vidéos ! \n Rafraichir la page avec F5 !"
        );
        setShowAltert(true);
      }
    }
    getLayouts();
    setIsOrderChanged(false);
  };

  const cancelOrder = () => {
    console.log("New video order canceled");
    setIsOrderChanged(false);
    setOrderHasChanged(false);
    setVideos(originalVideos);
  };

  const updateLayouts = async () => {
    getLayouts();
  };

  const moveVideo = (index, direction) => {
    console.log("Move video", index, direction);
    const newPosition = index + direction;
    if (newPosition < 0 || newPosition >= videos.length) return; // Out of bounds

    const newVideos = [...videos];
    [newVideos[index], newVideos[newPosition]] = [
      newVideos[newPosition],
      newVideos[index],
    ];
    setOrderHasChanged(true);
    setVideos(newVideos);
  };

  const moveVideoUp = (index) => moveVideo(index, -1);
  const moveVideoDown = (index) => moveVideo(index, 1);

  useEffect(() => {
    setTimeout(() => {
      setShowAltert(false);
    }, 5000);
  }, [showAlert]);

  useEffect(() => {
    UpdateAccessState(setAccessToken);
    setAccessToken(cookies.access_token);
    getLayouts();
    getTemplates();
  }, []);

  return (
    <div>
      <Container className="mt-1">
        {window.location.pathname == "/adboard/home" ? (
          <h1 style={{ marginTop: "20px", marginBottom: "20px" }}>
            Vos annonces
          </h1>
        ) : (
          <h1 style={{ marginTop: "20px", marginBottom: "20px" }}>
            Vidéos en diffusion
          </h1>
        )}
        {window.location.pathname == "/docboard/annonces" ? (
          <p style={{ marginTop: "35px", marginBottom: "25px" }}>
            <BsFillInfoCircleFill style={{ color: "#f8b5a8" }} />
            <i>
              {" "}
              Pour importer des vidéos que vous avez créées, faîtes les parvenir
              par email à{" "}
              <a
                href="mailto:media@lisaconnect.fr"
                style={{ color: "#6ebaa7" }}
              >
                {" "}
                media@lisaconnect.fr
              </a>{" "}
              avec les numéros des bornes concernées.
            </i>
          </p>
        ) : (
          <></>
        )}
        {window.location.pathname == "/docboard/annonces" && (
          <AnnonceTools
            videoOrder={videoOrder}
            changeOrderMod={changeOrderMod}
            setChangeOrderMod={setIsOrderChanged}
            isOrderChanged={isOrderChanged}
            orderHasChanged={orderHasChanged}
            saveOrderAPI={saveOrderAPI}
            cancelOrder={cancelOrder}
            changeView={toolView}
          />
        )}

        <Row
          id="video-row-bloc"
          style={{ flexDirection: "row", justifyContent: "space-evenly" }}
        >
          {videos &&
            videos.map((video, index) => {
              let statutAnnonce = "";
              let statutAttente = false;
              let hideInput = "";
              let hideDelBtn = "collapse";
              let displayVideo = "";
              let layId = index;
              let medId = index;
              let medName = "";

              let uniqueVideoId = Math.random() * 1000000;

              let layoutName = video.layout.split("__");
              let layoutIndex = layoutName[0];

              if (window.location.pathname == "/docboard/annonces") {
                // if (window.location.pathname == "/adboard/home") {
                hideInput = "collapse";
              }

              if (layoutIndex == (index + 1).toString()) {
                hideInput = "collapse";
                hideDelBtn = "";
                layId = video.layoutId;
                if (video.regions[0].regionPlaylist.widgets.length !== 0) {
                  medId =
                    video.regions[0].regionPlaylist.widgets[0].mediaIds[0];
                  uniqueVideoId =
                    video.regions[0].regionPlaylist.widgets[0].mediaIds[0];
                }
                // medId = video.regions[0].regionPlaylist.widgets[0].mediaIds[0];
                medName = video.layout.split("__")[2];

                if (props.annonceur) {
                  switch (video.description) {
                    case "en attente":
                      statutAnnonce = (
                        <Badge variant="warning">
                          En attente de validation
                        </Badge>
                      );
                      statutAttente = true;
                      break;
                    case "validé":
                      statutAnnonce = <Badge variant="success">Validé</Badge>;
                      break;
                    case "rejeté":
                      statutAnnonce = (
                        <Badge style={{ fontSize: 8.3 }} variant="danger">
                          Rejeté - Contactez Lisa Connect pour plus
                          d'informations
                        </Badge>
                      );
                      break;
                  }
                }

                // break;
              } else {
                displayVideo = "";
              }

              return (
                <div
                  // xs={12}
                  // sm={6}
                  // md={4}
                  // lg={3}
                  key={`col-${uniqueVideoId}-${index}`}
                  className="mb-5"
                  // style={{ maxWidth: "unset !important" }}
                  style={{ marginLeft: "15px", marginRight: "15px" }}
                >
                  <Card
                    style={{
                      maxWidth: "300px",
                      minWidth: "153px",
                      width: "30vw",
                      aspectRatio: "0.537",
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    }}
                  >
                    <Card.Header
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        position: "relative",
                      }}
                    >
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          // marginRight: "36px", // Assurez-vous que cette marge est suffisante pour votre icône de suppression
                          maxWidth: "calc(100% - 40px)", // Ajustez cette valeur pour s'assurer que le texte ne chevauche pas le bouton
                        }}
                        title={
                          video.layout && video.layout.includes("__")
                            ? `${video.layout.split("__")[0]} - ${
                                video.layout.split("__")[2]
                              }`
                            : video.layout
                        }
                      >
                        {video.layout && video.layout.includes("__") ? (
                          <b>
                            {video.layout.split("__")[0] +
                              " - " +
                              video.layout.split("__")[2]}
                          </b>
                        ) : (
                          video.layout
                        )}
                      </span>
                      <Button
                        className={`${hideDelBtn} float-right`}
                        size="sm"
                        variant="danger"
                        id={video.layoutId + "_" + index + "_buttonDelete"}
                        onClick={(e) => {
                          setMediaName(medName);
                          setStatutAttenteDelete(statutAttente);
                          setEventClickDelete(video.layoutId + "_" + index);
                          setShowConfirm(true);
                        }}
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                        }}
                      >
                        <BsTrash />
                      </Button>
                      <span> {statutAnnonce}</span>
                    </Card.Header>

                    <Card.Body
                      key={`cardbody-${uniqueVideoId}-${index}`}
                      className="modal-center"
                      style={{
                        // minHeight: "385px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      {video &&
                      video.regions &&
                      video.regions.length > 0 &&
                      video.regions[0].regionPlaylist.widgets.length > 0 ? (
                        video.description &&
                        video.description.includes("autoVideo") ? (
                          <VideoAuto
                            index={index}
                            autoData={video}
                            templates={boucleTemplates}
                            updateLayouts={updateLayouts}
                          />
                        ) : (
                          <video
                            width="-webkit-fill-available"
                            height="auto"
                            muted
                            controlsList="nodownload noremoteplayback noplaybackrate "
                            style={{
                              width: "100%",
                              height: "auto",
                              objectFit: "cover",
                              boxShadow:
                                "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                              borderRadius: "4px",

                              // border: "1px #e0e0e0 solid",
                              // bordeRadius: "8px",
                            }}
                            onMouseOver={(e) => (e.target.controls = true)}
                            onMouseOut={(e) => (e.target.controls = false)}
                          >
                            <source
                              src={
                                `${XiboURL}/api/library/download/` +
                                video.regions[0].regionPlaylist.widgets[0]
                                  .mediaIds[0] +
                                "/video?access_token=" +
                                accessToken.split(" ")[1]
                              }
                              type="video/mp4"
                            />
                            <source src="movie.ogg" type="video/ogg" />
                          </video>
                        )
                      ) : props.annonceur ? (
                        <input
                          className={hideInput + " input-video"}
                          type="file"
                          name={index + "file"}
                          id={index + "file"}
                          onChange={(e) => handleChange(e)}
                        />
                      ) : cookies.user.userName == "medecin-exemple" ||
                        cookies.user.userName == "medecin-220042" ||
                        cookies.user.userName == "richard-dell-medecin" ? (
                        <VideoAuto
                          index={index}
                          templates={boucleTemplates}
                          updateLayouts={updateLayouts}
                        />
                      ) : (
                        <></>
                      )}
                      {video.auto && <span>VIDEO AUTO</span>}
                    </Card.Body>
                    {props.annonceur && !video.regions && (
                      <Card.Footer className={hideInput + " footer-add-btns"}>
                        <Button
                          variant="primary"
                          id={index + "buttonUpload"}
                          onClick={(e) => handleClickInput(e)}
                          className={"add-buttons"}
                        >
                          Ajouter une vidéo
                        </Button>
                      </Card.Footer>
                    )}
                  </Card>
                  {isOrderChanged && video.layoutId && (
                    <>
                      <Button
                        size="sm"
                        style={{
                          position: "relative",
                          top: "calc(-50% + 31px)",
                          left: "3px",
                          rotate: "-90deg",
                          zIndex: "15",
                        }}
                        onClick={() => moveVideoUp(index)}
                      >
                        &uarr;
                      </Button>
                    </>
                  )}
                  {isOrderChanged && video.layoutId && (
                    <>
                      <Button
                        size="sm"
                        style={{
                          position: "relative",
                          bottom: "calc(50% - 31px)",
                          left: "calc(100% - 54px)",
                          rotate: "-90deg",
                          zIndex: "15",
                        }}
                        onClick={() => moveVideoDown(index)}
                      >
                        &darr;
                      </Button>
                    </>
                  )}
                </div>
              );
            })}
        </Row>

        {props.annonceur && (
          <p style={{ fontSize: 10 }}>
            <i>
              Avant d'être diffusées vos annonces seront soumises à la
              validation des administrateurs dans un délai de 48 heures après
              leur mise en ligne.
            </i>
          </p>
        )}
        <div
          className="novalidatedvideo"
          id="novalidatedvideo"
          style={{ marginLeft: "40px", display: "none" }}
        >
          <br /> <br />
          Aucune vidéo validée.
          <br /> <br />
          Contactez votre référent Lisa Connect ou envoyez un email à
          media@lisaconnect.fr
        </div>
      </Container>

      <ConfirmationModal
        show={showConfirm}
        closeModal={closeConfirm}
        message="Supprimer votre annonce ?"
        confirm={handleClickDelete}
      />
      {/* <LoadingModal show={showLoading2.show} />
      <LoadingModal show={showLoading.show} /> */}
      <InputModal
        show={showInput}
        message="Nommez votre annonce"
        onChange={changeInput}
        closeModal={closeInput}
        value={inputValue}
        confirm={() => {
          closeInput();
          libraryUpload(currIndex, upload[currIndex].name, upload[currIndex]);
        }}
      />

      {showAlert && (
        <div className="alert-container-central">
          <Alert id="Annonces_Alert" variant={alertType}>
            <b>{alertMessage}</b>
          </Alert>
        </div>
      )}

      {showLoading && <LoadingModal show={showLoading} />}
    </div>
  );
};

export default VideoReorder;

import { useState, useRef, useLayoutEffect, useEffect } from "react";

const PADDINGS = 110;

const useSliding = (elementWidth, countElements) => {
  const containerRef = useRef(null);
  const [oldContainerWidth, setOldContainerWidth] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const [sizeChanged, setSizeChanged] = useState(false);
  const [distance, setDistance] = useState(0);
  const [totalInViewport, setTotalInViewport] = useState(5);
  const [viewed, setViewed] = useState(0);

  useLayoutEffect(() => {
    function updateSize(triggerChange = true) {
      const containerWidth = containerRef.current.clientWidth - PADDINGS;
      setContainerWidth(containerWidth);
      if (!triggerChange) setOldContainerWidth(containerWidth);
      setSizeChanged(triggerChange);
      //setTotalInViewport(Math.floor(containerWidth / elementWidth));
    }
    window.addEventListener("resize", updateSize);
    updateSize(false);
    return () => window.removeEventListener("resize", updateSize);
  }, [containerRef.current]);

  useEffect(() => {
    if (sizeChanged) {
      setSizeChanged(false);
      if (distance !== 0) {
        let isPositive = true;
        let positiveDist = distance;
        if (distance < 0) {
          isPositive = false;
          positiveDist *= -1;
        }
        console.log(" pos : " + positiveDist);
        console.log(" oldCont : " + oldContainerWidth);
        let distPercentage = (positiveDist / oldContainerWidth) * 100;
        let newDist = (containerWidth * distPercentage) / 100;
        if (!isPositive) {
          newDist *= -1;
        }

        console.log("new " + newDist);
        setDistance(newDist);
        setOldContainerWidth(containerWidth);
      }
    }
  }, [containerWidth, oldContainerWidth, distance, sizeChanged]);

  const handlePrev = () => {
    let currViewed = viewed - totalInViewport;
    let currDistance = distance;
    let prevElements = viewed;

    //s'il ne reste pas assez d'éléments pour remplir le viewport le slide s'arrête au dernier élément
    if (prevElements < totalInViewport) {
      currDistance += elementWidth * prevElements;
      currViewed = viewed - prevElements;
    } else {
      currDistance += containerWidth;
    }
    setViewed(currViewed);
    setDistance(currDistance);
  };

  const handleNext = () => {
    let currViewed = viewed + totalInViewport;
    let currDistance = distance;
    let nextElements = countElements - currViewed;

    //s'il ne reste pas assez d'éléments pour remplir le viewport le slide s'arrête au dernier élément
    if (nextElements < totalInViewport) {
      currDistance -= elementWidth * nextElements;
      currViewed = viewed + nextElements;
    } else {
      currDistance -= containerWidth;
    }
    setViewed(currViewed);
    setDistance(currDistance);
  };

  const slideProps = {
    style: { transform: `translate3d(${distance}px, 0, 0)` },
  };

  const hasPrev = distance < 0;
  const hasNext = viewed + totalInViewport < countElements;

  return { handlePrev, handleNext, slideProps, containerRef, hasPrev, hasNext };
};

export default useSliding;

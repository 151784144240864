import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Form } from "react-bootstrap";
import {
  BsFileEarmarkPlus,
  BsFilePlus,
  BsFillPlusCircleFill,
  BsFillPlusSquareFill,
  BsPlus,
  BsPlusCircle,
  BsPlusCircleFill,
  BsPlusSquare,
} from "react-icons/bs";
import {
  CreatePrinterConfig,
  DeletePrinterConfig,
  GetAllTemplates,
  ModifyPrinterConfig,
} from "../../services/PrintsServices";

export default function PrintsConfigs({
  dataConfig,
  setShowSection,
  resetSelector,
}) {
  const [formData, setFormData] = useState({
    borne_id: "",
    is_custom: true,
    prints: [],
  });
  const [templates, setTemplates] = useState([]);
  const [showHiddenCol, setShowHiddenCol] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const [printValidity, setPrintValidity] = useState([]);

  const [showAlertFailed, setShowAlertFailed] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlertSuccess, setShowAlertSuccess] = useState(false);

  const handleTempSelect = (e, index) => {
    console.log("e.target.value ", e.target.value);
    const isGoodSelect = e.target.value !== "--- Select Print Template ---";
    const newPrints = [...formData.prints];
    newPrints[index]._id = e.target.value;
    newPrints[index].name = e.target.value;
    setFormData({ ...formData, prints: newPrints });

    // setPrintValidity([...printValidity, isGoodSelect]);
    // update the printValidity array
    const newValidity = printValidity;
    newValidity[index] = isGoodSelect;
    setPrintValidity(newValidity);
  };

  const renderPrintsList = () => {
    return formData.prints.map((print, index) => {
      return (
        <div key={index} className="mt-3">
          <Form.Group>
            <Form.Row>
              <Form.Label column="sm" lg={2} className="text-center">
                {index + 1}
              </Form.Label>
              <Col>
                <Form.Control
                  size="sm"
                  as="select"
                  onChange={(e) => handleTempSelect(e, index)}
                  isInvalid={!printValidity[index]}
                  key={index}
                  // value={formData.prints[index].name}
                  value={formData.prints[index]._id}
                >
                  <option key={"default"}>--- Select Print Template ---</option>
                  {templates.data && templates.data.data.length > 0 ? (
                    templates.data.data.map((template) => {
                      return (
                        <option key={template._id} value={template._id}>
                          {template.name}
                        </option>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </Form.Control>
              </Col>
              <Button
                size="sm"
                variant="outline-danger"
                onClick={() => removePrintList(index)}
              >
                x
              </Button>
            </Form.Row>
          </Form.Group>
        </div>
      );
    });
  };

  const addPrintList = () => {
    setFormData({
      ...formData,
      prints: [...formData.prints, { name: "" }],
    });
    setPrintValidity([...printValidity, true]);
  };

  function removePrintList(index) {
    const newPrints = [...formData.prints];
    newPrints.splice(index, 1);
    setFormData({ ...formData, prints: newPrints });
    const newValidity = printValidity;
    newValidity.splice(index, 1);
    setPrintValidity(newValidity);
  }

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "switch" || type === "checkbox") {
      setFormData({ ...formData, [name]: checked });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSecretMacroClick = () => {
    const newCount = clickCount + 1;
    setClickCount(newCount);

    if (newCount === 5) {
      setShowHiddenCol(!showHiddenCol);
      setClickCount(0);
    }
  };

  const cleanForm = () => {
    if (dataConfig) {
      setFormData(dataConfig);
      setPrintValidity(
        dataConfig.prints.map((print) => print._id !== "" && print._id !== null)
      );
    } else {
      setFormData({
        borne_id: "",
        is_custom: false,
        prints: [],
      });
      setPrintValidity([]);
    }
  };

  function checkPrintsValidity() {
    const validity = formData.prints.map(
      (print) =>
        print.name !== "" &&
        print.name !== "--- Select Print Template ---" &&
        print._id !== "" &&
        print._id !== "--- Select Print Template ---"
    );

    if (validity.includes(false)) {
      setPrintValidity(validity);
      return false;
    } else {
      return true;
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.length === 0 || !checkPrintsValidity()) {
      return;
    }

    if (dataConfig) {
      ModToAPI(e);
    } else {
      SaveToAPI(e);
    }
  };

  async function SaveToAPI(e) {
    e.preventDefault();
    const creation = await CreatePrinterConfig(formData);
    if (creation.error) {
      setAlertMessage(creation.data.toString() || "Network Error");
      setShowAlertFailed(true);
    } else {
      setShowAlertSuccess(true);
      startReset();
    }
  }

  async function ModToAPI(e) {
    e.preventDefault();
    const modification = await ModifyPrinterConfig(dataConfig._id, formData);
    if (modification.error) {
      setAlertMessage(modification.data.toString() || "Network Error");
      setShowAlertFailed(true);
    } else {
      setShowAlertSuccess(true);
      startReset();
    }
  }

  const deletePC = async (e) => {
    e.preventDefault();
    const deletion = await DeletePrinterConfig(dataConfig._id);
    if (deletion.error) {
      setAlertMessage(deletion.data.toString() || "Network Error");
      setShowAlertFailed(true);
    } else {
      setShowAlertSuccess(true);
      startReset();
    }
  };

  function startReset() {
    cleanForm();
    // setShowSection(false);
    setTimeout(() => {
      resetSelector();
    }, 2000);
  }

  useEffect(() => {
    if (dataConfig) {
      setFormData(dataConfig);
      setPrintValidity(
        dataConfig.prints.map((print) => print._id !== "" && print._id !== null)
      );
    }
    async function fetchData() {
      const allTemplates = await GetAllTemplates();
      setTemplates(allTemplates);
    }
    fetchData();
  }, [dataConfig]);

  return (
    <Container className="forms-deco" id="printCustomCreate">
      <Col>
        {/* <Form onSubmit={handleSubmit} onReset={cleanForm}> */}
        <Form onReset={cleanForm}>
          <Form.Row>
            <Col className="mr-4">
              <Form.Group className="mb-3">
                <Form.Label>Borne</Form.Label>
                <Form.Control
                  type="text"
                  name="borne_id"
                  required
                  onChange={handleInputChange}
                  placeholder="ex:  B-2v1-220086"
                  size="sm"
                  className="ml-2 mr-2"
                  value={formData.borne_id}
                />
              </Form.Group>
            </Col>
            {showHiddenCol && (
              <Col xs={2} style={{ textAlign: "center", alignSelf: "center" }}>
                <Form.Group className="">
                  <Form.Check
                    type="checkbox"
                    label='"Custom" label ?'
                    name="is_custom"
                    onChange={handleInputChange}
                    size="sm"
                    checked={formData.is_custom}
                  />
                </Form.Group>
              </Col>
            )}
            <Col xs="6">
              <div>
                <span id="secret-macro-prints" onClick={handleSecretMacroClick}>
                  Prints
                </span>
              </div>
              <section className="ml-2 mr-2">
                <div id="printsCustList">
                  {formData.prints &&
                    formData.prints.length > 0 &&
                    renderPrintsList()}
                </div>
                <div>
                  <Button variant="light" size="sm" onClick={addPrintList}>
                    <BsPlusSquare />
                  </Button>
                </div>
              </section>
            </Col>
          </Form.Row>
          {/* --------------- FORM BUTTONS ---------------- */}
          <Form.Row className="mt-4">
            {dataConfig ? (
              <Col className="text-right">
                <Button variant="danger" onClick={deletePC} size="sm">
                  Delete
                </Button>
              </Col>
            ) : (
              <Col className="text-right">
                <Button variant="warning" type="reset" size="sm">
                  Clean
                </Button>
              </Col>
            )}

            <Col className="text-left">
              {/* <Button variant="success" type="submit" size="sm"> */}
              <Button variant="success" onClick={handleSubmit} size="sm">
                {dataConfig ? "Modifier" : "Créer"}
              </Button>
            </Col>
          </Form.Row>
        </Form>
      </Col>
      <Alert
        show={showAlertFailed}
        key={"fileFail"}
        variant={"danger"}
        className="alertsForms"
      >
        <Alert.Heading>Network Error</Alert.Heading>
        <p>
          <br />
          <i> Something went wrong during your last action :(</i> <br />
          Maybe you have not the rights to do this...
          <br />
          Try to : <br />
          - Refresh the page and try again. <br />
          - Log out from this dashboard and login another time
          <br /> <br />
          If the problem persists, contact the{" "}
          <b>
            <a href="mailto: richard@lisaconnect.fr" target="_blank">
              administrator
            </a>
            .
          </b>
        </p>
        <div>{alertMessage}</div>
        <div className="d-flex justify-content-end">
          <Button
            onClick={() => setShowAlertFailed(false)}
            variant="outline-danger"
          >
            OK
          </Button>
        </div>
      </Alert>
      <Alert
        show={showAlertSuccess}
        variant="success"
        className="alertsForms"
        id="AlertSuccessFile"
      >
        <Alert.Heading>
          Prints Config successfully {dataConfig ? "Updated" : "Created"}
        </Alert.Heading>
        <hr />
        <div className="d-flex justify-content-center">
          <Button
            onClick={() => setShowAlertSuccess(false)}
            variant="outline-success"
            size="sm"
            className="btn-icon-list"
          >
            OK
          </Button>
        </div>
      </Alert>
    </Container>
  );
}

import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import "../assets/css/Docboard.css"

function ConfirmationModal(props) {
    return (
        <Modal
        show={props.show}
        onHide={props.closeModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.message}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Button className="mr-3" variant="primary" onClick={props.confirm}>
                Confirmer
            </Button>
            <Button variant="danger" onClick={props.closeModal}>
                Annuler
            </Button>
        </Modal.Body>
      </Modal>
    );
}

export default ConfirmationModal;

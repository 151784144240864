import React, { useEffect, useRef } from "react";
import mbxClient from "@mapbox/mapbox-sdk";
import mbxGeocoding from "@mapbox/mapbox-sdk/services/geocoding";
import { useState } from "react";
import { Xpress } from "../../APIs/api-xibo";
import { useCookies } from "react-cookie";
import { Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import ExcelUploader from "./ExcelUploader";

const baseClient = mbxClient({
  accessToken:
    "pk.eyJ1IjoicmljaC1sYyIsImEiOiJjbGo2eWR5aHgwcTgxM2NwYWdqNjhhNTBkIn0.S5Dp1t66H5VexJ6rYksxEA",
});
const geocodingService = mbxGeocoding(baseClient);

function LeadsList() {

  const [target, setTarget] = useState(null);

  const formRef = useRef(null);

  const cookies = useCookies(["access_token"]);

  const [bornes, setBornes] = useState([]);

  const [error, setError] = useState(null);



  function getBornes() {
    const formData = new FormData();
    formData.append("access_token", cookies[0].access_token);

    Xpress.get("/leads/bornes", formData)
      .then((res) => {
        // console.log(res.data);
        setBornes(res.data.data);
      })
      .catch((err) => {});
  }



  const handleSelect = (event) => {
    console.log(event.target.value);
    setTarget(event.target.value);
  };

  useEffect(() => {
    getBornes();
  }, []);

  return (
    <div>
      <Form className="G-form interf-forms" ref={formRef}>
        <Row style={{ margin: "0" }}>
          <Col>
            <Form.Group controlId="borneSelect" className="interfSectionsH3">
              <Form.Label>Numéro de la borne</Form.Label>
              <Form.Control
                required
                as="select"
                defaultValue=""
                size="sm"
                onChange={handleSelect}
              >
                <option key={"empty"}></option>
                {bornes.map((borne) => {
                  return (
                    <option
                      key={borne.id + "-" + borne.borne}
                      value={`${borne.latitude},${borne.longitude}`}
                    >
                      {borne.borne} - {borne.adresse}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </Col>

        </Row>
      </Form>
      {target ? <ExcelUploader target={target} /> : <></>}
    </div>
  );
}

export default LeadsList;

import { useState } from "react";
import axios from "axios";
import GetIpClient from "./getIp";
import { XapiURL, GestionURL } from "../APIs/api-xibo";

async function StopDisplaysProofs(mediaID, mediaName, cookies) {
  const Xpress = axios.create({
    baseURL: `${XapiURL}/api`,
    withCredentials: true,
    crossDomain: true,
    origin: GestionURL,
  });
  let clientIp = await GetIpClient();


  let proof;
  try {
    proof = await Xpress.post(
      `/proof/stop?userID=${cookies.user.userId}&userName=${cookies.user.userName}&ip=${clientIp}&videoID=${mediaID}&videoName=${mediaName}`
    );
    if (proof.status === 201 || proof.status === 200) {
      return true;
    } else {
      return "Proof not created";
    }
  } catch (error) {
    return error;
  }
}

export default StopDisplaysProofs;

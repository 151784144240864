import React, { useEffect, useState } from "react";
import { Button, Form, Container, Row, Col, Alert } from "react-bootstrap";
import { BsFillPlusCircleFill, BsPlus } from "react-icons/bs";
import { FaRegTrashAlt } from "react-icons/fa";
import { MdRefresh } from "react-icons/md";
import AutoVidServices from "../../services/AutoVideoServices";

function XiboVideoForm({ xiboVid, xiboMed, refreshXiboVid }) {
  const [selected, setSelected] = useState("");
  const [name, setName] = useState("");
  const [mediaId, setMediaId] = useState("");
  const [layoutId, setLayoutId] = useState("");

  const [xibovideo, setXibovideo] = useState(undefined);

  const [mode, setMode] = useState(undefined);
  const [secCount, setSecCount] = useState(0);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");

  const PostNewXiboVideo = async (e) => {
    e.preventDefault();

    const bodyContent = {
      name: name,
      xibo_mediaId: mediaId,
      xibo_layoutId: layoutId,
    };

    const creation = await AutoVidServices.PostNewXiboVideo(bodyContent);

    if (!creation.success) {
      setAlertMessage(creation.error.response.data.message);
      setAlertType("danger");
      setShowAlert(true);
    } else {
      setAlertMessage("Data sent successfully");
      setAlertType("success");
      setShowAlert(true);

      refreshXiboVid();
      resetSelection();
    }
  };

  async function DeleteXiboVideo(event) {
    event.preventDefault();

    const deleted = await AutoVidServices.DeleteXiboVideo(xibovideo._id);

    if (!deleted.success) {
      setAlertMessage(deleted.error.response.data.message);
      setAlertType("danger");
      setShowAlert(true);
    } else {
      setAlertMessage("Data deleted successfully");
      setAlertType("success");
      setShowAlert(true);

      refreshXiboVid();
      resetSelection();
    }
  }

  const ModifyXiboVideo = async (data) => {
    setSelected(data.target.value);
    setXibovideo(
      xiboVid?.find((xibovideo) => xibovideo.name === data.target.value)
    );
    setMode("mod");
  };

  const resetSelection = () => {
    setSelected("");
    setXibovideo(undefined);
    setName("");
    setMediaId("");
    setLayoutId("");
    setMode(undefined);
  };

  const selectNewMedia = (data) => {
    const target = xiboMed?.find(
      (xibomedia) => xibomedia.name === data.target.value
    );
    setName(data.target.value);
    setMediaId(target.mediaId);
  };

  useEffect(() => {
    if (xibovideo && xibovideo._id) {
      setName(xibovideo.name);
      setMediaId(xibovideo.xibo_mediaId);
      xibovideo.xibo_layoutId ? setLayoutId(xibovideo.xibo_layoutId) : <></>;
    }
  }, [xibovideo]);

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  }, [showAlert]);

  return (
    <Container className="forms-deco">
      <Container>
        <Form>
          <Form.Row>
            <Form.Group as={Col} controlId="xibo_controller">
              {mode !== "new" ? (
                <Form.Control
                  as="select"
                  onChange={ModifyXiboVideo}
                  value={selected}
                >
                  <option value={"0"}> -- Select --</option>
                  {/* {xibovideos.map((xibovideo) => ( */}
                  {xiboVid.map((xibovideo) => (
                    <option key={xibovideo._id} value={xibovideo.name}>
                      {xibovideo.name}
                    </option>
                  ))}
                </Form.Control>
              ) : (
                <></>
              )}
            </Form.Group>

            <Form.Group
              as={Col}
              xs={3}
              controlId="actions"
              className="text-right"
            >
              {mode ? (
                <>
                  <Button
                    style={{
                      borderRadius: "50%",
                      backgroundColor: "unset",
                      border: "none",
                      padding: "0px",
                      marginRight: "20px",
                    }}
                    onClick={resetSelection}
                  >
                    <MdRefresh size={28} color="grey" />
                  </Button>
                  {xibovideo ? (
                    <Button
                      style={{
                        borderRadius: "50%",
                        backgroundColor: "unset",
                        border: "none",
                        padding: "0px",
                      }}
                      onClick={(e) => DeleteXiboVideo(e)}
                    >
                      <FaRegTrashAlt size={28} color="grey" />
                    </Button>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Button
                  style={{
                    borderRadius: "50%",
                    backgroundColor: "unset",
                    border: "2px solid grey",
                    padding: "0px",
                  }}
                  onClick={() => setMode("new")}
                >
                  <BsPlus size={32} color="grey" />
                </Button>
              )}
            </Form.Group>
          </Form.Row>
        </Form>
      </Container>
      <Container>
        <Row>
          {mode === "new" ? (
            <Col md={{ span: 6, offset: 3 }}>
              <Form onSubmit={PostNewXiboVideo}>
                {secCount === 7 ? (
                  <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Group>
                ) : (
                  <></>
                )}
                <Form.Group className="mb-3">
                  <Form.Label onClick={() => setSecCount(secCount + 1)}>
                    Media
                  </Form.Label>
                  <Form.Group as={Col} controlId="xibo_controller">
                    <Form.Control as="select" onChange={selectNewMedia}>
                      <option> -- Select --</option>
                      {/* {xibomedias.map((xibomedia) => ( */}
                      {xiboMed.map((xibomedia) => (
                        <option key={xibomedia.mediaId} value={xibomedia.name}>
                          {xibomedia.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Layout ID (optional)</Form.Label>
                  <Form.Control
                    type="text"
                    value={layoutId}
                    onChange={(e) => setLayoutId(e.target.value)}
                  />
                </Form.Group>
                <Button variant="success" type="submit">
                  Submit
                </Button>
              </Form>
            </Col>
          ) : (
            <></>
          )}
        </Row>
      </Container>
      {showAlert && (
        <div className="alert-container-central">
          <Alert id="Annonces_Alert" variant={alertType}>
            <b>{alertMessage}</b>
          </Alert>
        </div>
      )}
    </Container>
  );
}

export default XiboVideoForm;

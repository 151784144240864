import React from 'react';
import cx from 'classnames';
import SliderContext from './context'
import ShowDetailsButton from './ShowDetailsButton'
import Mark from './Mark'
import './Item.scss'

const Item = ({ annonce }) => (
  <SliderContext.Consumer>
    {({ onSelectSlide, currentSlide, elementRef }) => {
      const isActive = currentSlide && currentSlide.id === annonce.id;

      return (
        <div
          ref={elementRef}
          className={cx('item', {
            'item--open': isActive,
          })}
        >
          <img style={{cursor: "pointer"}} loading="lazy" src={annonce.image} alt="" onClick={() => onSelectSlide(annonce)}/>
        </div>
      );
    }}
  </SliderContext.Consumer>
);

export default Item;

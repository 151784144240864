import React, { useRef, useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { checkMatchingLength, getValue } from "../../services/Utils";
import { useEffect } from "react";
import ViewInterf_2 from "../Interfaces/ViewInterf_2";
import {
  ModifyInterfaceLinks,
  PostNewUrlsToKio,
} from "../../services/InterfaceServices";

export default function LinksConfigMod({ dataBorne }) {
  const [modData, setModData] = useState();
  const [modData2, setModData2] = useState();

  const [showAlertInterfFailed, setShowAlertInterfFailed] = useState(false);
  const [showAlertInterfSuccess, setShowAlertInterfSuccess] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");

  const handleSubmitMod = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (
      form.checkValidity() === false ||
      !checkMatchingLength(modData2, modData?.NbLiens, false) ||
      !checkMatchingLength(modData2, modData?.NbLiens, true)
    ) {
      event.preventDefault();
      // bloque refresh de la page
      event.stopPropagation();
    } else {
      const saveNewUrls = await PostNewUrlsToKio(event.target);
      if (!saveNewUrls.error) {
        const resp = await ModifyInterfaceLinks(modData.id, event.target);
        if (!resp.error) {
          setShowAlertInterfSuccess(true);
        } else {
          setShowAlertInterfFailed(true);
        }
      } else {
        setShowAlertInterfFailed(true);
      }
    }
  };

  function setTemplate(dataTarget) {
    let formatedData = [];
    if (dataTarget) {
      if (dataTarget instanceof HTMLFormControlsCollection) {
        for (let i = 1; i <= 6; i++) {
          formatedData.push({
            img: getValue(`image${i}`, dataTarget),
            url: getValue(`link${i}`, dataTarget),
          });
        }
      } else {
        for (let i = 1; i <= 6; i++) {
          formatedData.push({
            img: getValue(`I${i}`, dataTarget),
            url: getValue(`L${i}`, dataTarget),
          });
        }
      }
      return formatedData;
    }
  }

  useEffect(() => {
    if (dataBorne) {
      setModData(dataBorne[0]);
      setModData2(setTemplate(dataBorne[0]));
    }
  }, [dataBorne]);

  useEffect(() => {
    if (modData) {
      setModData2(setTemplate(modData));
    }
  }, [modData]);

  return (
    <Container>
      <Container>
        {modData && (
          <Form
            className="G-form interf-forms forms-bg"
            // validated={validated3}
            onSubmit={handleSubmitMod}
            // onChange={handleFormModsChange}
            // ref={formRef}
          >
            <Row>
              <Col xs={7} className={"nodisplay"}>
                <Form.Group controlId="borneId">
                  <Form.Label>Numéro de la borne </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    size="sm"
                    placeholder="B-2v1-220010"
                    value={modData?.NumeroBorne || ""}
                    onChange={(e) => {
                      setModData({ ...modData, NumeroBorne: e.target.value });
                    }}
                  />
                  <Form.Text id="borneIdHelpBloc" muted>
                    Format obligatoire : B-2v1-220010
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    Renseigner le Numéro de la borne
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="refClient">
                  <Form.Label>Ref Client</Form.Label>
                  <Form.Control
                    type="text"
                    size="sm"
                    placeholder=""
                    value={modData?.RefClient || ""}
                    onChange={(e) => {
                      setModData({ ...modData, RefClient: e.target.value });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={5}>
                <Form.Group controlId="nbLinks">
                  <Form.Label>Nombre d'icones</Form.Label>
                  <Form.Control
                    required
                    as="select"
                    size="sm"
                    value={modData?.NbLiens || ""}
                    onChange={(e) => {
                      setModData({ ...modData, NbLiens: e.target.value });
                    }}
                    isInvalid={
                      !checkMatchingLength(modData2, modData?.NbLiens, false)
                    }
                  >
                    <option></option>
                    <option value={"1"} key={1}>
                      1
                    </option>
                    <option value={"2"} key={2}>
                      2
                    </option>
                    <option value={"3"} key={3}>
                      3
                    </option>
                    <option value={"4"} key={4}>
                      4
                    </option>
                    <option value={"5"} key={5}>
                      5
                    </option>
                    <option value={"6"} key={6}>
                      6
                    </option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="link1">
                  <Form.Label>URL lien 1</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    as="textarea"
                    rows={1}
                    size="sm"
                    placeholder="URL lien 1"
                    value={modData?.L1 || ""}
                    onChange={(e) => {
                      setModData({ ...modData, L1: e.target.value });
                    }}
                    isInvalid={!modData?.L1 && modData?.I1}
                  />
                  <Form.Text id="link1HelpBloc" muted>
                    Format : https://www.site.fr
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="image1">
                  <Form.Label>Icone 1</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    size="sm"
                    placeholder="icon.png"
                    value={modData?.I1 || ""}
                    onChange={(e) => {
                      setModData({ ...modData, I1: e.target.value });
                    }}
                    isInvalid={!modData?.I1 && modData?.L1}
                  />
                  <Form.Text id="icon1HelpBloc" muted>
                    Format : icon1.png
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group controlId="link2">
                  <Form.Label>URL lien 2</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    as="textarea"
                    rows={1}
                    size="sm"
                    placeholder="URL lien 2"
                    value={modData?.L2 || ""}
                    onChange={(e) => {
                      setModData({ ...modData, L2: e.target.value });
                    }}
                    isInvalid={!modData?.L2 && modData?.I2}
                  />
                  <Form.Text id="link2HelpBloc" muted>
                    Format : https://www.site.fr
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="image2">
                  <Form.Label>Icone 2</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    size="sm"
                    placeholder="icon.png"
                    value={modData?.I2 || ""}
                    onChange={(e) => {
                      setModData({ ...modData, I2: e.target.value });
                    }}
                    isInvalid={!modData?.I2 && modData?.L2}
                  />
                  <Form.Text id="icon2HelpBloc" muted>
                    Format : icon2.png
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="link3">
                  <Form.Label>URL lien 3</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    as="textarea"
                    rows={1}
                    size="sm"
                    placeholder="URL lien 3"
                    value={modData?.L3 || ""}
                    onChange={(e) => {
                      setModData({ ...modData, L3: e.target.value });
                    }}
                    isInvalid={!modData?.L3 && modData?.I3}
                  />
                  <Form.Text id="link3HelpBloc" muted>
                    Format : https://www.site.fr
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="image3">
                  <Form.Label>Icone 3</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    size="sm"
                    placeholder="icon.png"
                    value={modData?.I3 || ""}
                    onChange={(e) => {
                      setModData({ ...modData, I3: e.target.value });
                    }}
                    isInvalid={!modData?.I3 && modData?.L3}
                  />
                  <Form.Text id="icon3HelpBloc" muted>
                    Format : icon3.png
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="link4">
                  <Form.Label>URL lien 4</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    as="textarea"
                    rows={1}
                    size="sm"
                    placeholder="URL lien 4"
                    value={modData?.L4 || ""}
                    onChange={(e) => {
                      setModData({ ...modData, L4: e.target.value });
                    }}
                    isInvalid={!modData?.L4 && modData?.I4}
                  />
                  <Form.Text id="link4HelpBloc" muted>
                    Format : https://www.site.fr
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="image4">
                  <Form.Label>Icone 4</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    size="sm"
                    placeholder="icon.png"
                    value={modData?.I4 || ""}
                    onChange={(e) => {
                      setModData({ ...modData, I4: e.target.value });
                    }}
                    isInvalid={!modData?.I4 && modData?.L4}
                  />
                  <Form.Text id="icon4HelpBloc" muted>
                    Format : icon4.png
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="link5">
                  <Form.Label>URL lien 5</Form.Label>
                  <Form.Control
                    type="text"
                    as="textarea"
                    rows={1}
                    size="sm"
                    placeholder="URL lien 5"
                    value={modData?.L5 || ""}
                    onChange={(e) => {
                      setModData({ ...modData, L5: e.target.value });
                    }}
                    isInvalid={!modData?.L5 && modData?.I5}
                  />
                  <Form.Text id="link5HelpBloc" muted>
                    Format : https://www.site.fr
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="image5">
                  <Form.Label>Icone 5</Form.Label>
                  <Form.Control
                    type="text"
                    size="sm"
                    placeholder="icon.png"
                    value={modData?.I5 || ""}
                    onChange={(e) => {
                      setModData({ ...modData, I5: e.target.value });
                    }}
                    isInvalid={!modData?.I5 && modData?.L5}
                  />
                  <Form.Text id="icon5HelpBloc" muted>
                    Format : icon5.png
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="link6">
                  <Form.Label>URL lien 6</Form.Label>
                  <Form.Control
                    type="text"
                    as="textarea"
                    rows={1}
                    size="sm"
                    placeholder="URL lien 6"
                    value={modData.L6 || ""}
                    onChange={(e) => {
                      setModData({ ...modData, L6: e.target.value });
                    }}
                    isInvalid={!modData?.L6 && modData?.I6}
                  />
                  <Form.Text id="link6HelpBloc" muted>
                    Format : https://www.site.fr
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="image6" className="dual-row">
                  <Form.Label>Icone 6</Form.Label>
                  <Form.Control
                    type="text"
                    size="sm"
                    placeholder="icon.png"
                    value={modData.I6 || ""}
                    onChange={(e) => {
                      setModData({ ...modData, I6: e.target.value });
                    }}
                    isInvalid={!modData?.I6 && modData?.L6}
                  />
                  <Form.Text id="icon1HelpBloc" muted>
                    Format : icon6.png
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>

            <Button variant="success" type="submit">
              Enregistrer
            </Button>
          </Form>
        )}
      </Container>
      <Container>
        {modData2 ? <ViewInterf_2 dataProps={modData2} /> : <></>}
      </Container>
      <Container id="alerts-forms">
        <Alert
          show={showAlertInterfSuccess}
          key={"interfaceSuccess"}
          variant="success"
          className="alertsForms"
          id="AlertSuccess"
        >
          <Alert.Heading>Interface successfully modified</Alert.Heading>
          <hr />
          <div className="d-flex justify-content-end">
            <Button
              onClick={() => setShowAlertInterfSuccess(false)}
              variant="outline-success"
            >
              OK
            </Button>
          </div>
        </Alert>
        <Alert
          show={showAlertInterfFailed}
          key={"interfaceFail"}
          variant={"danger"}
          className="alertsForms"
        >
          <Alert.Heading>Interface not created</Alert.Heading>
          <p>
            Something went wrong during creation. <br />
            Try to : <br />
            - Check the kiosk number <br />
            - Check if this interface already exist <br />
            <br /> <br />
            If the problem persists, contact the{" "}
            <b>
              <a
                href="mailto: richard@lisaconnect.fr"
                target="_blank"
                rel="noreferrer"
              >
                administrator
              </a>
              .
            </b>
            <div className="d-flex justify-content-end">
              <Button
                onClick={() => setShowAlertInterfFailed(false)}
                variant="outline-danger"
              >
                OK
              </Button>
            </div>
          </p>
        </Alert>
      </Container>
    </Container>
  );
}

import React, { useState } from "react";
import { Alert, Button, Col, Form } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { GestionURL, Xpress } from "../../APIs/api-xibo";
import { UploadLinkImg } from "../../services/InterfaceServices";

export default function UploadFile(props) {
  const [validatedFile, setValidatedFile] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileInput, setFileInput] = useState();
  const [filePath, setFilePath] = useState("");
  const [showAlertSuccess, setShowAlertSuccess] = useState(false);
  const [showAlertFailed, setShowAlertFailed] = useState(false);

  const fileNameHandler = (event) => {
    setFileName(event.target.value);
  };
  const fileDataHandler = (event) => {
    setFileInput(event.target.files[0]);
  };

  const handleSubmitFile = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidatedFile(true);
    } else {
      event.preventDefault();
      SendFileForm();
    }
  };

  async function SendFileForm() {
    const upload = await UploadLinkImg(fileInput, fileName);
    if (upload.error) {
      setShowAlertFailed(true);
    } else {
      setShowAlertSuccess(true);
      setFilePath(upload.data);
    }
  }

  return (
    <div>
      <h4>Upload une Icone</h4>
      <Form
        noValidate
        validated={validatedFile}
        onSubmit={handleSubmitFile}
        id="UploadForm"
      >
        <Form.Row>
          {/* <Form.Group as={Col} sm="6" controlId="IconeName">
          <Form.Label>Nom Icone (.png)</Form.Label>
          <Form.Control
            required
            type="text"
            as="textarea"
            rows={1}
            size="sm"
            placeholder="nom_icone.png"
            onChange={fileNameHandler}
          />
          <Form.Text id="borneIdHelpBloc" muted>
            Format : ffdiabete_lr.png
          </Form.Text>
        </Form.Group> */}
          {/* <Form.Group as={Col} sm="6" controlId="IconFile"> */}
          <Form.Group as={Col} controlId="IconFile">
            <Form.Label>
              Fichier <b>.png</b> obligatoire
            </Form.Label>
            <Form.Control
              required
              type="file"
              size="sm"
              placeholder="ff-diabete.png"
              onChange={fileDataHandler}
            />
            <Form.Text id="borneIdHelpBloc" muted>
              Chaque <b>nom de fichier</b> doit être <b>unique</b>
            </Form.Text>
            <Form.Control.Feedback type="invalid">
              Upload un fichier
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>

        <Button type="submit" variant="success">
          Upload Icone
        </Button>
      </Form>
      <section id="alerts-forms">
        {/* <Alert show={showAlertSuccess} key={"fileSuccess"} variant={"success"}>
          Image uploaded
        </Alert> */}
        <Alert
          show={showAlertFailed}
          key={"fileFail"}
          variant={"danger"}
          className="alertsForms"
        >
          <Alert.Heading>Image not uploaded</Alert.Heading>
          <p>
            <br />
            <i> Something went wrong during upload... :(</i> <br />
            <br />
            Try to : <br />
            - Check the file format (png). <br />
            - Refresh the page and try again. <br />
            - Log out from this dashboard and login another time
            <br /> <br />
            If the problem persists, contact the{" "}
            <b>
              <a href="mailto: richard@lisaconnect.fr" target="_blank">
                administrator
              </a>
              .
            </b>
            <div className="d-flex justify-content-end">
              <Button
                onClick={() => setShowAlertFailed(false)}
                variant="outline-danger"
              >
                OK
              </Button>
            </div>
          </p>
        </Alert>
        <Alert
          show={showAlertSuccess}
          variant="success"
          className="alertsForms"
          id="AlertSuccess"
        >
          <Alert.Heading>Image successfully uploaded</Alert.Heading>
          <p>
            The icon is stored at url : <br />
            <a href={filePath} target="_blank">
              {filePath}
            </a>
          </p>
          <hr />
          <div className="d-flex justify-content-end">
            <Button
              onClick={() => {
                setShowAlertSuccess(false);
                window.location.href = `${GestionURL}/admin/interfaces`;
              }}
              variant="outline-success"
            >
              OK
            </Button>
          </div>
        </Alert>
      </section>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import Button from "react-bootstrap/Button";
import BootstrapTable from "react-bootstrap-table-next";
import "../assets/css/Docboard.css";
import Xibo, { UpdateAccessState } from "../APIs/api-xibo";
import ConfirmationModal from "./ConfirmationModal";

function EndPaper({ isAdmin }) {
  const [dataSet, setDataSet] = useState([]);
  const [newDataToDelete, setNewDataToDelete] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  useEffect(() => {
    getDataSet();
  }, []);

  
  const getDataSet = () => {
    Xibo.get("/dataset/data/3?length=1000").then(
      (res) => {
        console.log(res);
        let newData = [];
        let count = 0;

        for (let i = 0; i < res.data.length; i++) {
          let tempData = [];
          tempData.push(res.data[i]);
          if (
            tempData[0].Statut !== " NORMAL " &&
            tempData[0].Statut !== "NORMAL"
          ) {
            newData.push(tempData[0]);
            let color = "primary";
            if (
              newData[count].Statut === " NORMAL " ||
              newData[count].Statut === "NORMAL"
            ) {
              color = "success";
            } else if (newData[count].Statut === "PAPIER PRESQUE VIDE") {
              color = "warning";
            } else if (newData[count].Statut === "PAPIER VIDE") {
              color = "danger";
            }

            newData[count].validate = (
              <div>
                {isAdmin ? (
                  <Button
                    variant={color}
                    id={tempData[0].id + "delete"}
                    onClick={() => {
                      setNewDataToDelete(tempData[0]);
                      setShowConfirm(true);
                    }}
                  >
                    Retirer
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            );
            count++;
          }
        }
        setDataSet(newData);
      },
      (err) => console.log(err)
    );
  };

  const columns = [
    { dataField: "id", text: "ID" },
    { dataField: "Borne", text: "Borne" },
    { dataField: "Statut", text: "Statut" },
    { dataField: "Date", text: "Date" },
    { dataField: "Time", text: "Heure" },
    { dataField: "validate", text: "#" },
  ];

  const closeConfirm = () => {
    setShowConfirm(false);
  };

  const handleClick = (e, data = null) => {
    if (data === null) data = newDataToDelete;
    setNewDataToDelete(null);

    Xibo.delete("/dataset/data/3/" + data.id).then(
      (res) => {
        getDataSet();
      },
      (err) => {
        alert(err);
      }
    );

    setShowConfirm(false);
  };

  const hideData = () => {};

  return (
    <div>
      <BootstrapTable keyField="id" data={dataSet} columns={columns} striped />
      <ConfirmationModal
        show={showConfirm}
        closeModal={closeConfirm}
        message="Supprimer la ligne ? Elle réapparaîtra plus tard si le papier manque."
        confirm={handleClick}
      />
    </div>
  );
}

export default EndPaper;

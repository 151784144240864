import React from "react";
import {Nav} from "react-bootstrap";
import '../assets/css/Sidebar.css'

const Sidebar = (props) => {
    let items = [];

    props.items.map((prop, key) => {
        let isActive = false;

        if(key === props.keyActive) isActive = true;
        items.push(
            <Nav.Item key={key}>
                <Nav.Link active={isActive} eventKey={key}>{prop}</Nav.Link>
            </Nav.Item>
        );
        return;
    });

    return (
        <>
            <Nav variant="pills" className="bg-light sidebar"
           onSelect={(eventKey, e) => props.onSelect(eventKey, e)}
            >
                <h5 style={{padding: "10px"}}>{props.title}</h5>
                {items}
            </Nav>
        </>
        );
  };
  //const Sidebar = withRouter(Side);
  export default Sidebar;
import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { BsTools } from "react-icons/bs";
import { BsX } from "react-icons/bs";
import { BsCheck } from "react-icons/bs";

export default function AnnonceTools({
  videoOrder,
  changeOrderMod,
  setChangeOrderMod,
  isOrderChanged,
  orderHasChanged,
  saveOrderAPI,
  cancelOrder,
  changeView,
}) {
  const [showTools, setShowTools] = useState(false);
  const [toolView, setToolView] = useState("main");

  //   useEffect(() => {
  //     console.log("changeView :", changeView);
  //     setToolView(changeView);
  //   }, [changeView]);
  //   useEffect(() => {
  //     console.log("AnnonceTools useEffect videoOrder :", videoOrder);
  //   }, [videoOrder]);

  //   useEffect(() => {
  //     console.log("toolView :", toolView);
  //   }, [toolView]);

  //   useEffect(() => {
  //     console.log("showTools :", showTools);
  //     console.log("toolView :", toolView);
  //   }, [showTools]);

  //   useEffect(() => {
  //     console.log("changeOrderMod :", changeOrderMod);
  //   }, [changeOrderMod]);

  return (
    <Container
      style={{
        marginBottom: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <Button
          variant={showTools ? "warning" : "info"}
          onClick={() => {
            setShowTools(!showTools);
          }}
        >
          <BsTools />
        </Button>
      </div>

      {showTools && (
        <section
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
          {!orderHasChanged && !isOrderChanged && (
            <Button
              onClick={() => {
                setChangeOrderMod(!changeOrderMod);
                setToolView("order");
              }}
              //   style={{color}}
              variant="info"
            >
              Changer l'ordre des vidéos
            </Button>
          )}

          {/* ---------------- TOOLS ---------------- */}
          {isOrderChanged && (
            <span>
              <Button onClick={cancelOrder} variant="danger">
                {/* Annuler modifications */}
                <BsX size={25} />
              </Button>
              {orderHasChanged && (
                <Button
                  onClick={saveOrderAPI}
                  variant="success"
                  style={{ marginLeft: "25px" }}
                >
                  {/* Sauvegarder l'ordre des vidéos */}
                  <BsCheck size={25} />
                </Button>
              )}
            </span>
          )}
        </section>
      )}
    </Container>
  );
}

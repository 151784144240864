import React, { useEffect, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Col,
  Container,
  Fade,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import {
  BsArrowCounterclockwise,
  BsFillCircleFill,
  BsFillXCircleFill,
  BsPlus,
  BsSearch,
} from "react-icons/bs";
import { Xpress } from "../../APIs/api-xibo";
import BootstrapTable from "react-bootstrap-table-next";

export default function PrinterNotifs() {
  const [originalNotifs, setOriginalNotifs] = useState([]);
  const [allNotifs, setAllNotifs] = useState([]);

  const [showForm, setShowForm] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const [typeValue, setTypeValue] = useState("");

  const [selectedContactId, setSelectedContactId] = useState("");

  const [showAlertSuccess, setShowAlertSuccess] = useState(false);
  const [showAlertFailed, setShowAlertFailed] = useState(false);
  const [lastError, setLastError] = useState(null);

  const [filterArchived, setFilterArchived] = useState("");

  function typeFormatter(cell, row) {
    if (row.type === "printer") {
      return (
        <span>
          {/* <strong style={{ color: "blue" }}>Printer</strong> */}
          <Badge variant="warning">Printer</Badge>
        </span>
      );
    }

    return (
      <span>
        {/* <strong style={{ color: "red" }}>Paper</strong> */}
        <Badge variant="info">Paper</Badge>
      </span>
    );
  }

  function statusFormatter(cell, row) {
    console.log("row", row);
    return (
      <div>
        <span>
          {/* {row.resolved == "true" ? (
            <BsFillCircleFill color="green" />
          ) : (
            <BsFillCircleFill color="orange" />
          )} */}

          {row.resolved == true ? (
            <>
              <BsFillCircleFill color="green" />
              <span className="ml-2"> Terminé</span>
            </>
          ) : (
            <></>
          )}
          {row.resolved == false && row.notifs.length <= 2 ? (
            <>
              <BsFillCircleFill color="orange" />{" "}
              <span className="ml-2"> En cours</span>
            </>
          ) : (
            <></>
          )}
          {row.resolved == false && row.notifs.length > 2 ? (
            <>
              {" "}
              <BsFillCircleFill color="red" />{" "}
              <span className="ml-2"> En cours</span>
            </>
          ) : (
            <></>
          )}
        </span>
        <div className="mt-2" style={{ fontSize: "smaller" }}>
          {" "}
          {row.resolveDate ? row.resolveDate : <></>}
        </div>
      </div>
    );
  }

  function detailsFormatter(cell, row) {
    return (
      <div>
        <div>{row.borne}</div>
        <div>
          <span>
            {row.dataRef.dataStatus == "OFF" ? (
              <BsFillCircleFill color="red" />
            ) : (
              <BsFillCircleFill color="orange" />
            )}
          </span>
          <span> {row.dataRef.dataStatus}</span>
        </div>
      </div>
    );
  }

  function dateFormatter(cell, row) {
    return (
      <span style={{ fontSize: "smaller" }}>
        <div>{row.dataRef.dataDate}</div>
        <div>{row.dataRef.dataTime}</div>
      </span>
    );
  }

  function relanceFormatter(cell, row) {
    // chercher la row.notifs[].delay avec le délais le plus grand et stocker la notif entière dans une variable
    const lastNotif = row.notifs.reduce((max, notif) => {
      return notif.delay > max.delay ? notif : max;
    }, row.notifs[0] || {});

    return (
      <div>
        <div>
          <b> {row.notifs.length} relances </b> -{" "}
          <i> {lastNotif.delay} heures</i>
        </div>
        <div>
          {lastNotif.date} {lastNotif.time}
        </div>
      </div>
    );
  }

  const columns = [
    {
      dataField: "_id",
      text: "ID",
      style: { cursor: "pointer" },
      hidden: true,
    },

    {
      dataField: "type",
      text: "Type",
      formatter: typeFormatter,
      style: { cursor: "pointer" },
      headerStyle: { width: "80px" }, // Fixe la largeur de l'en-tête de la colonne
      style: { width: "80px" }, // Fixe la largeur de la colonne pour les cellules
    },
    {
      dataField: "date",
      text: "Date",
      formatter: dateFormatter,
      style: { cursor: "pointer" },
    },
    {
      dataField: "details",
      text: "Détail",
      formatter: detailsFormatter,
      style: { cursor: "pointer" },
      formatExtraData: {
        up: "glyphicon glyphicon-chevron-up",
        down: "glyphicon glyphicon-chevron-down",
      },
    },

    {
      dataField: "relanceMax",
      text: "Dernière relance",
      formatter: relanceFormatter,
      style: { cursor: "pointer" },
    },
    {
      dataField: "resolved",
      text: "Status",
      formatter: statusFormatter,
      style: { cursor: "pointer" },
    },
  ];

  const expandRow = {
    renderer: (row) => (
      <Row key={"section-row" + row._id} style={{ fontSize: "smaller" }}>
        <Col xs="12" key={"section-row-col" + row._id}>
          {row.notifs.map((borne, key) => {
            // console.log("row" + key + row._id);
            // console.log("col1" + key + row._id);
            // console.log("row" + key + row._id);
            return (
              <>
                <Row
                  key={"section-row-col-row-" + key + row._id}
                  className="mt-3"
                >
                  <Col key={"section-row-col-row-col1" + key + row._id} xs="2">
                    <div
                      className="notifs-title"
                      key={"section-row-col-row-col1-div1-" + key + row._id}
                    >
                      <b
                        key={"section-row-col-row-col1-div1-b" + key + row._id}
                      >
                        Date{" "}
                      </b>
                    </div>
                    <div
                      className="notifs-content"
                      key={"section-row-col-row-col1-div2-" + key + row._id}
                    >
                      {borne.date} {borne.time}
                    </div>
                  </Col>
                  <Col key={"section-row-col-row-col2-" + key + row._id} xs="1">
                    <div
                      className="notifs-title"
                      key={"section-row-col-row-col2-div1-" + key + row._id}
                    >
                      <b
                        key={"section-row-col-row-col2-div1-b" + key + row._id}
                      >
                        Delay{" "}
                      </b>
                    </div>
                    <div
                      className="notifs-content"
                      key={"section-row-col-row-col2-div2-" + key + row._id}
                    >
                      {borne.delay}
                    </div>
                  </Col>
                  <Col key={"section-row-col-row-col3-" + key + row._id} xs="5">
                    <div
                      className="notifs-title"
                      key={"section-row-col-row-col3-div1" + key + row._id}
                    >
                      <b
                        key={"section-row-col-row-col3-div1-b" + key + row._id}
                      >
                        Message{" "}
                      </b>
                    </div>
                    <div
                      className="notifs-content"
                      key={"section-row-col-row-col3-div2-" + key + row._id}
                    >
                      {borne.message}
                    </div>
                  </Col>

                  <Col key={"section-row-col-row-col4-" + key + row._id} xs="4">
                    {" "}
                    <div
                      className="notifs-title"
                      key={"section-row-col-row-col4-div1" + key + row._id}
                    >
                      <b
                        key={"section-row-col-row-col4-div1-b" + key + row._id}
                      >
                        Targets{" "}
                      </b>{" "}
                    </div>
                    {borne.targets.map((target, key2) => {
                      //   console.log("col4-target" + key + "-" + key2 + row._id);
                      return (
                        <div
                          key={
                            "section-row-col-row-col4-div2-" +
                            key +
                            "-" +
                            key2 +
                            row._id +
                            target
                          }
                          style={{ fontSize: "smaller" }}
                        >
                          {target}
                        </div>
                      );
                    })}
                  </Col>
                </Row>
                <hr key={"hr" + key + row._id} />
              </>
            );
          })}
        </Col>
      </Row>
    ),
    showExpandColumn: true,
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <i
            className="bi bi-caret-up-fill rotate h5"
            id={"carret-notifsList"}
          ></i>
        );
      }

      return (
        <i
          className="bi bi-caret-down-fill rotate h5"
          id={"carret-notifsList"}
        ></i>
      );
    },
  };

  const handleSelect = (event) => {
    const selectedId = event.target.value;
    if (selectedId) {
      const selectedContact = allNotifs.find(
        (contact) => contact._id === selectedId
      );
      //   setFormData(selectedContact);
      //   setIsEditMode(true);
      //   setShowForm(true);
      setSelectedContactId(selectedId);
    }
  };

  const handleChangeSearch = (event) => {
    const search = event.target.value;
    if (search) {
      const filteredNotifs = originalNotifs.filter((notif) => {
        return (
          notif.borne.toLowerCase().includes(search.toLowerCase()) ||
          notif.type.toLowerCase().includes(search.toLowerCase()) ||
          notif.dataRef.dataStatus
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          notif.dataRef.dataDate.toLowerCase().includes(search.toLowerCase()) ||
          notif.dataRef.dataTime.toLowerCase().includes(search.toLowerCase()) ||
          notif.notifs.some((notif) => {
            return (
              notif.date.toLowerCase().includes(search.toLowerCase()) ||
              notif.time.toLowerCase().includes(search.toLowerCase()) ||
              notif.delay
                .toString()
                .toLowerCase()
                .includes(search.toLowerCase()) ||
              notif.message.toLowerCase().includes(search.toLowerCase()) ||
              notif.targets.some((target) => {
                return target.toLowerCase().includes(search.toLowerCase());
              })
            );
          })
        );
      });
      setAllNotifs(filteredNotifs);
    } else {
      setAllNotifs(originalNotifs);
      // setSearchValue("");
    }
    setSearchValue(search);
    setTypeValue("");
    setFilterArchived("");
  };
  const handleChangeType = (event) => {
    const search = event.target.value;
    if (search && search != "") {
      const filteredNotifs = originalNotifs.filter((notif) => {
        return notif.type.toLowerCase().includes(search.toLowerCase());
      });
      setAllNotifs(filteredNotifs);
      // setSearchValue(search);
    } else {
      setAllNotifs(originalNotifs);
      // setSearchValue("");
    }
    setTypeValue(search);
    setSearchValue("");
    setFilterArchived("");
  };

  const handleChangePrinter = (event) => {
    const search = event.target.value;
    if (search && search != "") {
      const filteredNotifs = originalNotifs.filter((notif) => {
        return notif.dataRef.dataStatus
          .toLowerCase()
          .includes(search.toLowerCase());
      });
      setAllNotifs(filteredNotifs);
      // setSearchValue(search);
    } else {
      setAllNotifs(originalNotifs);
      // setSearchValue("");
      handleChangeType({
        target: { value: "printer" },
      });
    }
    setSearchValue("");
    setFilterArchived("");
  };

  const handleChangePaper = (event) => {
    const search = event.target.value;
    if (search && search != "") {
      const filteredNotifs = originalNotifs.filter((notif) => {
        return notif.dataRef.dataStatus

          .toLowerCase()
          .includes(search.toLowerCase());
      });
      setAllNotifs(filteredNotifs);
      // setSearchValue(search);
    } else {
      setAllNotifs(originalNotifs);
      // setSearchValue("");
      handleChangeType({
        target: { value: "paper" },
      });
    }
    setSearchValue("");
    setFilterArchived("");
  };

  const handleChangeArchived = (event) => {
    const search = event.target.value;
    console.log("search", search);
    if (search == "true") {
      const filteredNotifs = originalNotifs.filter((notif) => {
        console.log("notif.resolved", notif.resolved);
        return notif.resolved == true;
      });
      console.log("filteredNotifs", filteredNotifs);
      setAllNotifs(filteredNotifs);
      // setSearchValue(search);
    } else if (search == "false") {
      const filteredNotifs = originalNotifs.filter((notif) => {
        console.log("notif.resolved", notif.resolved);
        return notif.resolved == false;
      });
      console.log("filteredNotifs", filteredNotifs);
      setAllNotifs(filteredNotifs);
      // setSearchValue(search);
    } else {
      setAllNotifs(originalNotifs);
      // setSearchValue("");
    }
    setFilterArchived(search);
    setSearchValue("");
    setTypeValue("");
  };

  const resetForm = () => {
    // setSelectedContactId("");
    setSearchValue("");
    setTypeValue("");
    setFilterArchived("");
    setAllNotifs(originalNotifs);
  };

  const alterModal = () => {
    <section
      className="alerts-forms"
      onClick={() => {
        setShowAlertFailed(false);
        setLastError(null);
      }}
    >
      <Alert
        show={showAlertFailed}
        key={"fileFail"}
        variant={"danger"}
        className="alertsForms"
      >
        <Alert.Heading>Erreur lors de l'enregistrement</Alert.Heading>
        <p>
          <br />
          <div>
            Error details : <b>{lastError}</b>
          </div>
          <br />
        </p>
        <hr />
        <p>
          If the problem persists, contact the{" "}
          <b>
            <a href="mailto: richard@lisaconnect.fr" target="_blank">
              administrator
            </a>
            .
          </b>
          <div className="d-flex justify-content-end">
            <Button
              onClick={() => {
                setShowAlertFailed(false);
                setLastError(null);
              }}
              variant="outline-danger"
            >
              OK
            </Button>
          </div>
        </p>
      </Alert>
    </section>;
  };

  useEffect(() => {
    const callAsynch = async () => {
      try {
        const resp = await Xpress.get("/printer/notifs/all");
        console.log("resp.data", resp.data);
        setOriginalNotifs(resp.data.data);
        setAllNotifs(resp.data.data);
      } catch (error) {
        console.error(error);
        setLastError(error.message || error);
        setShowAlertFailed(true);
      }
    };
    callAsynch();
  }, []);

  return (
    <Container>
      <section className="contact-actions-tools">
        <Row
          style={{ margin: "0", width: "80vw", maxWidth: "1000px" }}
          className="contact-form-actions"
        >
          <Col xs="4">
            <InputGroup className="">
              {searchValue != "" ? (
                <InputGroup.Prepend
                  onClick={() => {
                    setAllNotifs(originalNotifs);
                    setSearchValue("");
                  }}
                >
                  <InputGroup.Text
                    as={Button}
                    size="sm"
                    style={searchValue != "" ? { borderColor: "black" } : {}}
                  >
                    <BsFillXCircleFill />
                  </InputGroup.Text>
                </InputGroup.Prepend>
              ) : (
                <> </>
              )}
              <Form.Control
                type="text"
                size="sm"
                placeholder="Rechercher"
                onChange={handleChangeSearch}
                value={searchValue}
                style={searchValue != "" ? { borderColor: "black" } : {}}
              />
            </InputGroup>
          </Col>
          <Col xs="8" style={{ alignSelf: "center" }}>
            <Row>
              <Col xs="4">
                <InputGroup className="" size="sm">
                  <InputGroup.Prepend column="sm" sm={2}>
                    <InputGroup.Text
                      style={typeValue != "" ? { borderColor: "black" } : {}}
                    >
                      Type
                    </InputGroup.Text>
                  </InputGroup.Prepend>

                  <Form.Control
                    as="select"
                    id="type-filter"
                    size="sm"
                    onChange={handleChangeType}
                    value={typeValue}
                    style={typeValue != "" ? { borderColor: "black" } : {}}
                  >
                    <option
                      value={""}
                      style={{ fontWeight: "bolder", textAlign: "center" }}
                    >
                      Tous
                    </option>
                    <option value={"printer"}>Printer</option>
                    <option value={"paper"}>Paper</option>
                  </Form.Control>
                </InputGroup>
              </Col>
              <Col>
                {typeValue == "printer" ? (
                  <InputGroup className="" size="sm">
                    <InputGroup.Prepend column="sm" sm={2}>
                      <InputGroup.Text
                        style={
                          document.getElementById("printerStatus-filter") &&
                          document.getElementById("printerStatus-filter")
                            .value != ""
                            ? { borderColor: "black" }
                            : {}
                        }
                      >
                        Status
                      </InputGroup.Text>
                    </InputGroup.Prepend>

                    <Form.Control
                      as="select"
                      id="printerStatus-filter"
                      onChange={handleChangePrinter}
                      style={
                        document.getElementById("printerStatus-filter") &&
                        document.getElementById("printerStatus-filter").value !=
                          ""
                          ? { borderColor: "black" }
                          : {}
                      }
                    >
                      <option
                        value={""}
                        style={{ fontWeight: "bolder", textAlign: "center" }}
                      >
                        Tous
                      </option>
                      <option value={"Error"}>Error</option>
                      <option value={"OFF"}>OFF</option>
                    </Form.Control>
                  </InputGroup>
                ) : (
                  <></>
                )}
                {typeValue == "paper" ? (
                  <InputGroup className="" size="sm">
                    <InputGroup.Prepend column="sm" sm={2}>
                      <InputGroup.Text
                        style={
                          document.getElementById("printerStatus-filter") &&
                          document.getElementById("printerStatus-filter")
                            .value != ""
                            ? { borderColor: "black" }
                            : {}
                        }
                      >
                        Statut
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      as="select"
                      id="paperStatus-filter"
                      onChange={handleChangePaper}
                      style={
                        document.getElementById("paperStatus-filter") &&
                        document.getElementById("paperStatus-filter").value !=
                          ""
                          ? { borderColor: "black" }
                          : {}
                      }
                    >
                      <option
                        value={""}
                        style={{ fontWeight: "bolder", textAlign: "center" }}
                      >
                        Tous
                      </option>
                      <option value={"PAPIER PRESQUE VIDE"}>
                        Presque vide
                      </option>
                      <option value={"PAPIER VIDE"}>Vide</option>
                    </Form.Control>
                  </InputGroup>
                ) : (
                  <></>
                )}
              </Col>
              <Col xs="4" style={{ textAlign: "center", alignSelf: "center" }}>
                <InputGroup className="" size="sm">
                  <InputGroup.Prepend column="sm" sm={2}>
                    <InputGroup.Text
                      style={
                        document.getElementById("archived-filter") &&
                        document.getElementById("archived-filter").value != ""
                          ? { borderColor: "black" }
                          : {}
                      }
                    >
                      Statut
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    as="select"
                    id="archived-filter"
                    value={filterArchived}
                    onChange={handleChangeArchived}
                    style={
                      document.getElementById("archived-filter") &&
                      document.getElementById("archived-filter").value != ""
                        ? { borderColor: "black" }
                        : {}
                    }
                  >
                    <option
                      value={""}
                      style={{ fontWeight: "bolder", textAlign: "center" }}
                    >
                      Tous
                    </option>
                    <option value={false}>En cours</option>
                    <option value={true}>Terminés</option>
                  </Form.Control>
                </InputGroup>
              </Col>
              <Col xs="1">
                <span style={{ alignSelf: "center" }}>
                  {(searchValue || typeValue || filterArchived) != "" ? (
                    <Button size="sm" onClick={resetForm} variant="warning">
                      <BsArrowCounterclockwise />
                    </Button>
                  ) : (
                    <></>
                  )}
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>
      {/* <Row style={{ margin: "0" }} className="contact-form-actions"></Row> */}
      <Fade>
        <BootstrapTable
          id="table-notifs"
          bootstrap4
          keyField="_id"
          data={allNotifs}
          columns={columns}
          expandRow={expandRow}
          hover
        />
      </Fade>
      {showAlertFailed ? alterModal : <></>}
      {showAlertSuccess ? (
        <section
          className="alerts-forms"
          onClick={() => setShowAlertSuccess(false)}
        >
          <Alert
            show={showAlertSuccess}
            variant="success"
            className="alertsForms"
            id="AlertSuccessFile"
          >
            <Alert.Heading>Contact Sucessfully created</Alert.Heading>
            <hr />
            <div className="d-flex justify-content-center">
              <Button
                onClick={() => setShowAlertSuccess(false)}
                variant="outline-success"
                size="sm"
                className="btn-icon-list"
              >
                OK
              </Button>
            </div>
          </Alert>
        </section>
      ) : (
        <></>
      )}
    </Container>
  );
}

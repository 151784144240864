import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import routes from '../routes';

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if(prop.layout === "/public")
      {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          /> 
        );
      }
      return null;
    })}
    <Redirect from="/public" to="/public/origami" />
  </Switch>
);
function Public(props) {
  return (
        <div>{switchRoutes}</div>
  );
}

export default Public;

import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { XapiURL } from "../../APIs/api-xibo";

function ViewInterf_2({ dataProps, prints }) {
  // const [data, setData] = useState([]);
  // const [links, setLinks] = useState([]);
  // const [printData, setPrintData] = useState([]);
  const [linksNb, setLinksNb] = useState(0);

  useEffect(() => {
    setLinksNb(
      dataProps.filter((link) => {
        if (
          link &&
          link.img != null &&
          link.img != "" &&
          link.img != undefined &&
          link.url &&
          link.url != "" &&
          link.url != null &&
          link.url != undefined
        ) {
          return link;
        }
      }).length
    );
    // setData(dataProps);
  }, [dataProps]);

  return (
    // <section id="viewIntSec">
    <section className="viewIntSec2">
      {dataProps && dataProps.length > 0 ? (
        <>
          <Col xs={8} className="IntleftCol">
            <Row className="viewIntRows">
              <Col xs={6} className="viewIntCols">
                {dataProps[0] ? (
                  <a
                    href={dataProps[0].url || "#"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {dataProps[0].img ? (
                      <img
                        src={XapiURL + "/interfaces/image/" + dataProps[0].img}
                        className="viewIntImg"
                      />
                    ) : (
                      <></>
                    )}
                  </a>
                ) : (
                  <></>
                )}
              </Col>
              <Col xs={6} className="viewIntCols">
                {dataProps[1] ? (
                  <a
                    href={dataProps[1].url || "#"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {dataProps[1].img ? (
                      <img
                        src={XapiURL + "/interfaces/image/" + dataProps[1].img}
                        className="viewIntImg"
                      />
                    ) : (
                      <></>
                    )}
                  </a>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
            <Row className="viewIntRows">
              <Col xs={6} className="viewIntCols">
                {dataProps[2] ? (
                  <a
                    href={dataProps[2].url || "#"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {dataProps[2].img ? (
                      <img
                        src={XapiURL + "/interfaces/image/" + dataProps[2].img}
                        className="viewIntImg"
                      />
                    ) : (
                      <></>
                    )}
                  </a>
                ) : (
                  <></>
                )}
              </Col>
              <Col xs={6} className="viewIntCols">
                {dataProps[3] ? (
                  <a
                    href={dataProps[3].url || "#"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {dataProps[3].img ? (
                      <img
                        src={XapiURL + "/interfaces/image/" + dataProps[3].img}
                        className="viewIntImg"
                      />
                    ) : (
                      <></>
                    )}
                  </a>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
            {linksNb > 4 && (
              <Row className="viewIntRows">
                <Col xs={6} className="viewIntCols">
                  {dataProps[4] ? (
                    <a
                      href={dataProps[4].url || "#"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {dataProps[4].img ? (
                        <img
                          src={
                            XapiURL + "/interfaces/image/" + dataProps[4].img
                          }
                          className="viewIntImg"
                        />
                      ) : (
                        <></>
                      )}
                    </a>
                  ) : (
                    <></>
                  )}
                </Col>
                {linksNb > 5 && (
                  <Col xs={6} className="viewIntCols">
                    {dataProps[5] ? (
                      <a
                        href={dataProps[5].url || "#"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {dataProps[5].img ? (
                          <img
                            src={
                              XapiURL + "/interfaces/image/" + dataProps[5].img
                            }
                            className="viewIntImg"
                          />
                        ) : (
                          <></>
                        )}
                      </a>
                    ) : (
                      <></>
                    )}
                  </Col>
                )}
              </Row>
            )}
          </Col>
          <Col xs={4} className="IntRightCol"></Col>
        </>
      ) : (
        <></>
      )}
    </section>
  );
}

export default ViewInterf_2;

import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { GamesURL } from "../../APIs/api-xibo";
import ViewInterf2 from "../Interfaces/ViewInterf_2";
import { checkMatchingLength, getValue } from "../../services/Utils";
import {
  PostInterfaceLinks,
  PostNewUrlsToKio,
} from "../../services/InterfaceServices";

export default function LinksConfigNew() {
  const [validated, setValidated] = useState(false);
  const formRef2 = useRef(null);
  const [givenData, setGivenData] = useState();

  const [showAlertInterfFailed, setShowAlertInterfFailed] = useState(false);
  const [showAlertInterfSuccess, setShowAlertInterfSuccess] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [dataUrl, setDataUrl] = useState();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (
      form.checkValidity() === false
      // || !checkMatchingLength(givenData, givenData.NbLiens, false)
    ) {
      event.preventDefault();
      // bloque refresh de la page
      event.stopPropagation();
      setValidated(false);
    } else {
      const saveNewUrls = await PostNewUrlsToKio(event.target);
      if (!saveNewUrls.error) {
        const resp = await PostInterfaceLinks(event.target);
        if (resp.error) {
          setShowAlertInterfFailed(true);
        } else {
          setShowAlertInterfSuccess(true);
          setDataUrl(
            `${GamesURL}/interfaces/${formRef2.current.elements.borneId.value}`
          );
        }
      } else {
        setAlertMsg(saveNewUrls.data);
        setShowAlertInterfFailed(true);
      }
    }
  };

  const handleFormChange = (event) => {
    const templating = setTemplate(formRef2.current.elements);
    setGivenData(templating);
  };

  function setTemplate(dataTarget) {
    let formatedData = [];
    if (dataTarget) {
      if (dataTarget instanceof HTMLFormControlsCollection) {
        for (let i = 1; i <= 6; i++) {
          formatedData.push({
            img: getValue(`image${i}`, dataTarget),
            url: getValue(`link${i}`, dataTarget),
          });
        }
      } else {
        for (let i = 1; i <= 6; i++) {
          formatedData.push({
            img: getValue(`I${i}`, dataTarget),
            url: getValue(`L${i}`, dataTarget),
          });
        }
      }
      return formatedData;
    }
  }

  const checkValidity = (targetNb, type) => {
    if (givenData && givenData[targetNb]) {
      if (givenData[targetNb].img && givenData[targetNb].url) {
        return true;
      } else if (
        givenData[targetNb].img === "" &&
        givenData[targetNb].url === ""
      ) {
        return true;
      } else {
        if (type === "url" && givenData[targetNb].url === "") {
          return false;
        } else if (type === "img" && givenData[targetNb].img === "") {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return true;
    }
  };

  useEffect(() => {
    const templating = setTemplate(formRef2.current.elements);
    setGivenData(templating);
  }, [formRef2]);

  return (
    <Container>
      <Container>
        <Form
          className="G-form interf-forms forms-bg"
          validated={validated}
          onSubmit={handleSubmit}
          ref={formRef2}
          onChange={handleFormChange}
        >
          <Row>
            <Col xs={6}>
              <Form.Group controlId="borneId">
                <Form.Label size="sm">Numéro de la borne </Form.Label>
                <Form.Control
                  required
                  type="text"
                  size="sm"
                  placeholder="B-2v1-220010"
                />
                <Form.Text id="borneIdHelpBloc" muted>
                  Format obligatoire : B-2v1-220010
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  Renseigner le Numéro de la borne
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="refClient">
                <Form.Label size="sm">Ref Client</Form.Label>
                <Form.Control type="text" size="sm" placeholder="" />
              </Form.Group>
            </Col>
            <Col xs={3}>
              <Form.Group controlId="nbLinks">
                <Form.Label size="sm">Nombre d'icones</Form.Label>
                <Form.Control
                  required
                  as="select"
                  defaultValue=""
                  size="sm"
                  isInvalid={
                    givenData
                      ? !checkMatchingLength(
                          givenData,
                          formRef2.current.elements.nbLinks.value || 0,
                          false
                        )
                      : false
                  }
                >
                  <option></option>
                  <option value={"1"} key={1}>
                    1
                  </option>
                  <option value={"2"} key={2}>
                    2
                  </option>
                  <option value={"3"} key={3}>
                    3
                  </option>
                  <option value={"4"} key={4}>
                    4
                  </option>
                  <option value={"5"} key={5}>
                    5
                  </option>
                  <option value={"6"} key={6}>
                    6
                  </option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="link1">
                <Form.Label size="sm">URL lien 1</Form.Label>
                <Form.Control
                  required
                  type="text"
                  as="textarea"
                  rows={1}
                  size="sm"
                  placeholder="URL lien 1"
                  // isInvalid={givenData[0] ? givenData[0].url === "" : false}
                  isInvalid={!checkValidity(0, "url")}
                />
                <Form.Text id="link1HelpBloc" muted>
                  Format : https://www.site.fr
                </Form.Text>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="image1">
                <Form.Label size="sm">Icone 1</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="icon.png"
                  isInvalid={!checkValidity(0, "img")}
                  size="sm"
                />
                <Form.Text id="icon1newHelpBloc" muted>
                  Format : icon1.png
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group controlId="link2">
                <Form.Label size="sm">URL lien 2</Form.Label>
                <Form.Control
                  required
                  type="text"
                  as="textarea"
                  rows={1}
                  size="sm"
                  placeholder="URL lien 2"
                  isInvalid={!checkValidity(1, "url")}
                />
                <Form.Text id="link2HelpBloc" muted>
                  Format : https://www.site.fr
                </Form.Text>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="image2">
                <Form.Label size="sm">Icone 2</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="icon.png"
                  isInvalid={!checkValidity(1, "img")}
                  size="sm"
                />
                <Form.Text id="icon2newHelpBloc" muted>
                  Format : icon2.png
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="link3">
                <Form.Label size="sm">URL lien 3</Form.Label>
                <Form.Control
                  required
                  type="text"
                  as="textarea"
                  rows={1}
                  size="sm"
                  placeholder="URL lien 3"
                  isInvalid={!checkValidity(2, "url")}
                />
                <Form.Text id="link3HelpBloc" muted>
                  Format : https://www.site.fr
                </Form.Text>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="image3">
                <Form.Label size="sm">Icone 3</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="icon.png"
                  isInvalid={!checkValidity(2, "img")}
                  size="sm"
                />
                <Form.Text id="icon3newHelpBloc" muted>
                  Format : icon3.png
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="link4">
                <Form.Label size="sm">URL lien 4</Form.Label>
                <Form.Control
                  required
                  type="text"
                  as="textarea"
                  rows={1}
                  size="sm"
                  placeholder="URL lien 4"
                  isInvalid={!checkValidity(3, "url")}
                />
                <Form.Text id="link4HelpBloc" muted>
                  Format : https://www.site.fr
                </Form.Text>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="image4">
                <Form.Label size="sm">Icone 4</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="icon.png"
                  isInvalid={!checkValidity(3, "img")}
                  size="sm"
                />
                <Form.Text id="icon4newHelpBloc" muted>
                  Format : icon4.png
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="link5">
                <Form.Label size="sm">URL lien 5</Form.Label>
                <Form.Control
                  type="text"
                  as="textarea"
                  rows={1}
                  size="sm"
                  placeholder="URL lien 5"
                  isInvalid={!checkValidity(4, "url")}
                />
                <Form.Text id="link5HelpBloc" muted>
                  Format : https://www.site.fr
                </Form.Text>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="image5">
                <Form.Label size="sm">Icone 5</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="icon.png"
                  isInvalid={!checkValidity(4, "img")}
                  size="sm"
                />
                <Form.Text id="icon5newHelpBloc" muted>
                  Format : icon5.png
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="link6">
                <Form.Label size="sm">URL lien 6</Form.Label>
                <Form.Control
                  type="text"
                  as="textarea"
                  rows={1}
                  size="sm"
                  placeholder="URL lien 6"
                  isInvalid={!checkValidity(5, "url")}
                />
                <Form.Text id="link6HelpBloc" muted>
                  Format : https://www.site.fr
                </Form.Text>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="image6" className="dual-row">
                <Form.Label size="sm">Icone 6</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="icon.png"
                  isInvalid={!checkValidity(5, "img")}
                  size="sm"
                />
                <Form.Text id="icon6newHelpBloc" muted>
                  Format : icon6.png
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>

          <Button variant="success" type="submit">
            Enregistrer
          </Button>
        </Form>
      </Container>
      <Container>
        {givenData ? <ViewInterf2 dataProps={givenData} /> : <></>}
      </Container>
      <Container id="alerts-forms">
        <Alert
          show={showAlertInterfSuccess}
          key={"interfaceSuccess"}
          variant="success"
          className="alertsForms"
          id="AlertSuccess"
        >
          <Alert.Heading>Interface successfully created</Alert.Heading>
          <div>
            <div>
              Voici l'addresse de l'interface :{" "}
              <a href={dataUrl} target="_blank" rel="noreferrer">
                {dataUrl}
              </a>
            </div>
            <hr />
            <div className="d-flex justify-content-end">
              <Button
                onClick={() => setShowAlertInterfSuccess(false)}
                variant="outline-success"
              >
                OK
              </Button>
            </div>
          </div>
        </Alert>
        <Alert
          show={showAlertInterfFailed}
          key={"interfaceFail"}
          variant={"danger"}
          className="alertsForms"
        >
          <Alert.Heading>Interface not created</Alert.Heading>
          <div>
            <p>
              Something went wrong during creation. <br />
              Try to : <br />
              - Check the kiosk number <br />
              - Check if this interface already exist <br />
              <br /> <br />
              If the problem persists, contact the{" "}
              <b>
                <a
                  href="mailto: richard@lisaconnect.fr"
                  target="_blank"
                  rel="noreferrer"
                >
                  administrator
                </a>
                .
              </b>
            </p>
            <p>{alertMsg}</p>
            <div className="d-flex justify-content-end">
              <Button
                onClick={() => setShowAlertInterfFailed(false)}
                variant="outline-danger"
              >
                OK
              </Button>
            </div>
          </div>
        </Alert>
      </Container>
    </Container>
  );
}

import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import Container from "react-bootstrap/Container";

import "../../assets/css/Docboard.css";
import TableCabinets from "../../components/TableCabinets";
// import Xibo from "../../APIs/api-xibo";

function AdBornes(props) {
  const [cookies] = useCookies(["refresh_token"]);
  const [isOk, setOk] = useState(false);
  useEffect(() => {
    if (cookies.user === undefined) return;
    else if (cookies.user.userName === "xibo_admin") {
      setOk(true);
    } else if (cookies.user.userName === "quentin_lisa") {
      setOk(true);
    } else if (cookies.user.userName === "media_lisa") {
      setOk(true);
    } else if (cookies.user.userName === "anthony_lisa") {
      setOk(true);
    } else if (cookies.user.groups[0].group === "Annonceurs") {
      if (cookies.user.ref1 !== null && cookies.user.ref2 !== null) {
        let splitDateDebut = cookies.user.ref1.split("/");
        let strDateDebut =
          splitDateDebut[2] + "-" + splitDateDebut[1] + "-" + splitDateDebut[0];
        let dateDebut = new Date(strDateDebut);

        let splitDateFin = cookies.user.ref2.split("/");
        let strDateFin =
          splitDateFin[2] + "-" + splitDateFin[1] + "-" + splitDateFin[0];
        let dateFin = new Date(strDateFin);

        if (dateDebut <= new Date() && dateFin > new Date()) {
          setOk(true);
        } else {
          props.history.push("/");
        }
      } else {
        props.history.push("/");
      }
    }
  }, []);

  return (
    <Container>
      {isOk && (
        <div>
          <h1>Mes lieux de diffusions</h1>
          <TableCabinets />
        </div>
      )}
    </Container>
  );
}

export default AdBornes;

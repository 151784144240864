import { Xpress, XpressNoApi } from "../APIs/api-xibo";

export async function GetAllPrintFiles() {
  try {
    const response = await XpressNoApi.get("/printsFiles");
    if (response.status === 200) {
      return { data: response.data, error: false };
    } else {
      return { data: "Prints not found", error: true };
    }
  } catch (error) {
    console.error(error);
    return { data: error, error: true };
  }
}

export async function GetAllTemplates() {
  try {
    const response = await Xpress.get("prints/template/all");
    if (response.status === 200) {
      return { data: response.data, error: false };
    } else {
      return { data: "Templates not found", error: true };
    }
  } catch (error) {
    console.error(error);
    return { data: error, error: true };
  }
}

export async function CreatePrintTemplate(data) {
  try {
    const response = await Xpress.post("prints/template", data);
    if (response.status === 201) {
      return { data: response.data, error: false };
    } else {
      return { data: "Template not created", error: true };
    }
  } catch (error) {
    console.error(error);
    return { data: error, error: true };
  }
}

export async function ModifyPrintTemplate(id, data) {
  try {
    const response = await Xpress.put(`prints/template/${id}`, data);
    if (response.status === 201) {
      return { data: response.data, error: false };
    } else {
      return { data: "Template not found", error: true };
    }
  } catch (error) {
    console.error(error);
    return { data: error, error: true };
  }
}

export async function DeletePrintTemplate(id) {
  try {
    const response = await Xpress.delete(`prints/template/${id}`);
    if (response.status === 200) {
      return { data: response.data, error: false };
    } else {
      return { data: "Template not found", error: true };
    }
  } catch (error) {
    console.error(error);
    return { data: error, error: true };
  }
}

export async function GetAllConfigs() {
  try {
    const response = await Xpress.get("prints/config/all");
    if (response.status === 200) {
      return { data: response.data, error: false };
    } else {
      return { data: "Configs not found", error: true };
    }
  } catch (error) {
    console.error(error);
    return { data: error, error: true };
  }
}

export async function CreatePrinterConfig(data) {
  try {
    const response = await Xpress.post("prints/config", data);
    if (response.status === 201) {
      return { data: response.data, error: false };
    } else {
      return { data: "Config not created", error: true };
    }
  } catch (error) {
    console.error(error);
    return { data: error, error: true };
  }
}

export async function ModifyPrinterConfig(id, data) {
  try {
    const response = await Xpress.put(`prints/config/${id}`, data);
    if (response.status === 201) {
      return { data: response.data, error: false };
    } else {
      return { data: "Config not found", error: true };
    }
  } catch (error) {
    console.error(error);
    return { data: error, error: true };
  }
}

export async function DeletePrinterConfig(id) {
  try {
    const response = await Xpress.delete(`prints/config/${id}`);
    if (response.status === 200) {
      return { data: response.data, error: false };
    } else {
      return { data: "Config not found", error: true };
    }
  } catch (error) {
    console.error(error);
    return { data: error, error: true };
  }
}

import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  OverlayTrigger,
  Tooltip,
  Container,
  Col,
  Alert,
  InputGroup,
  Row,
  Modal,
} from "react-bootstrap";
import { Xpress } from "../../APIs/api-xibo";
import {
  BsPhone,
  BsGeo,
  BsFillTrashFill,
  BsArrowCounterclockwise,
  BsPlus,
  BsTrashFill,
  BsCheck,
} from "react-icons/bs";
import { rotateImg } from "../../services/Utils";

function ContactFormSimply() {
  const [allContacts, setAllContact] = useState([]);
  const [showAlertSuccess, setShowAlertSuccess] = useState(false);
  const [showAlertFailed, setShowAlertFailed] = useState(false);
  const [lastError, setLastError] = useState(null);
  const [invalidFields, setInvalidFields] = useState({});

  const [showForm, setShowForm] = useState(false); // To control the display of FormContact
  const [isEditMode, setIsEditMode] = useState(false); // To determine if the form is in edit mode or create mode
  const [selectedContactId, setSelectedContactId] = useState("empty"); // To store the selected contact's ID

  const [showContactPrincipal, setShowContactPrincipal] = useState(false);
  const [showContact2, setShowContact2] = useState(false);
  const [showContact3, setShowContact3] = useState(false);

  const [modalShow, setModalShow] = useState(false);

  const emptyFormData = {
    borne: "",
    etablissement: "",
    refclient: "",
    contact_principal: {
      nom: "",
      prenom: "",
      email: "",
      telephone: "",
      commentaire: "",
    },
    contact_2: {
      nom: "",
      prenom: "",
      email: "",
      telephone: "",
      commentaire: "",
    },
    contact_3: {
      nom: "",
      prenom: "",
      email: "",
      telephone: "",
      commentaire: "",
    },
  };

  const [formData, setFormData] = useState({
    borne: "",
    etablissement: "",
    refclient: "",
    contact_principal: {
      nom: "",
      prenom: "",
      email: "",
      telephone: "",
      commentaire: "",
    },
    contact_2: {
      nom: "",
      prenom: "",
      email: "",
      telephone: "",
      commentaire: "",
    },
    contact_3: {
      nom: "",
      prenom: "",
      email: "",
      telephone: "",
      commentaire: "",
    },
  });

  const regexPatterns = {
    borne: /^[a-zA-Z]-[a-zA-Z0-9]{3}-[0-9]{6}$/,
    refclient: /^.{4,}$/,
    email: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/,
    telephone: /^(?:\d{10}|\d{2}[-.\s]\d{2}[-.\s]\d{2}[-.\s]\d{2}[-.\s]\d{2})$/,
  };

  function checkDataInputs() {
    let isValid = true;
    let newInvalidFields = {};

    // Check borne
    if (!regexPatterns.borne.test(formData.borne)) {
      console.log("borne", formData.borne);
      isValid = false;
      newInvalidFields.borne = true;
    }

    // Check refclient
    if (!regexPatterns.refclient.test(formData.refclient)) {
      console.log("refclient", formData.refclient);
      isValid = false;
      newInvalidFields.refclient = true;
    }

    // Check emails and phones for all contacts
    ["contact_principal", "contact_2", "contact_3"].forEach((contact) => {
      if (
        formData[contact].email !== "" &&
        formData[contact].email !== null &&
        formData[contact].email !== undefined
      ) {
        if (!regexPatterns.email.test(formData[contact].email)) {
          console.log("email", formData[contact].email);
          isValid = false;
          newInvalidFields[`${contact}.email`] = true;
        }
      }
      if (
        formData[contact].telephone !== "" &&
        formData[contact].telephone !== null &&
        formData[contact].telephone !== undefined
      ) {
        if (!regexPatterns.telephone.test(formData[contact].telephone)) {
          isValid = false;
          newInvalidFields[`${contact}.telephone`] = true;
        }
      }
    });

    setInvalidFields(newInvalidFields);
    console.log("isValid", isValid);
    return isValid;
  }

  const resetForm = () => {
    setShowForm(false);
    setIsEditMode(false);
    setSelectedContactId("");
    setInvalidFields({});
    setFormData(emptyFormData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const keys = name.split(".");

    if (keys.length === 1) {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else if (keys.length === 2) {
      const [parentKey, childKey] = keys;
      setFormData((prevState) => ({
        ...prevState,
        [parentKey]: {
          ...prevState[parentKey],
          [childKey]: value,
        },
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // TODO: Make an API call to your backend to save the data
    if (checkDataInputs() == true) {
      console.log("isEditMode", isEditMode);
      if (!isEditMode) {
        try {
          const resp = await Xpress.post(
            `/printer/${formData.borne}`,
            formData
          );
          console.log("resp.data POST", resp.data);
          if (resp.status == 299) {
            console.log("299", resp.data);
            setLastError(resp.data.error);
            setShowAlertFailed(true);
          } else {
            // update the list of contacts
            setAllContact((prevState) => [...prevState, resp.data.data]);
            setShowAlertSuccess(true);
            resetForm();
          }
        } catch (error) {
          console.error(error);
          setLastError(error.message || error);
          setShowAlertFailed(true);
        }
      } else {
        try {
          const resp = await Xpress.patch(
            `/printer/${formData.borne}`,
            formData
          );
          console.log("resp.data", resp.data);
          if (resp.status == 299) {
            console.log("299", resp.data);
            setLastError(resp.data.error);
            setShowAlertFailed(true);
          } else {
            // update the value of the contact in the list
            const newAllContacts = allContacts.map((contact) => {
              if (contact._id === resp.data.data._id) {
                return resp.data.data;
              }
              return contact;
            });
            setAllContact(newAllContacts);
            setShowAlertSuccess(true);
            resetForm();
          }
        } catch (error) {
          console.error(error);
          setLastError(error.message || error);
          setShowAlertFailed(true);
        }
      }
    }
  };

  const handleSelect = (event) => {
    const selectedId = event.target.value;
    if (selectedId) {
      const selectedContact = allContacts.find(
        (contact) => contact._id === selectedId
      );
      setFormData(selectedContact);
      setIsEditMode(true);
      setShowForm(true);
      setSelectedContactId(selectedId);
    }
  };

  const handleDelete = async (event) => {
    event.preventDefault();

    // Verifier que la borne n'est pas utilisée dans une notif ouverte

    try {
      const resp = await Xpress.delete(`/printer/${formData.borne}`);
      console.log("resp.data", resp.data);
      if (resp.status == 299) {
        console.log("299", resp.data);
        setModalShow(false);
        setLastError(resp.data.error);
        setShowAlertFailed(true);
      } else {
        // update the list of contacts
        const newAllContacts = allContacts.filter(
          (contact) => contact._id !== formData._id
        );
        setAllContact(newAllContacts);
        setModalShow(false);
        setShowAlertSuccess(true);
        resetForm();
      }
    } catch (error) {
      console.error(error);
      setLastError(error.message || error);
      setShowAlertFailed(true);
    }
  };

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Suppresion du contact - {formData.borne}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Êtes-vous certain de vouloir supprimer ce contact ?</h5>
          <br />
          <p>
            Cette action est <b> irréversible</b>. Vous ne pourrez pas récupérer
            les données.
          </p>
          <p>
            Un <b>contact associé </b>à une <b>borne existante</b> ne peut{" "}
            <b>pas être supprimé</b>.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleDelete} variant="danger">
            Delete
          </Button>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  useEffect(() => {
    const callAsynch = async () => {
      try {
        const resp = await Xpress.get("/printer/all");
        console.log("resp.data", resp.data);
        setAllContact(resp.data.data);
      } catch (error) {
        console.error(error);
        setLastError(error.message || error);
        setShowAlertFailed(true);
      }
    };
    callAsynch();
  }, []);

  return (
    <>
      <section className="contact-actions-tools">
        <Row style={{ margin: "0" }} className="contact-form-actions">
          <Col xs="4">
            <Button
              // variant="outline-info"
              variant={
                showForm && isEditMode === false ? "info" : "outline-info"
              }
              style={{ margin: "auto" }}
              onClick={() => {
                if (showForm) {
                  resetForm();
                } else {
                  setFormData(emptyFormData);
                  setIsEditMode(false);
                  setShowForm(true);
                  setSelectedContactId("");
                  invalidFields != {} && setInvalidFields({});
                }
              }}
            >
              <BsPlus style={{ marginRight: "10px" }} />
              Nouveau Contact
            </Button>
          </Col>

          <Col style={{ alignSelf: "center" }}>
            <Form.Group
              controlId="borneSelect"
              className="interfSectionsH3"
              style={{ margin: 0 }}
            >
              <InputGroup size="sm">
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    {/* <Form.Label size="sm">Numéro de la borne</Form.Label> */}
                    N° borne
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  required
                  as="select"
                  // defaultValue=""
                  size="sm"
                  onChange={handleSelect}
                  value={selectedContactId}
                >
                  <option key={"empty"} value={""}></option>
                  {allContacts.map((borne) => {
                    return (
                      <option key={borne._id} value={borne._id}>
                        {borne.borne}
                      </option>
                    );
                  })}
                </Form.Control>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col xs="1" style={{ alignSelf: "center" }}>
            {showForm ? (
              <Button size="sm" onClick={resetForm} variant="warning">
                <BsArrowCounterclockwise />
              </Button>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </section>
      {showForm && (
        <Form className="contact-form-actions" onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} controlId="borne">
              <Form.Label>Borne *</Form.Label>
              <InputGroup hasValidation>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <BsGeo />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  name="borne"
                  placeholder="ex: B-2v1-220000"
                  value={formData.borne}
                  onChange={handleChange}
                  isInvalid={invalidFields.borne}
                  disabled={isEditMode}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Format Borne incorrecte. Format type: B-2v1-220000
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} controlId="refclient">
              <Form.Label>Ref Client (Axonaut) *</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  name="refclient"
                  placeholder="ex: 13270481 - Visible dans l'url d'Axonaut"
                  value={formData.refclient}
                  onChange={handleChange}
                  isInvalid={invalidFields.refclient}
                  aria-describedby="refClientHelpBlock"
                />
                <Form.Control.Feedback type="invalid">
                  Format RefClient incorrecte. Longeur min 4 caractères.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Form.Row>
          <Form.Group controlId="etablissement">
            <Form.Label>Etablissement *</Form.Label>
            <Form.Control
              type="text"
              name="etablissement"
              placeholder="ex: Clinique Tzanck"
              value={formData.etablissement}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Container>
            <Container
              className="btn G-title interfSectionsH3"
              // type="button"
              onClick={() => {
                setShowContactPrincipal(!showContactPrincipal);
                rotateImg("carret-principalList");
              }}
              id="contact-principal-btn"
            >
              <h3
                className="data-titles-tab"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <span id="btn-title-span">
                  <h5>Contact Principal</h5>
                </span>
                <i
                  className="bi bi-caret-down-fill rotate h4"
                  id={"carret-principalList"}
                ></i>
              </h3>
            </Container>
            {showContactPrincipal ? (
              <>
                <Form.Row>
                  <Form.Group as={Col} controlId="contact_principal.nom">
                    <Form.Label>Nom *</Form.Label>
                    <Form.Control
                      type="text"
                      name="contact_principal.nom"
                      placeholder="ex: Dupont"
                      value={formData.contact_principal.nom}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="contact_principal.prenom">
                    <Form.Label>Prénom *</Form.Label>
                    <Form.Control
                      type="text"
                      name="contact_principal.prenom"
                      placeholder="ex: Jean"
                      value={formData.contact_principal.prenom}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} controlId="contact_principal.email">
                    <Form.Label>Email *</Form.Label>
                    <InputGroup hasValidation>
                      <InputGroup.Prepend>
                        <InputGroup.Text>@</InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="email"
                        name="contact_principal.email"
                        placeholder="ex: name@example.com"
                        value={formData.contact_principal.email}
                        onChange={handleChange}
                        isInvalid={invalidFields["contact_principal.email"]}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Format d'email invalide
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group as={Col} controlId="contact_principal.telephone">
                    <Form.Label>Téléphone *</Form.Label>
                    <InputGroup hasValidation>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <BsPhone />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="tel"
                        name="contact_principal.telephone"
                        placeholder="ex: 0601020304"
                        value={formData.contact_principal.telephone}
                        onChange={handleChange}
                        // isInvalid={invalidFields.telephone}
                        isInvalid={invalidFields["contact_principal.telephone"]}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Téléphone au format classique : 0601020304 ou
                        +33601020304
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Form.Row>
                <Form.Group controlId="contact_principal.commentaire">
                  <Form.Label>Commentaire</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="contact_principal.commentaire"
                    value={formData.contact_principal.commentaire}
                    rows={3}
                    onChange={handleChange}
                  />
                </Form.Group>
              </>
            ) : (
              <></>
            )}
          </Container>
          <Container>
            {/* <h5>Contact 2</h5> */}
            <Container
              className="btn G-title interfSectionsH3"
              // type="button"
              onClick={() => {
                setShowContact2(!showContact2);
                rotateImg("carret-secondList");
              }}
              id="contact-principal-btn"
            >
              <h3
                className="data-titles-tab"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <span id="btn-title-span">
                  <h5>Contact 2</h5>
                </span>
                <i
                  className="bi bi-caret-down-fill rotate h4"
                  id={"carret-secondList"}
                ></i>
              </h3>
            </Container>
            {showContact2 ? (
              <>
                <Form.Row>
                  <Form.Group as={Col} controlId="contact_2.nom">
                    <Form.Label>Nom</Form.Label>
                    <Form.Control
                      type="text"
                      name="contact_2.nom"
                      placeholder="ex: Dupont"
                      value={formData.contact_2.nom}
                      onChange={handleChange}
                      // required
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="contact_2.prenom">
                    <Form.Label>Prénom</Form.Label>
                    <Form.Control
                      type="text"
                      name="contact_2.prenom"
                      placeholder="ex: Jean"
                      value={formData.contact_2.prenom}
                      onChange={handleChange}
                      // required
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="contact_2.email">
                    <Form.Label>Email</Form.Label>
                    <InputGroup hasValidation>
                      <InputGroup.Prepend>
                        <InputGroup.Text>@</InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="email"
                        name="contact_2.email"
                        placeholder="ex: name@example.com"
                        value={formData.contact_2.email}
                        isInvalid={invalidFields["contact_2.email"]}
                        onChange={handleChange}
                        // required
                      />
                      <Form.Control.Feedback type="invalid">
                        Format d'email invalide
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group as={Col} controlId="contact_2.telephone">
                    <Form.Label>Téléphone</Form.Label>
                    <Form.Control
                      type="tel"
                      name="contact_2.telephone"
                      placeholder="ex: 0601020304"
                      value={formData.contact_2.telephone}
                      isInvalid={invalidFields["contact_2.telephone"]}
                      onChange={handleChange}
                      // required
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Group controlId="contact_2.commentaire">
                  <Form.Label>Commentaire</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="contact_2.commentaire"
                    value={formData.contact_2.commentaire}
                    onChange={handleChange}
                    rows={3}
                  />
                </Form.Group>{" "}
              </>
            ) : (
              <></>
            )}
          </Container>
          <Container>
            <Container
              className="btn G-title interfSectionsH3"
              // type="button"
              onClick={() => {
                setShowContact3(!showContact3);
                rotateImg("carret-thirdList");
              }}
              id="contact-principal-btn"
            >
              <h3
                className="data-titles-tab"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <span id="btn-title-span">
                  <h5>Contact 3</h5>
                </span>
                <i
                  className="bi bi-caret-down-fill rotate h4"
                  id={"carret-thirdList"}
                ></i>
              </h3>
            </Container>
            {showContact3 ? (
              <>
                <Form.Row>
                  <Form.Group as={Col} controlId="contact_3.nom">
                    <Form.Label>Nom</Form.Label>
                    <Form.Control
                      type="text"
                      name="contact_3.nom"
                      placeholder="ex: Dupont"
                      value={formData.contact_3.nom}
                      onChange={handleChange}
                      // required
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="contact_3.prenom">
                    <Form.Label>Prénom</Form.Label>
                    <Form.Control
                      type="text"
                      name="contact_3.prenom"
                      placeholder="ex: Jean"
                      value={formData.contact_3.prenom}
                      onChange={handleChange}
                      // required
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="contact_3.email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="contact_3.email"
                      placeholder="ex: name@example.com"
                      value={formData.contact_3.email}
                      isInvalid={invalidFields["contact_3.email"]}
                      onChange={handleChange}
                      // required
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="contact_3.telephone">
                    <Form.Label>Téléphone</Form.Label>
                    <Form.Control
                      type="tel"
                      name="contact_3.telephone"
                      placeholder="ex: 0601020304"
                      value={formData.contact_3.telephone}
                      isInvalid={invalidFields["contact_3.telephone"]}
                      onChange={handleChange}
                      // required
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Group controlId="contact_3.commentaire">
                  <Form.Label>Commentaire</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="contact_3.commentaire"
                    value={formData.contact_3.commentaire}
                    onChange={handleChange}
                    rows={3}
                  />
                </Form.Group>
              </>
            ) : (
              <></>
            )}
          </Container>
          <Container style={{ textAlign: "center" }}>
            <Button
              variant={"outline-danger"}
              className="mr-5"
              onClick={() => setModalShow(true)}
            >
              <BsTrashFill /> Supprimer le contact ?
            </Button>
            <Button variant="success" type="submit">
              <BsCheck /> Submit
            </Button>
          </Container>
        </Form>
      )}
      {showAlertFailed ? (
        <section
          className="alerts-forms"
          onClick={() => {
            setShowAlertFailed(false);
            setLastError(null);
          }}
        >
          <Alert
            show={showAlertFailed}
            key={"fileFail"}
            variant={"danger"}
            className="alertsForms"
          >
            <Alert.Heading>Erreur lors de l'enregistrement</Alert.Heading>
            <p>
              <br />
              <div>
                Error details : <b>{lastError}</b>
              </div>
              <br />
            </p>
            <hr />
            <p>
              If the problem persists, contact the{" "}
              <b>
                <a href="mailto: richard@lisaconnect.fr" target="_blank">
                  administrator
                </a>
                .
              </b>
              <div className="d-flex justify-content-end">
                <Button
                  onClick={() => {
                    setShowAlertFailed(false);
                    setLastError(null);
                  }}
                  variant="outline-danger"
                >
                  OK
                </Button>
              </div>
            </p>
          </Alert>
        </section>
      ) : (
        <></>
      )}
      {showAlertSuccess ? (
        <section
          className="alerts-forms"
          onClick={() => setShowAlertSuccess(false)}
        >
          <Alert
            show={showAlertSuccess}
            variant="success"
            className="alertsForms"
            id="AlertSuccessFile"
          >
            <Alert.Heading>Contact Sucessfully created</Alert.Heading>
            <hr />
            <div className="d-flex justify-content-center">
              <Button
                onClick={() => setShowAlertSuccess(false)}
                variant="outline-success"
                size="sm"
                className="btn-icon-list"
              >
                OK
              </Button>
            </div>
          </Alert>
        </section>
      ) : (
        <></>
      )}
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default ContactFormSimply;

import React, { useState } from "react";
import { Container, Fade } from "react-bootstrap";
import { useCookies } from "react-cookie";
import PrinterNotifs from "../../components/Printers/PrinterNotifs";
import { rotateImg } from "../../services/Utils";
import ContactFormSimply from "../../components/Printers/ContactsForm_simplified";
import BornesFormSimply from "../../components/Bornes/BornesForm_simplified";
import { ModalForms } from "../../components/GoogleForms/GoogleForm";

export default function AdminBornes() {
  const [showContactForm, setShowContactForm] = useState(false);
  const [showNotifsSect, setShowNotifsSect] = useState(false);
  const [showPrintertSect, setShowPrintertSect] = useState(false);

  return (
    <Container>
      <h1 className="mt-1 mb-5 ">Bornes Dashboard</h1>
      <Container className="mt-1">
        <Container
          className="btn G-title interfSectionsH3"
          onClick={() => {
            setShowContactForm(!showContactForm);
            rotateImg("carret-contactsList");
          }}
          id="contacts-title-btn"
        >
          <h2
            className="data-titles-tab"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <span id="btn-title-span">Informations Bornes</span>
            <i
              className="bi bi-caret-down-fill rotate h4"
              id={"carret-contactsList"}
            ></i>
          </h2>
        </Container>
        {showContactForm ? <BornesFormSimply /> : <></>}
      </Container>
      <ModalForms />
    </Container>
  );
}

import React from "react";
import PrintView from "./PrintView";

function PublicOrigami(props) {
  return (
      <PrintView 
        contentType="story"
        title="Touche l'histoire que tu veux imprimer !"
      />
)}
export default PublicOrigami;
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

import Container from "react-bootstrap/Container";
import "../../assets/css/Docboard.css";
import WaitingAds from "../../components/WaitingAds";
import Xibo from "../../APIs/api-xibo";
import ManageAnnonces from "../../components/ManageAnnonces";

import ConfirmationModal from "../../components/ConfirmationModal";
import LoadingModal from "../../components/LoadingModal";
import CheckLibrary from "../../services/CheckLibrary";
import { ModalForms } from "../../components/GoogleForms/GoogleForm";

function AdminHome(props) {
  const [cleanedUsers, setCleanedUsers] = useState([]);
  const [refresher, setRefresher] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [selectValue, setSelectValue] = useState("");

  const [data, setData] = useState([]);

  useEffect(() => {
    const start = async () => {
      let copyClean = [];
      try {
        let users = await Xibo.get("/user");

        let config = {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        };

        for (let i = 0; i < users.data.length; i++) {
          let user = users.data[i];

          if (user.ref2 === null) continue;

          let splitDateFin = user.ref2.split("/");
          let strDateFin =
            splitDateFin[2] + "-" + splitDateFin[1] + "-" + splitDateFin[0];
          let dateFin = new Date(strDateFin);

          if (dateFin > new Date()) continue;

          let displays = await Xibo.get("/display");

          for (let j = 0; j < displays.data.length; j++) {
            let display = displays.data[j];

            let permissions = await Xibo.get(
              "/user/permissions/display/" + display.displayId
            );

            for (let k = 0; k < permissions.data.length; k++) {
              let permission = permissions.data[k];
              if (permission.group === user.group && permission.view === 1) {
                let params = new URLSearchParams();
                params.append(`groupIds[${permission.groupId}]`, 0);
                Xibo.post(
                  "/user/permissions/display/" + display.displayId,
                  params,
                  config
                );
              }
            }
          }

          const dataUser = new URLSearchParams();
          dataUser.append("userName", user.userName);
          dataUser.append("email", user.email);
          dataUser.append("userTypeId", user.userTypeId);
          dataUser.append("homePageId", 4);
          dataUser.append("libraryQuota", user.libraryQuota);
          dataUser.append("libraryQuota", user.libraryQuota);
          dataUser.append("retired", user.retired);
          dataUser.append("firstName", user.firstName);
          dataUser.append("lastName", user.lastName);
          dataUser.append("phone", user.phone);
          dataUser.append("newUserWizard", user.newUserWizard);
          dataUser.append("hideNavigation", 1);
          dataUser.append(
            "isPasswordChangeRequired",
            user.isPasswordChangeRequired
          );

          Xibo.put("/user/" + user.userId, dataUser, config);

          let res = await Xibo.get("/layout");

          for (let j = 0; j < res.data.length; j++) {
            let layout = res.data[j];
            let desc = layout.description;
            if (layout.ownerId === user.userId) {
              await Xibo.delete("/layout/" + layout.layoutId);

              if (desc === "en attente");
              {
                const resDataSet = await Xibo.get(
                  "/dataset/data/2?length=1000"
                );
                for (let k = 0; k < resDataSet.data.length; k++) {
                  let row = resDataSet.data[k];
                  if (row["Layout id"] === layout.layoutId) {
                    await Xibo.delete("/dataset/data/2/" + row.id);
                    break;
                  }
                }
              }
            }
          }

          Xibo.get("/library").then(
            (res) => {
              for (let j = 0; j < res.data.length; j++) {
                let media = res.data[j];
                if (media.ownerId === user.userId) {
                  const formData = new FormData();
                  formData.append("forceDelete", 1);
                  const config2 = {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  };
                  Xibo.delete("/library/" + media.mediaId, formData, config2);
                }
              }
            },
            (error) => console.log(error)
          );

          copyClean
            .push
            // <li key={"li" + user.userId}>
            //   {user.lastName + " " + user.firstName}
            // </li>
            ();
        }
      } catch (error) {
        console.log(error);
      }

      if (copyClean.length === 0) {
        copyClean.push(<li key="liNone">Aucun utilisateur à nettoyer</li>);
      }
      setRefresher(true);
      setCleanedUsers(copyClean);
    };

    start();
    getDG();
  }, []);

  async function getDG() {
    let groups = {};
    Xibo.get("/display?start=0&length=500")
      .catch((err) => {
        console.log(err);
      })
      .then((response) => {
        // console.log(response.data);
        setData(response.data);

        // getSchedule(response.data);
      })
      .finally(() => {
        // getSchedule();
        // getSchedule(groups);
      });
  }

  const handleDiffuseConfirm = () => {};

  const handleCloseConfirm = () => {
    setShowConfirm(false);
  };

  return (
    <Container>
      <ul className="mt-3 mb-5">{cleanedUsers}</ul>
      <h1>Annonces en attente</h1>
      {refresher && <WaitingAds />}
      <h1 style={{ marginTop: "60px", marginBottom: "20px" }}>
        Annonces en diffusion
      </h1>
      {data.map((item) => {
        // console.log(item);
        return <ManageAnnonces key={item.displayId} data={item} />;
      })}
      <ConfirmationModal
        show={showConfirm}
        closeModal={handleCloseConfirm}
        message={
          "Diffuser l'annonce \"" +
          selectValue.split("__")[1] +
          '" sur les bornes choisies ?'
        }
        confirm={handleDiffuseConfirm}
      />
      {/* <LoadingModal show={showLoading} /> */}
      {/* <CheckLibrary /> */}
      <ModalForms />
    </Container>
  );
}

export default AdminHome;

import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Switch, Route, Redirect } from "react-router-dom";

import routes from "../routes";
import NavTop from "../components/NavTop";

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/adboard") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Redirect from="/adboard" to="/adboard/home" />
  </Switch>
);

function Annonceur(props) {
  const [cookies] = useCookies(["refresh_token"]);
  const [isOk, setOk] = useState(false);
  useEffect(() => {
    if (cookies.user === undefined) {
      props.history.push("/xauth");
    } else if (cookies.user.userName === "xibo_admin") {
      setOk(true);
    } else if (cookies.user.userName === "media_lisa") {
      setOk(true);
    } else if (cookies.user.userName === "anthony_lisa") {
      setOk(true);
    } else if (cookies.user.userName === "quentin_lisa") {
      setOk(true);
    } else if (cookies.user.groups[0].group === "Medecins") {
      props.history.push("/docboard/home");
    } else if (cookies.user.groups[0].group !== "Annonceurs") {
      props.history.push("/xauth");
    } else {
      setOk(true);
    }
  }, []);

  const handleDisconnection = (e) => {
    document.cookie =
      "refresh_token=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie =
      "access_token=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "user=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    props.history.push("/logout");
  };

  return (
    <div>
      {isOk && (
        <div>
          <NavTop
            pageName={props.location.pathname}
            layout="/adboard"
            onClick={(e) => handleDisconnection(e)}
          />
          <div>{switchRoutes}</div>
        </div>
      )}
    </div>
  );
}

export default Annonceur;

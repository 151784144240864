export function rotateImg(tg) {
  document.getElementById(tg).classList.toggle("bi-caret-down-fill");
  document.getElementById(tg).classList.toggle("bi-caret-up-fill");
}

export const handleCopy = (text) => {
  navigator.clipboard.writeText(text);
};

export const getValue = (key, data) => {
  if (data instanceof HTMLFormControlsCollection) {
    return data[key]?.value;
  } else {
    return data[key] || undefined;
  }
};

export const checkMatchingLength = (data, length, exact) => {
  let match = 0;
  if (data && data.length > 0) {
    for (let i = 0; i < 6; i++) {
      if (exact) {
        if (data[i].img && data[i].url) {
          match++;
        }
      } else {
        if (data[i].img || data[i].url) {
          match++;
        }
      }
    }
    if (length == `${match}`) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import Button from "react-bootstrap/Button";
import BootstrapTable from "react-bootstrap-table-next";
import "../assets/css/Docboard.css";
import Xibo, { UpdateAccessState } from "../APIs/api-xibo";
import ConfirmationModal from "./ConfirmationModal";

function PrinterStatus({ isAdmin }) {
  const [dataSet, setDataSet] = useState([]);
  const [originalDataSet, setOriginalDataSet] = useState([]);
  const [newDataToDelete, setNewDataToDelete] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [shoE, setShoE] = useState(false);

  useEffect(() => {
    getDataSet();
  }, []);

  const getDataSet = () => {
    Xibo.get("/dataset/data/4?length=1000").then(
      (res) => {
        console.log(res);
        let newData = [];
        let count = 0;
        for (let i = 0; i < res.data.length; i++) {
          let tempData = [];
          tempData.push(res.data[i]);

          if (tempData[0].Statut !== "ON") {
            newData.push(tempData[0]);
            let color = "primary";
            if (newData[count].Statut === "ON") {
              color = "success";
            } else if (newData[count].Statut === "Error") {
              color = "warning";
              // Fonction pour push la div dans la page
            } else if (newData[count].Statut === "OFF") {
              color = "danger";
              // Fonction pour push la div dans la page
            }
            newData[count].validate = (
              <div>
                {isAdmin ? (
                  <Button
                    variant={color}
                    id={newData[count].id + "delete"}
                    onClick={() => {
                      setNewDataToDelete(newData[count]);
                      setShowConfirm(true);
                    }}
                  >
                    Retirer
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            );
            count++;
          }
        }
        setDataSet(newData.filter((data) => data.Statut !== "Error"));
        setOriginalDataSet(newData);
        console.log(newData);
      },
      (err) => console.log(err)
    );
  };

  const columns = [
    { dataField: "id", text: "ID" },
    { dataField: "Borne", text: "Borne" },
    { dataField: "Statut", text: "Statut" },
    { dataField: "Date", text: "Date" },
    { dataField: "Time", text: "Heure" },
    { dataField: "validate", text: "#" },
  ];

  const closeConfirm = () => {
    setShowConfirm(false);
  };

  const handleClick = (e, data = null) => {
    if (data === null) data = newDataToDelete;
    setNewDataToDelete(null);

    Xibo.delete("/dataset/data/4/" + data.id).then(
      (res) => {
        getDataSet();
      },
      (err) => {
        alert(err);
      }
    );

    setShowConfirm(false);
  };

  const filterData = () => {
    // let newData = [];
    // for (let i = 0; i < dataSet.length; i++) {
    //   if (dataSet[i].Statut === "ON") {
    //     newData.push(dataSet[i]);
    //   }
    // }
    // setDataSet(newData);
    console.log("coucou");
    console.log(shoE);
    shoE === true
      ? setDataSet(originalDataSet.filter((data) => data.Statut !== "Error"))
      : setDataSet(originalDataSet);
    // setDataSet(dataSet.filter((data) => data.Statut !== "Error"));
    setShoE(!shoE);
  };

  return (
    <div>
      <div className="form-check-filter-div">
        <div className="form-check-filter">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckDefault"
            onChange={filterData}
          />
          <label className="form-check-label" htmlFor="flexCheckDefault">
            Errors
          </label>
        </div>
      </div>
      <BootstrapTable keyField="id" data={dataSet} columns={columns} striped />
      <ConfirmationModal
        show={showConfirm}
        closeModal={closeConfirm}
        message="Supprimer la ligne ? Elle réapparaîtra plus tard si le papier manque."
        confirm={handleClick}
      />
    </div>
  );
}

export default PrinterStatus;

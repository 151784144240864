import React from "react";
import AnnonceContainer from "../../components/AnnonceContainer";
import AnnonceContainerV5 from "../../components/AnnonceContainer_v5";
import "../../assets/css/Docboard.css";

function DocAnnonces(props) {
  return <AnnonceContainerV5 numberOfLayouts="10" />;
}

export default DocAnnonces;

import React, { useState, useEffect } from "react";
import cx from "classnames";
import SliderContext from "./context";
import Content from "./Content";
import SlideButton from "./SlideButton";
import SliderWrapper from "./SliderWrapper";
import useSliding from "./useSliding";
import useSizeElement from "./useSizeElement";
import "./Slider.scss";
import VideoModal from "./VideoModal";
import LoadingModal from "../../LoadingModal";

const Slider = ({ children, activeSlide }) => {
  const [currentSlide, setCurrentSlide] = useState(activeSlide);
  const [showVideo, setShowVideo] = useState(false);
  const [showLoading, setShowLoading] = useState({ show: false, message: "" });
  const [alertMessage, setAlertMessage] = useState("");
  const { width, elementRef } = useSizeElement();
  const { handlePrev, handleNext, slideProps, containerRef, hasNext, hasPrev } =
    useSliding(width, React.Children.count(children));

  useEffect(() => {
    if (showLoading.message !== "") {
      setAlertMessage(showLoading.message);
      let show = showLoading.show;
      setShowLoading({ show: show, message: "" });
    }
  }, [showLoading]);

  useEffect(() => {
    if (alertMessage !== "") {
      alert(alertMessage);
      setAlertMessage("");
    }
  }, [alertMessage]);

  const openLoad = () => {
    setShowVideo(false);
    setShowLoading({ show: true, message: "" });
  };

  const closeLoad = (msg) => {
    setShowLoading({ show: false, message: msg });
  };

  const handleSelect = (movie) => {
    setCurrentSlide(movie);
    setShowVideo(true);
  };

  const handleClose = () => {
    setShowVideo(false);
    setCurrentSlide(null);
  };

  const contextValue = {
    onSelectSlide: handleSelect,
    onCloseSlide: handleClose,
    elementRef,
    currentSlide,
  };

  return (
    <SliderContext.Provider value={contextValue}>
      <SliderWrapper>
        <div className={cx("slider", { "slider--open": currentSlide != null })}>
          <div ref={containerRef} className="slider__container" {...slideProps}>
            {children}
          </div>
        </div>
        {hasPrev && <SlideButton onClick={handlePrev} type="prev" />}
        {hasNext && <SlideButton onClick={handleNext} type="next" />}
      </SliderWrapper>
      {currentSlide && (
        <VideoModal
          show={showVideo}
          closeModal={handleClose}
          annonce={currentSlide}
          load={openLoad}
          unLoad={closeLoad}
        />
      )}
      <LoadingModal show={showLoading.show} />
    </SliderContext.Provider>
  );
};

export default Slider;

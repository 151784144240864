import axios from "axios";
import { XiboURL, GestionURL, XapiURL } from "./api-xibo";

// const XiboAuto = axios.create({
//   baseURL: "https://connexion.lisaconnect.fr/api",
// });
const XiboAuto = axios.create({
  baseURL: `${XiboURL}/api`,
});

let accessToken = "";

let refreshStateToken = (token) => null;

//Fonction pour refresh l'access token dans les url de video qui n'utilisent pas Axios, appelée dans les components
export let UpdateAccessState = (refreshStateFunction) => {
  refreshStateToken = refreshStateFunction;
};

// export const redirect_uri = "https://gestion.lisaconnect.fr/auth/xlogin";
export const redirect_uri = `${GestionURL}/auth/xlogin`;
// export const xiboLogUri = "https://connexion.lisaconnect.fr";
export const xiboLogUri = XiboURL;
export const clientId = "m4PzqBnqt5aP1Amh3ogprYO2xWFGFGo1ci2d9RQy";
//export const clientId = "GavpuFdZq4Ixzq026OikmVXjASO2Qx7sydadjHta";

export const RefreshAccessToken = async () => {
  console.log("refreshing access token auto");
  let params = new URLSearchParams();
  // params.append("client_id", clientId);
  // params.append("client_secret", clientSecret);
  params.append("grant_type", "client_credentials");

  let config = {
    headers: {
      // "Content-Type": "application/x-www-form-urlencoded",
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  let response = await XiboAuto.post("/authorize/access_token", params, config);
  setCookie("access_token", "Bearer " + response.data.access_token, {
    SameSite: "None",
    Secure: true,
  });
  setCookie("refresh_token", response.data.refresh_token, {
    SameSite: "None",
    Secure: true,
    path: "/",
    maxAge: 172800,
  });
  accessToken = "Bearer " + response.data.access_token;
  refreshStateToken(accessToken);
};

// export const RefreshAccessToken = async () => {
//   let params = new URLSearchParams();
//   // params.append("client_id", clientId);
//   // params.append("client_secret", clientSecret);
//   params.append("grant_type", "client_credentials");

//   let config = {
//     headers: {
//       // "Content-Type": "application/x-www-form-urlencoded",
//       "Content-Type": "application/x-www-form-urlencoded",
//     },
//   };

//   let response = await XiboAuto.post("/authorize/access_token", params, config);
//   setCookie("access_token", "Bearer " + response.data.access_token, {
//     SameSite: "None",
//     Secure: true,
//   });
//   setCookie("refresh_token", response.data.refresh_token, {
//     SameSite: "None",
//     Secure: true,
//     path: "/",
//     maxAge: 172800,
//   });
//   accessToken = "Bearer " + response.data.access_token;
//   refreshStateToken(accessToken);
// };

// export const RefreshAccessToken = async () => {
//   let params = new URLSearchParams();
//   // params.append("client_id", clientId);
//   // params.append("client_secret", clientSecret);
//   params.append("grant_type", "client_credentials");

//   let config = {
//     headers: {
//       "Content-Type": "application/x-www-form-urlencoded",
//     },
//   };

//   let response = await XiboAuto.post("/authorize/access_token", params, config);
//   setCookie("access_token", "Bearer " + response.data.access_token);
//   setCookie("refresh_token", response.data.refresh_token);
//   accessToken = "Bearer " + response.data.access_token;
//   refreshStateToken(accessToken);
// };

XiboAuto.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = getCookie("access_token");
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

XiboAuto.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (error.response.status === 401 || error.response.status === 400) {
      if (
        !originalRequest._retry &&
        originalRequest.url !== "/authorize/access_token"
      ) {
        originalRequest._retry = true;
        await RefreshAccessToken();
        return XiboAuto(originalRequest);
      } else {
        window.location.href = `${GestionURL}/xauth`;
      }
    }

    return Promise.reject(error);
  }
);

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

//for duration : 3600 = one hour
function setCookie(cname, cvalue) {
  // document.cookie = cname + "=" + cvalue + ";" + ";path=/;samesite=strict";
  document.cookie =
    cname + "=" + cvalue + ";" + ";path=/;SameSite=None;Secure=true;";
}

export default XiboAuto;

import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import Badge from "react-bootstrap/Badge";
import BootstrapTable from "react-bootstrap-table-next";

import "../assets/css/Docboard.css";
import Xibo from "../APIs/api-xibo";
import { Button } from "react-bootstrap";
import { useCookies } from "react-cookie";
import ConfirmationModal from "./ConfirmationModal";
import LoadingModal from "./LoadingModal";
import { BsXSquare } from "react-icons/bs";
import emailjs from "emailjs-com";

function TableCabinets(props) {
  const [cookies] = useCookies("user");
  const [cabinets, setCabinets] = useState([]);
  const [displayCabinets, setDisplayCabinets] = useState([]);
  const [columnToFilter, setColumnToFilter] = useState("name");
  const [wordFilter, setWordFilter] = useState("");
  const [dropdownTitle, setDropdownTitle] = useState("Filtres");
  const [bornesToDiffuse, setBornesToDiffuse] = useState([]);
  const [plannedAnnonces, setPlannedAnnonces] = useState([]);
  const [formattedAnnonces, setFormattedAnnonces] = useState([]);
  const [emptyAnnonce, setEmptyAnnonce] = useState(true);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [selectValue, setSelectValue] = useState("");

  const expandRow = {
    renderer: (row) => (
      <div>
        {row.bornes.map((borne, key) => {
          //<Col key={"col1" + key} xs="1"> <Button size="sm" style={{height: "23px", fontSize: 10}}><b>+</b></Button></Col>
          return (
            <Row key={"row" + key} className="mt-3">
              <Col key={"col5" + key} xs="1">
                <Form.Check
                  type="checkbox"
                  onClick={(e) => addBorne(e, borne.displayGroupId)}
                />
              </Col>
              <Col key={"col2" + key} xs="1">
                <b>ID : </b>
                {borne.borneId}
              </Col>
              <Col key={"col3" + key} xs="3">
                {borne.borneName}
              </Col>
              <Col key={"col4" + key} xs="6">
                {formateTags(borne.tags)}
              </Col>
            </Row>
          );
        })}
      </div>
    ),
    showExpandColumn: true,
  };

  const columns = [
    { dataField: "name", text: "Etablissement", style: { cursor: "pointer" } },
    { dataField: "type", text: "Type", style: { cursor: "pointer" } },
    {
      dataField: "nbBornes",
      text: "Bornes disponibles",
      style: { cursor: "pointer" },
    },
    { dataField: "cp", text: "Code postal", style: { cursor: "pointer" } },
    { dataField: "ville", text: "Ville", style: { cursor: "pointer" } },
    { dataField: "addr", text: "Adresse", style: { cursor: "pointer" } },
  ];

  const addDeleteButton = (laytId, eventid, event) => {
    return (
      <BsXSquare
        color={"red"}
        onClick={() => {
          console.log("cliked");
          console.log(laytId);
          // deleteAddDisplay(laytId);
          deleteAddDisplay(laytId, eventid);
        }}
      />
    );
  };

  const deleteAddDisplay = (layoutId, eventId, event) => {
    // Xibo.delete("/layout/" + layoutId).then(
    //   (res) => {console.log(res);},
    //   (err) => console.log(err)
    // );
    // console.log(eventId);
    // Xibo.delete("/schedulerecurrence/" + eventId).then(
    //   (res) => {
    //     console.log(res);
    //   },
    //   (err) => console.log(err)
    // );

    Xibo.delete("/schedule/" + eventId).then(
      (res) =>
        console.log("deleted event " + event.eventId + " successfully !"),
      (err) => console.log(err)
    );

    // Xibo.delete("/layout/" + layoutId).then(
    //   (res) => {
    //     getAnnonces();
    //     console.log("deleted layout " + layoutId + " successfully !");
    //   },
    //   (err) => console.log(err)
    // );

    // if (event.campaign.split("__")[3] == cookies.user.userId) {
    //   console.log("ca commence a delete la !!!!!!!!!!!!!!!!!!!");
    //   Xibo.delete("/schedule/" + eventId).then(
    //     (res) => console.log("deleted event " + event.eventId),
    //     (err) => console.log(err)
    //   );
    // }
  };

  useEffect(() => {
    getCabinets();
    getAnnonces();
  }, []);

  useEffect(() => {
    // setPlannedAnnonces(plannedAnnonces);
    displayPlannedAnnonces(plannedAnnonces);
  }, [plannedAnnonces]);

  const sendMail = (templateId, message, error) => {
    // let formattedName = annonce.split("__")[2];
    let variables = { error_src: error, message: message };
    emailjs
      .send("service_w8rye4j", templateId, variables)
      .then((res) => {})
      // Handle errors here however you like, or use a React error boundary
      .catch((err) => console.error("Error :", err));
  };

  const addBorne = (e, borneId) => {
    let copyArray = bornesToDiffuse.slice();
    if (e.target.checked) {
      copyArray.push(borneId);
    } else {
      copyArray = copyArray.filter((value, index, arr) => {
        return value !== borneId;
      });
    }
    setBornesToDiffuse(copyArray);
    // console.log(copyArray);
  };

  const formateTags = (tags) => {
    let arrTags = tags.split(",");
    return arrTags.map((tag, key) => {
      return (
        <Badge className="mr-1" variant="secondary" key={"tag" + key}>
          {tag}
        </Badge>
      );
    });
  };

  const getCabinets = async () => {
    const resLength = "?start=0&length=500";
    let res = await Xibo.get("/display" + resLength);

    if (res.status == 200) {
      let retCabinets = [];
      for (let i = 0; i < res.data.length; i++) {
        let data = res.data[i];
        document.getElementById("target").innerHTML = "";
        getSchedules(data.displayGroupId, data.deviceName);
        let cabinetData = data.description.split(";");
        let isExists = false;
        let borne = {
          borneId: data.displayId,
          borneName: data.deviceName,
          tags: data.tags,
          tagValues: data.tagValues,
          displayGroupId: data.displayGroupId,
        };

        for (let j = 0; j < retCabinets.length; j++) {
          if (retCabinets[j].name == cabinetData[0]) {
            isExists = true;
            retCabinets[j].bornes.push(borne);
            retCabinets[j].nbBornes++;
            break;
          }
        }

        if (isExists) continue;

        let newCabinet = {
          name: cabinetData[0],
          nbBornes: 1,
          ville: cabinetData[1],
          cp: cabinetData[2],
          addr: cabinetData[3],
          type: cabinetData[4],
          bornes: [borne],
        };

        retCabinets.push(newCabinet);
      }

      setCabinets(retCabinets);
      setDisplayCabinets(retCabinets);
    } else {
      alert("Erreur " + res.status);
    }
  };

  // GET DATA ANNONCES EN COURS DE DIFFUSION
  const getSchedules = (groupId, borneName) => {
    let scheduledAnnonces = [];

    let dateNow = new Date();
    let minutes = ("0" + dateNow.getMinutes()).slice(-2);
    let seconds = ("0" + dateNow.getSeconds()).slice(-2);

    let stringDateNow =
      dateNow.getFullYear() +
      "-" +
      dateNow.getMonth() +
      "-" +
      dateNow.getDate() +
      " " +
      dateNow.getHours() +
      ":" +
      minutes +
      ":" +
      seconds;

    // console.log("Group ID:  " + groupId);

    Xibo.get(
      `/schedule/${groupId}/events?date=${stringDateNow}&start=0&length=500`
    ).then(
      (res) => {
        // console.log("le resultat de GetSchedules: ");
        // console.log(res.data);
        // console.log("user ID:  " + cookies.user.userId);

        for (let i = 0; i < res.data.events.length; i++) {
          let event = res.data.events[i];
          // console.log("eventCampain:   " + event.campaign.split("__")[3]);
          if (event.campaign.split("__")[3] == cookies.user.userId) {
            let bCheck = false;
            for (let j = 0; j < scheduledAnnonces.length; j++) {
              if (
                scheduledAnnonces[j].annonce == event.campaign.split("__")[2]
              ) {
                scheduledAnnonces[j].bornes.push(borneName);
                bCheck = true;
              }
            }

            if (!bCheck) {
              let row = {
                annonce: event.campaign.split("__")[2],
                bornes: [borneName],
                layoutID: event.layoutId,
                eventid: event.eventId,
              };
              scheduledAnnonces.push(row);
            }
          }
        }

        let newT = scheduledAnnonces.map((item) => {
          // console.log(item);
          return {
            annonce: item.annonce,
            bornes: item.bornes,
            layoutID: item.layoutID,
            eventid: item.eventid,
          };
        });

        // console.log(newT);

        for (let ind = 0; ind < newT.length; ind++) {
          let element = "";
          for (let index = 0; index < newT[ind].bornes.length; index++) {
            // console.log(newT[ind].bornes[index]);
            element += newT[ind].bornes[index] + ", ";
          }
          document.getElementById(
            "target"
          ).innerHTML += `<li>${newT[ind].annonce} : ${element} [eventID: ${newT[ind].eventid}] </li>`;
        }

        // setPlannedAnnonces(scheduledAnnonces);
      },
      (err) => alert("Erreur innattendue.")
    );
  };

  const filterCabinets = (valueFilter = null, colFilter = null) => {
    if (valueFilter == null) valueFilter = wordFilter;
    if (colFilter == null) colFilter = columnToFilter;

    for (let i = 0; i < cabinets.length; i++) {
      switch (colFilter) {
        case "Etablissement":
          setDisplayCabinets(
            cabinets.filter((cabinet) =>
              cabinet.name.toLowerCase().includes(valueFilter.toLowerCase())
            )
          );
          break;
        case "Type":
          setDisplayCabinets(
            cabinets.filter((cabinet) =>
              cabinet.type.toLowerCase().includes(valueFilter.toLowerCase())
            )
          );
          break;
        case "Ville":
          setDisplayCabinets(
            cabinets.filter((cabinet) =>
              cabinet.ville.toLowerCase().includes(valueFilter.toLowerCase())
            )
          );
          break;
        case "Code postal":
          setDisplayCabinets(
            cabinets.filter((cabinet) =>
              cabinet.cp.toLowerCase().includes(valueFilter.toLowerCase())
            )
          );
          break;
        case "Adresse":
          setDisplayCabinets(
            cabinets.filter((cabinet) =>
              cabinet.addr.toLowerCase().includes(valueFilter.toLowerCase())
            )
          );
          break;
        case "Catégorie":
          setDisplayCabinets(
            cabinets.filter((cabinet) => {
              let allTags = "";
              for (let i = 0; i < cabinet.bornes.length; i++) {
                allTags += cabinet.bornes[i].borneName + cabinet.bornes[i].tags;
              }
              return allTags.toLowerCase().includes(valueFilter.toLowerCase());
            })
          );
          break;
        case "ID":
          setDisplayCabinets(
            cabinets.filter((cabinet) => {
              let allIds = "";
              for (let i = 0; i < cabinet.bornes.length; i++) {
                allIds += cabinet.bornes[i].borneId;
              }
              return allIds.toLowerCase().includes(valueFilter.toLowerCase());
            })
          );
          break;
        default:
          setDisplayCabinets(
            cabinets.filter((cabinet) => {
              let allValuesBornes = "";
              for (let i = 0; i < cabinet.bornes.length; i++) {
                allValuesBornes +=
                  cabinet.bornes[i].borneId +
                  cabinet.bornes[i].borneName +
                  cabinet.bornes[i].tags;
              }

              let allValues =
                cabinet.name +
                cabinet.type +
                cabinet.ville +
                cabinet.cp +
                cabinet.addr +
                allValuesBornes;
              return allValues
                .toLowerCase()
                .includes(valueFilter.toLowerCase());
            })
          );
          break;
      }
    }
  };

  const getAnnonces = () => {
    Xibo.get("/layout").then(
      (res) => {
        let layouts = [];
        // console.log(res.data);
        for (let i = 0; i < res.data.length; i++) {
          let layout = res.data[i];
          // console.log(layout);
          if (
            layout.ownerId !== cookies.user.userId ||
            layout.description !== "validé"
          )
            continue;
          let layoutName = layout.layout.split("__")[2];
          // console.log(layoutName);
          // console.log(layout.layoutId);
          layouts.push(
            <option
              key={"annonce" + layout.layoutId}
              value={layout.layoutId + "__" + layoutName}
            >
              {layoutName}
            </option>
          );
          if (i == 0) {
            setSelectValue(layout.layoutId + "__" + layoutName);
          }
        }

        if (layouts.length < 1) {
          // console.log(layouts.length);
          layouts.push(
            <option value="none">Aucune annonce validée disponible</option>
          );
          setEmptyAnnonce(true);
        } else setEmptyAnnonce(false);
        setFormattedAnnonces(layouts);
      },
      (err) => console.log(err)
    );
  };

  const handleChangeSearch = (e) => {
    //  console.log(e.target.value);
    setWordFilter(e.target.value);
    filterCabinets(e.target.value);
  };

  const handleChangeFilterColumn = (e) => {
    setColumnToFilter(e);
    setDropdownTitle(e);
    filterCabinets(null, e);
  };

  const handleCloseConfirm = () => {
    setShowConfirm(false);
  };

  const handleDiffuseConfirm = async () => {
    setShowConfirm(false);
    let groupIds = bornesToDiffuse.slice();
    // console.log("Bornes to diffuse -> groupIds: ");
    // console.log(groupIds);
    // console.log("-------------");

    if (groupIds.length == 0) {
      alert(
        "Aucune borne sélectionnée. Cochez la case de la borne correspondante dans la liste pour la sélectionner."
      );
      return;
    }

    setShowLoading(true);
    let dateNow = new Date();
    let minutes = ("0" + dateNow.getMinutes()).slice(-2);
    let seconds = ("0" + dateNow.getSeconds()).slice(-2);
    let stringDateNow =
      dateNow.getFullYear() +
      "-" +
      dateNow.getMonth() +
      "-" +
      dateNow.getDate() +
      " " +
      dateNow.getHours() +
      ":" +
      minutes +
      ":" +
      seconds;

    //    On recupere le layout selectionné par l'USER pour diffusion
    Xibo.get("/layout?layoutId=" + selectValue.split("__")[0]).then(
      (resLayout) => {
        // console.log("Layout selectionné: ");
        // console.log({ resLayout });
        // console.log("Layout selectionné: ");
        // console.log(resLayout);
        // console.log("-------------");
        //  FOR chaque groupe de borne des multiples groupes de bornes selectionnés pour la diffusion du media
        for (let i = 0; i < groupIds.length; i++) {
          let groupId = groupIds[i];
          //  On recupere les events programmés pour le groupe de bornes
          Xibo.get(`/schedule/${groupId}/events?date=${stringDateNow}`).then(
            (res) => {
              // console.log(
              //   "Events programmés pour le groupe de bornes: " + groupId
              // );
              // console.log(res);
              // console.log(res.data.events.length);
              // console.log("-------------");

              let displayOrders = [];
              let displayOrdersEven = [];
              let displayOrder = 2;
              let layoutEntries = Object.entries(res.data.layouts);

              // supprimer les eventuelles annonces déjà plannifiées par l'annonceur
              // for (let j = 0; j < res.data.events.length; j++) {
              //   let event = res.data.events[j];
              //   if (event.campaign.split("__")[3] == cookies.user.userId) {
              //     // Xibo.delete("/schedule/" + event.eventId).then(
              //     //   (res) => console.log("deleted event " + event.eventId),
              //     //   (err) => console.log(err)
              //     // );
              //   }
              // }

              // Pour chaque layout programmé pour le groupe de bornes
              for (let j = 0; j < layoutEntries.length; j++) {
                let layout = layoutEntries[j][1];
                if (layout.description != "validé") continue;
                let event = null;
                let counting = 0;
                for (let k = 0; k < res.data.events.length; k++) {
                  // on check si il y a un doublon
                  if (res.data.events[k].campaignId == layout.campaignId) {
                    // console.log("same event found");
                    counting++;

                    event += res.data.events[k].displayOrder;
                    // break;
                  }
                  // on check si le displayOrder est pair ==> Annonceur
                  if (
                    res.data.events[k].displayOrder != 0 &&
                    res.data.events[k].displayOrder % 2 == 0
                  ) {
                    // console.log("on push le displayOrder d'un annonceur");
                    displayOrdersEven.push(res.data.events[k].displayOrder);
                  }
                }
                if (counting != 0) {
                  // console.log(layout.layout);
                  alert(
                    `Anomalie détectée, veuillez contacter l'administrateur au plus vite ! \n\n Error: doublons de diffusion d'une même annonce \nAnnonce : ${layout.layout}: [${counting}]\n Owner: ${layout.owner}`
                  );
                }
                // displayOrders.push(event.displayOrder);
                displayOrders.push(event);
              }

              if (displayOrdersEven.length == 0) {
                // console.log("continue depassé");
                for (let k = 0; k < res.data.events.length; k++) {
                  // console.log(res.data.events[k].displayOrder);
                  // on check si le displayOrder est pair ==> Annonceur
                  if (
                    res.data.events[k].displayOrder != 0 &&
                    res.data.events[k].displayOrder % 2 == 0
                  ) {
                    // console.log("on push le displayOrder d'un annonceur");
                    displayOrdersEven.push(res.data.events[k].displayOrder);
                  }
                }

                // displayOrders.push(event.displayOrder);
                // displayOrders.push(event);
              }

              displayOrdersEven = displayOrdersEven.sort((a, b) => a - b);
              for (let j = 0; j < displayOrdersEven.length; j++) {
                if (displayOrdersEven[j] === displayOrdersEven[j + 1]) {
                  // anormal !!!!!!!   SEND EMAIL TO ADMIN
                  alert(
                    "Anomalie détectée, veuillez contacter l'administrateur au plus vite ! \n Error: doublons dans les displayOrder"
                  );
                }
                if (displayOrder == displayOrdersEven[j]) {
                  // console.log("displayOrder augmente : " + displayOrder);
                  displayOrder += 2;

                  continue;
                }
                // break;
              }

              if (displayOrder > 20) {
                alert(
                  "Anomalie détectée, veuillez contacter l'administrateur au plus vite ! \n Error: excès d'annonces programmées"
                );
              }

              const formData = new FormData();
              formData.append("eventTypeId", 1);
              formData.append("displayOrder", displayOrder);
              formData.append("isPriority", 0);
              formData.append("campaignId", resLayout.data[0].campaignId);
              formData.append("displayGroupIds[]", [groupId]);
              formData.append("dayPartId", 2);
              // formData.append("userId", 19);

              // -----------------  FROM DATE // TO DATE in a Unix timestamp representing the to date  -----------------
              // let dateNow = new Date();
              // let minutes = ("0" + dateNow.getMinutes()).slice(-2);
              // let seconds = ("0" + dateNow.getSeconds()).slice(-2);

              // let stringDateNow =
              //   dateNow.getFullYear() +
              //   "-" +
              //   dateNow.getMonth() +
              //   "-" +
              //   dateNow.getDate() +
              //   " " +
              //   dateNow.getHours() +
              //   ":" +
              //   minutes +
              //   ":" +
              //   seconds;

              // let dateNowUnix = Math.round(
              //   new Date(stringDateNow).getTime() / 1000
              // );
              // let dateNowPlusOneDayUnix = dateNowUnix + 86400;

              // console.log("dateNowUnix: " + dateNowUnix);
              // console.log("dateNowPlusOneDayUnix: " + dateNowPlusOneDayUnix);

              // formData.append("fromDt", dateNowUnix);
              // formData.append("toDt", dateNowPlusOneDayUnix);

              // var dateNow = Math.floor(Date.now() / 1000);
              // var dateNowPlusOneDay = dateNow + 86400;

              // console.log("dateNow: " + dateNow);
              // console.log("dateNowPlusOneDay: " + dateNowPlusOneDay);

              // formData.append("fromDt", 1673610841);
              // formData.append("toDt", 1673952061);
              // formData.append("recurrenceRange", 1673952061);

              // console.log("final displayOrder : " + displayOrder);
              // console.log("-----------------  FORM DATA  -----------------");
              let config = {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              };
              Xibo.post("/schedule", formData, config).then(
                (res) => {
                  // console.log("CA POST la BOUCLE FINALE");
                  setShowLoading(false);
                  alert("Annonce diffusée avec succès.");
                  // window.location.reload(false);
                  // window.location.reload(false);
                },
                (err) => {
                  setShowLoading(false);
                  console.log(err);
                  alert("Erreur innattendue.");
                }
              );
            },
            (err) => {
              setShowLoading(false);
              console.log(err);
              alert("Erreur innattendue.");
            }
          );
        }
      },
      (err) => {
        setShowLoading(false);
        console.log(err);
        alert("Erreur innattendue.");
      }
    );
  };

  //  BLOC DIFFUSION DES ANNONCES EN COURS
  const displayPlannedAnnonces = () => {
    let retAnnonces = [];
    // console.log("Planned annonces : ");
    // console.log(plannedAnnonces);
    // console.log("Nombre de planned annonces:  " + plannedAnnonces.length);
    if (plannedAnnonces.length == 0) {
      return <p>- Aucune annonce diffusée pour le moment.</p>;
    }
    for (let i = 0; i < plannedAnnonces.length; i++) {
      // console.log("Length:   " + plannedAnnonces.length);
      if (plannedAnnonces[i].bornes.length == 0) continue;
      let annonce = plannedAnnonces[i].annonce + " : ";
      // console.log(plannedAnnonces[i].layoutID);
      // console.log("eventID:   " + plannedAnnonces[i].eventid);
      for (let j = 0; j < plannedAnnonces[i].bornes.length; j++) {
        // console.log(plannedAnnonces[i]);
        // console.log(plannedAnnonces[i].bornes[j]);
        if (j > 0) annonce += ",";
        annonce += " " + plannedAnnonces[i].bornes[j];
      }
      retAnnonces.push(
        <li key={i}>
          {annonce}
          {/* {addDeleteButton(
            plannedAnnonces[i].layoutID,
            plannedAnnonces[i].eventid,
            plannedAnnonces[i]
          )} */}
        </li>
      );
    }
    return <ul>{retAnnonces}</ul>;
  };

  return (
    <div>
      <Row className="mt-3">
        <Col>
          <strong>Diffusion des annonces</strong>
          {/* {displayPlannedAnnonces()} */}
          <div>
            <ul id="target">Aucune annonce diffusée pour le moment.</ul>
          </div>
        </Col>
      </Row>
      <Row className="mt-3 mb-4">
        <Col xs="6" md="3">
          <Form.Control
            type="text"
            placeholder="Rechercher"
            onChange={handleChangeSearch}
          />
        </Col>
        <Col xs="3">
          <Dropdown
            style={{ width: "170px" }}
            onSelect={handleChangeFilterColumn}
          >
            <Dropdown.Toggle variant="primary">{dropdownTitle}</Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item eventKey="Tout rechercher">
                Tout rechercher
              </Dropdown.Item>

              <Dropdown.Header>
                <u>Par cabinet</u>
              </Dropdown.Header>
              <Dropdown.Item eventKey="Etablissement">
                Etablissement
              </Dropdown.Item>
              <Dropdown.Item eventKey="Type">Type</Dropdown.Item>
              <Dropdown.Item eventKey="Ville">Ville</Dropdown.Item>
              <Dropdown.Item eventKey="Code postal">Code postal</Dropdown.Item>
              <Dropdown.Item eventKey="Adresse">Adresse</Dropdown.Item>

              <Dropdown.Header>
                <u>Par borne</u>
              </Dropdown.Header>
              <Dropdown.Item eventKey="ID">ID</Dropdown.Item>
              <Dropdown.Item eventKey="Catégorie">Catégorie</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Row>
        <Col className="mb-1" xs="12">
          <h5>Diffuser sur toutes les bornes cochées :</h5>
        </Col>
      </Row>
      <Row>
        <Col className="mb-1" xs="4">
          <Form.Control
            as="select"
            value={selectValue}
            onChange={(e) => {
              setSelectValue(e.target.value);
            }}
          >
            {formattedAnnonces}
          </Form.Control>
        </Col>
        <Col>
          {!emptyAnnonce && (
            <Button variant="success" onClick={() => setShowConfirm(true)}>
              Diffuser
            </Button>
          )}
        </Col>
      </Row>
      <BootstrapTable
        keyField="name"
        data={displayCabinets}
        columns={columns}
        expandRow={expandRow}
        striped
        hover
      />
      <ConfirmationModal
        show={showConfirm}
        closeModal={handleCloseConfirm}
        message={
          "Diffuser l'annonce \"" +
          selectValue.split("__")[1] +
          '" sur les bornes choisies ?'
        }
        confirm={handleDiffuseConfirm}
      />
      <LoadingModal show={showLoading} />
    </div>
  );
}

export default TableCabinets;

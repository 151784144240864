import axios from "axios";

async function GetIpClient() {
  //   const [myIP, setMyIP] = useState("");
  let myIP2 = "";
  // console.log(
  //   "On commence le GET IP ---------------------------------------------------------"
  // );

  try {
    const res = await axios.get("https://geolocation-db.com/json/");
    //   console.log(res);
    // console.log(res.data.IPv4);
    if (localStorage.getItem("myIP") === res.data.IPv4) {
      // console.log("LocalStorage = ", localStorage.getItem("myIP"));
      // console.log("Mon IP = ", res.data.IPv4);
      // console.log("c'est le meme");
      // compareIp();
      //   setMyIP(res.data.IPv4);
      return res.data.IPv4;
    } else {
      // console.log("LocalStorage = ", localStorage.getItem("myIP"));
      // console.log("Mon IP = ", res.data.IPv4);
      if (localStorage.getItem("myIP") != null) {
        localStorage.removeItem("myIP");
      }
      localStorage.setItem("myIP", res.data.IPv4);
      // console.log("c'était pas le meme enfaite");
      // console.log("Mon new LocalStorage = ", localStorage.getItem("myIP"));
      //   setMyIP(res.data.IPv4);
      myIP2 = res.data.IPv4;

      // compareIp();
    }
  } catch (error) {
    console.error(error);
  }

  //   return myIP;
  return myIP2;
}

export default GetIpClient;

import React from "react";
import { Link } from "react-router-dom";
import { XiboURL } from "../../APIs/api-xibo";
import "../../assets/css/Gestionnaire.css";
import { ModalForms } from "../../components/GoogleForms/GoogleForm";

function Gestionnaire() {
  return (
    <div>
      <h4 className="G-title">Accès à XIBO</h4>
      <div
        className="row"
        style={{
          margin: "0px",
          justifyContent: "space-evenly",
          alignContent: "center",
        }}
      >
        <span className="rd-div">
          <a
            className="rd-links"
            href={XiboURL + "/user/view"}
            target="_blank"
            rel="noreferrer"
          >
            Utilisateurs
          </a>
        </span>
        <span className="rd-div">
          <a
            className="rd-links"
            href={XiboURL + "/display/view"}
            target="_blank"
            rel="noreferrer"
          >
            Bornes
          </a>
        </span>
        <span className="rd-div">
          <a
            className="rd-links"
            href={XiboURL + "/displaygroup/view"}
            target="_blank"
            rel="noreferrer"
          >
            Groupes Bornes
          </a>
        </span>
        <span className="rd-div">
          <a
            className="rd-links"
            href={XiboURL + "/library/view"}
            target="_blank"
            rel="noreferrer"
          >
            Vidéos
          </a>
        </span>
        <span className="rd-div">
          <a
            className="rd-links"
            href={XiboURL + "/layout/view"}
            target="_blank"
            rel="noreferrer"
          >
            Annonces
          </a>
        </span>
      </div>
      <ModalForms />
    </div>
  );
}

export default Gestionnaire;

import React, { useState, useEffect } from "react";

import "../../assets/css/Docboard.css";
import XiboAuto, { UpdateAccessState } from "../../APIs/api-xibo-auto";
import { useCookies } from "react-cookie";
import { Container } from "react-bootstrap";
import ItemPrint from "../../components/ItemPrint";
import ConfirmationModal from "../../components/ConfirmationModal";
import { XiboURL } from "../../APIs/api-xibo";

function PrintView(props) {
  const [accessToken, setAccessToken] = useState("");
  const [pdf, setPdf] = useState("");
  const [pdfToPrint, setPdfToPrint] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [cookies] = useCookies(["access_token"]);

  useEffect(() => {
    UpdateAccessState(setAccessToken);
    setAccessToken(cookies.access_token);
    getPDF();
  }, []);

  const getPDF = () => {
    let arrPdf = [];
    XiboAuto.get("/library?media=" + props.contentType + "__pdf__").then(
      (resMediaPdf) => {
        XiboAuto.get("/library?media=" + props.contentType + "__cover__").then(
          async (resMediaCover) => {
            for (let i = 0; i < resMediaPdf.data.length; i++) {
              let mediaPdf = resMediaPdf.data[i];
              let splitPdf = mediaPdf.name.split("__");
              if (splitPdf[1] !== "pdf") continue;

              for (let j = 0; j < resMediaCover.data.length; j++) {
                let mediaCover = resMediaCover.data[j];
                let splitCover = mediaCover.name.split("__");
                if (splitCover[1] !== "cover" || splitCover[2] !== splitPdf[2])
                  continue;

                try {
                  let resDownloadPdf = await XiboAuto.get(
                    "/library/download/" + mediaPdf.mediaId,
                    { responseType: "arraybuffer" }
                  );
                  let arr = new Uint8Array(resDownloadPdf.data);
                  let blob = new Blob([arr], { type: "application/pdf" });
                  let url = window.URL.createObjectURL(blob);
                  arrPdf.push({
                    urlPdf: url,
                    idCover: mediaCover.mediaId,
                  });
                } catch (err) {
                  alert("Erreur, service non valable pour le moment.");
                }
                break;
              }
            }
            setPdf(arrPdf);
          },
          (err) => {
            alert("Erreur, service non valable pour le moment.");
          }
        );
      },
      (err) => {
        alert("Erreur, service non valable pour le moment.");
      }
    );
  };

  const renderPdf = () => {
    let arrPdf = [];
    for (let i = 0; i < pdf.length; i++) {
      arrPdf.push(
        <ItemPrint
          key={"printKey" + i}
          img={
            // "https://connexion.lisaconnect.fr/api/library/download/" +
            `${XiboURL}/api/library/download/` +
            pdf[i].idCover +
            "?access_token=" +
            accessToken.split(" ")[1]
          }
          idPdf={"pdf" + i}
          pdf={pdf[i].urlPdf}
          onClick={handleClick}
        />
      );
    }

    return arrPdf;
  };

  const print = () => {
    pdfToPrint.contentWindow.focus();
    pdfToPrint.contentWindow.print();
  };

  const handleClick = (element) => {
    setPdfToPrint(element);
    setShowConfirm(true);
  };

  const handleConfirm = () => {
    setShowConfirm(false);
    print();
  };

  return (
    <div>
      <Container className="center-container">
        <h2 className="mb-4">{props.title}</h2>
        <div className="flex-list">{renderPdf()}</div>
      </Container>
      <ConfirmationModal
        show={showConfirm}
        closeModal={() => setShowConfirm(false)}
        confirm={handleConfirm}
        message="Imprimer ?"
      />
    </div>
  );
}

export default PrintView;

import React from "react";
import LeadsList from "../../components/Leads/LeadsList";
import "../../assets/css/leadsStyle.scss";
import { ModalForms } from "../../components/GoogleForms/GoogleForm";

function LeadsPage() {
  return (
    <section>
      <h1>Leads Page</h1>
      <div>
        <LeadsList />
      </div>
      <ModalForms />
    </section>
  );
}

export default LeadsPage;

import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import AnnonceContainer from "../../components/AnnonceContainer";
import AnnonceContainer_V5 from "../../components/AnnonceContainer_v5";
import "../../assets/css/Docboard.css";

function AdHome(props) {
  const [cookies] = useCookies(["refresh_token"]);
  const [isOk, setOk] = useState(false);
  const [content, setContent] = useState(null);

  useEffect(() => {
    if (cookies.user === undefined) return;
    else if (cookies.user.userName === "xibo_admin") {
      setOk(true);
    } else if (cookies.user.userName === "media_lisa") {
      setOk(true);
    } else if (cookies.user.userName === "anthony_lisa") {
      setOk(true);
    } else if (cookies.user.userName === "quentin_lisa") {
      setOk(true);
    } else if (cookies.user.groups[0].group === "Annonceurs") {
      if (cookies.user.ref1 !== null && cookies.user.ref2 !== null) {
        let splitDateDebut = cookies.user.ref1.split("/");
        let strDateDebut =
          splitDateDebut[2] + "-" + splitDateDebut[1] + "-" + splitDateDebut[0];
        let dateDebut = new Date(strDateDebut);

        let splitDateFin = cookies.user.ref2.split("/");
        let strDateFin =
          splitDateFin[2] + "-" + splitDateFin[1] + "-" + splitDateFin[0];
        let dateFin = new Date(strDateFin);

        if (dateDebut <= new Date() && dateFin > new Date()) {
          setOk(true);
        } else {
          setOk("Non abonné");
        }
      } else {
        setOk("Non abonné");
      }
    }
  }, []);

  useEffect(() => {
    if (isOk === "Non abonné") {
      setContent(
        <h1>
          Aucun abonnement actif, abonnez-vous ou attendez la date de départ de
          votre abonnement pour vous connecter.
        </h1>
      );
    } else if (isOk) {
      setContent(<AnnonceContainer numberOfLayouts="4" annonceur />);
      // setContent(<AnnonceContainer_V5 numberOfLayouts="4" annonceur />);
    }
  }, [isOk]);

  return <div>{content}</div>;
}

export default AdHome;

import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
//import Xibo from './APIs/api-xibo';

function HomeRouter(props) {
  const [cookies] = useCookies(["refresh_token"]);

  useEffect(() => {
    if (cookies.user == undefined) {
      props.history.push("/xauth");
    } else if (cookies.user.userName === "xibo_admin") {
      // console.log("on va push /admin");
      props.history.push("/admin");
    } else if (
      cookies.user.userName === "media_lisa" &&
      cookies.user.userTypeId === 1
    ) {
      // console.log("L ou Q");
      // console.log("on va push /admin");
      props.history.push("/admin");
    } else if (
      cookies.user.userName === "anthony_lisa" &&
      cookies.user.userTypeId === 1
    ) {
      // console.log("L ou Q");
      // console.log("on va push /admin");
      props.history.push("/admin");
    } else if (
      cookies.user.userName === "quentin_lisa" &&
      cookies.user.userTypeId === 1
    ) {
      // console.log("on va push /admin");
      props.history.push("/admin");
    } else if (cookies.user.groups[0].group === "Annonceurs") {
      props.history.push("/adboard");
    } else if (cookies.user.groups[0].group === "Medecins") {
      props.history.push("/docboard");
    }
  }, []);

  /*const handleDisconnection = (e) => {
    Xibo.get('/lisa/logout')
    .then(function(response) {
      document.cookie = "refresh_token=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "access_token=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "user=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      props.history.push("/xauth");
    });
  }*/

  return <div></div>;
}

export default HomeRouter;

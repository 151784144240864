import React from "react";
import ReactDOM from "react-dom";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import "bootstrap/dist/css/bootstrap.min.css";
import { CookiesProvider } from "react-cookie";

import Medecin from "./layouts/Medecin";
import Annonceur from "./layouts/Annonceur";
import Admin from "./layouts/Admin";
import Auth from "./layouts/Auth";
import Public from "./layouts/Public";
import { clientId, redirect_uri, xiboLogUri } from "./APIs/api-xibo";

import "./assets/css/Global.scss";

import HomeRouter from "./HomeRouter";

import { init } from "emailjs-com";
import { XiboURL } from "./APIs/api-xibo";

init("user_AyR8b7sXZqNFaAKojpVey");
const hist = createBrowserHistory();

ReactDOM.render(
  <CookiesProvider>
    <Router history={hist}>
      <Switch>
        <Route path="/docboard" component={Medecin} />
        <Route path="/adboard" component={Annonceur} />
        <Route path="/admin" component={Admin} />
        <Route path="/auth" component={Auth} />
        <Route path="/public" component={Public} />
        <Route path="/home" component={HomeRouter} />
        <Route
          path="/xauth"
          component={() => {
            window.location.href = `${xiboLogUri}/api/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirect_uri}`;
            return null;
          }}
        />
        <Route
          path="/logout"
          component={() => {
            window.location.href = `${XiboURL}/logout`;
            return null;
          }}
        />
        <Redirect from="/" to="/home" />
      </Switch>
    </Router>
  </CookiesProvider>,
  document.getElementById("root")
);

import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import * as XLSX from "xlsx";
import mbxClient from "@mapbox/mapbox-sdk";
import mbxGeocoding from "@mapbox/mapbox-sdk/services/geocoding";
import axios from "axios";
import { Row } from "react-bootstrap";

function ExcelUploader(props) {
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [cols, setCols] = useState([]);
  const [show, setShow] = useState(false);
  const [coordinates, setCoordinates] = useState(null);
  const [range, setRange] = useState("");

  const [target, setTarget] = useState(null);
  const columns = [
    { dataField: "Agence", text: "Etablissement" },
    { dataField: "Téléphone", text: "Téléphone" },
    { dataField: "Adresse", text: "Adresse" },
    { dataField: "latitude", text: "Latitude" },
    { dataField: "longitude", text: "Longitude" },
  ];

  const baseClient = mbxClient({
    accessToken:
      "pk.eyJ1IjoicmljaC1sYyIsImEiOiJjbGo2eWR5aHgwcTgxM2NwYWdqNjhhNTBkIn0.S5Dp1t66H5VexJ6rYksxEA",
  });
  const geocodingService = mbxGeocoding(baseClient);

  async function getAllCoordinates() {
    console.log("CA COMMENCE get all coordinates");

    let newData = [...data]; // make a copy to modify

    console.log(newData.length);

    for (let i = 0; i < newData.length; i++) {
      if (
        newData[i].Adresse !== undefined &&
        newData[i].latitude == null &&
        newData[i].longitude == null
      ) {
        console.log("on cherche les coordonnées");
        await geocodingService
          .forwardGeocode({
            query: newData[i].Adresse,
            limit: 1,
          })
          .send()
          .then((response) => {
            if (
              response &&
              response.body &&
              response.body.features &&
              response.body.features.length
            ) {
              console.log(response.body.features[0]);
              const coords = response.body.features[0].geometry.coordinates;
              newData[i].latitude = coords[1]; // swap to [1] for latitude and [0] for longitude
              newData[i].longitude = coords[0];
              console.log(coords);
            } else {
              console.log("No coordinates found for this address");
            }
          });
      } else {
      }
    }
    setData(newData); // update state with new data
  }

  // split target from  XXX,XXX  to XXX  and  XXX
  const splitTarget = (target) => {
    let targetArray = target.split(",");
    let target1 = targetArray[0];
    console.log(target1);
    let target2 = targetArray[1];
    console.log(target2);
    // setTarget([target1, target2]);
  };

  // const handleFileUpload = (event) => {
  //   const file = event.target.files[0];

  //   const reader = new FileReader();
  //   reader.onload = (evt) => {
  //     const bstr = evt.target.result;
  //     const workbook = XLSX.read(bstr, { type: "binary" });
  //     const worksheetName = workbook.SheetNames[0];
  //     const worksheet = workbook.Sheets[worksheetName];
  //     const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

  //     const cols = data[0]; // Assuming first row is the header
  //     data.splice(0, 1); // Remove first row (header)

  //     setCols(cols);
  //     console.log(cols);
  //     setData(data);
  //   };

  //   reader.readAsBinaryString(file);
  // };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    const reader = new FileReader();
    reader.onload = async (evt) => {
      // ajouté async
      const bstr = evt.target.result;
      const workbook = XLSX.read(bstr, { type: "binary" });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const rawData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const cols = rawData[0]; // Assuming first row is the header
      rawData.splice(0, 1); // Remove first row (header)

      // Filter out empty rows
      const filteredData = rawData.filter((row) =>
        row.some((cell) => cell !== null && cell !== "")
      );

      const data = filteredData.map((row) => {
        let obj = {};
        row.forEach((value, index) => {
          obj[cols[index]] = value;
        });
        return obj;
      });

      setCols(cols);
      setData(data);
      setOriginalData(data);
    };

    reader.readAsBinaryString(file);
  };

  const handleColumnSelection = async (event, index) => {
    // Map the column to the respective field
    let colName = event.target.value;
    for (let i = 0; i < data.length; i++) {
      data[i][index] = { [colName]: data[i][index] };
    }

    // console.log(data[0]);
    // console.log(data[0]["Adresse"]);
    // const test = await getCoordinates(data[0]["Adresse"]);
    // console.log(test);

    setData([...data]);
  };

  const handleRadiusSearch = () => {
    console.log("DEBUT HANDLE RADIUS SEARCH");
    console.log("target");
    console.log(target);

    console.log(target);

    // Assuming target is a string like 'latitude,longitude'
    const [targetLat, targetLong] = target.split(",").map(Number);
    const radius = range; // Ensure this is in kilometers

    // Calculate the distance for each row and filter out those outside the radius
    const filteredData = data
      .map((row) => ({
        ...row,
        distance:
          row.latitude && row.longitude
            ? calculateDistance(
                targetLat,
                targetLong,
                row.latitude,
                row.longitude
              )
            : Infinity, // check if latitude and longitude are defined
      }))
      .filter((row) => row.distance <= radius);

    // Sort the data by distance
    filteredData.sort((a, b) => a.distance - b.distance);

    setData(filteredData);
  };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    // Convert degrees to radians
    lat1 = (lat1 * Math.PI) / 180.0;
    lon1 = (lon1 * Math.PI) / 180.0;

    lat2 = (lat2 * Math.PI) / 180.0;
    lon2 = (lon2 * Math.PI) / 180.0;

    // radius of earth in metres
    var R = 6371e3;

    // P
    var x = (lon2 - lon1) * Math.cos(0.5 * (lat2 + lat1));
    var y = lat2 - lat1;

    // Euclidean distance in km
    return (Math.sqrt(x * x + y * y) * R) / 1000;
  };

  const exportToExcel = () => {
    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Create a new worksheet from the data
    const ws = XLSX.utils.json_to_sheet(data);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Save the workbook to a file
    XLSX.writeFile(wb, "output.xlsx");
  };

  const resetData = () => {
    setData(originalData);
    setRange("");
  };

  useEffect(() => {
    if (props.target) {
      setTarget(props.target);
    }
  }, [props]);

  return (
    <div>
      <div style={{ width: "100%", textAlign: "center", marginBottom: "20px" }}>
        <b>Position de la borne cible : </b> Latitude :{" "}
        {target ? target.split(",")[0] : <></>} | Longitude :{" "}
        {target ? target.split(",")[1] : <></>}
      </div>
      <div>
        <div style={{ marginBottom: "30px", textAlign: "center" }}>
          <input type="file" onChange={handleFileUpload} />
          <button
            onClick={() => {
              setShow(true);
              getAllCoordinates();
            }}
            style={{
              backgroundColor: "blue",
              color: "white",
              fontWeight: "bolder",
            }}
          >
            SHOW
          </button>
          <button onClick={exportToExcel} style={{ marginLeft: "20px" }}>
            Export to Excel
          </button>
        </div>
        <Row
          style={{
            margin: 0,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
          }}
        >
          {cols.length > 0 &&
            cols.map((col, index) => (
              <div key={`${index}-${col}`} style={{ marginRight: "10px" }}>
                <label key={index} style={{ marginLeft: "5px" }}>
                  {col}{" "}
                </label>
                {" :  "}
                <select
                  onChange={(event) => handleColumnSelection(event, index)}
                >
                  <option value="">Select</option>
                  <option value="Nom">Nom</option>
                  <option value="Téléphone">Téléphone</option>
                  <option value="Adresse">Adresse</option>
                </select>
              </div>
            ))}
        </Row>
        <div></div>
        <div style={{ width: "100%", textAlign: "center" }}>
          <input
            type="text"
            value={range}
            onChange={(event) => setRange(event.target.value)}
            placeholder="rayon en km"
          />
          <button
            onClick={handleRadiusSearch}
            style={{
              marginLeft: "20px",
              backgroundColor: "green",
              color: "white",
              fontWeight: "bolder",
            }}
          >
            Filtrer
          </button>
          <button
            onClick={resetData}
            style={{
              marginLeft: "20px",
              backgroundColor: "red",
              color: "white",
              fontWeight: "bolder",
            }}
          >
            RESET
          </button>
        </div>
      </div>
      {show ? (
        <BootstrapTable
          keyField="Etablissement"
          data={data}
          columns={columns}
          striped
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default ExcelUploader;

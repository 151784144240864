import React, { useState, useRef, useEffect } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import "../../assets/css/createPrintsTemp.scss";
import { Xpress, XpressNoApi } from "../../APIs/api-xibo";
import {
  CreatePrintTemplate,
  DeletePrintTemplate,
  GetAllPrintFiles,
  ModifyPrintTemplate,
} from "../../services/PrintsServices";

export default function PrintsTemplateForm({ dataTemplate, setShowSection }) {
  const [printsFiles, setPrintsFiles] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    title: "",
    icon: "",
    is_new: false,
    files: [],
    MVfiles: [],
    DYSfiles: [],
  });
  const [fileValidity, setFileValidity] = useState({
    files: [],
    MVfiles: [],
    DYSfiles: [],
  });
  const fileInputRefs = {
    files: useRef([]),
    MVfiles: useRef([]),
    DYSfiles: useRef([]),
  };

  const [showAlertSuccess, setShowAlertSuccess] = useState(false);
  const [showAlertFailed, setShowAlertFailed] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  async function getAllPrintFiles() {
    const allPrints = await GetAllPrintFiles();
    if (allPrints.error) {
      setAlertMessage(allPrints.data.toString() || "Network Error");
      setShowAlertFailed(true);
    } else {
      setPrintsFiles(allPrints.data);
    }
  }

  const cleanForm = () => {
    setFormData({
      name: "",
      title: "",
      icon: "",
      is_new: false,
      files: [],
      MVfiles: [],
      DYSfiles: [],
    });
    setFileValidity({
      files: [],
      MVfiles: [],
      DYSfiles: [],
    });
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "switch" || type === "checkbox") {
      setFormData({ ...formData, [name]: checked });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleFileSelect = (e, fileType, index) => {
    const file = e.target.value;
    const isFileSelected = file !== "--- Select Print File ---";
    const updatedValidity = fileValidity[fileType].map((valid, i) =>
      i === index ? isFileSelected : valid
    );
    setFileValidity({ ...fileValidity, [fileType]: updatedValidity });
    const updatedFiles = formData[fileType].map((f, i) =>
      i === index ? { ...f, name: file } : f
    );
    setFormData({ ...formData, [fileType]: updatedFiles });
  };

  const handleFileListChange = (e, fileType, index) => {
    const file = e.target.files[0];
    const isValidFileType =
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg";

    if (!isValidFileType) {
      fileInputRefs[fileType].current[index].value = "";
    }
    const updatedFiles = formData[fileType].map((f, i) => {
      if (i === index) {
        return isValidFileType ? { ...f, name: file.name } : f;
      }
      return f;
    });

    const updatedValidity = fileValidity[fileType].map((valid, i) => {
      return i === index ? isValidFileType : valid;
    });
    setFormData({ ...formData, [fileType]: updatedFiles });

    setFileValidity({ ...fileValidity, [fileType]: updatedValidity });
  };

  const addFileField = (fileType) => {
    setFormData({
      ...formData,
      [fileType]: [...formData[fileType], { name: "", url: "" }],
    });
    setFileValidity({
      ...fileValidity,
      [fileType]: [...fileValidity[fileType], true],
    });
    fileInputRefs[fileType].current.push(React.createRef());
  };

  function checkFilesValidity(dataTarget) {
    const filesValidity = dataTarget.files.map((file) => file.name !== "");
    if (filesValidity.includes(false)) {
      setFileValidity({ ...fileValidity, files: filesValidity });
      return false;
    }
    return true;
  }

  function checkMVfilesValidity() {
    const MVfilesValidity = formData.MVfiles.map((file) => file.name !== "");
    if (MVfilesValidity.includes(false)) {
      setFileValidity({ ...fileValidity, MVfiles: MVfilesValidity });
      return false;
    }
    return true;
  }

  function checkDYSfilesValidity() {
    const DYSfilesValidity = formData.DYSfiles.map((file) => file.name !== "");
    if (DYSfilesValidity.includes(false)) {
      setFileValidity({ ...fileValidity, DYSfiles: DYSfilesValidity });
      return false;
    }
    return true;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.files.length === 0) {
      setFileValidity({ ...fileValidity, files: [false] });
      return;
    }
    if (
      !checkFilesValidity(formData) ||
      !checkMVfilesValidity() ||
      !checkDYSfilesValidity()
    ) {
      return;
    }

    if (dataTemplate && dataTemplate._id) {
      ModToAPI(e);
    } else {
      SaveToAPI(e);
    }
  };

  async function SaveToAPI(e) {
    e.preventDefault();
    const creation = await CreatePrintTemplate(formData);
    if (creation.error) {
      setAlertMessage(creation.data.toString() || "Network Error");
    } else {
      setShowAlertSuccess(true);
      cleanForm();
      setShowSection ? setShowSection(false) : <></>;
    }
  }

  async function ModToAPI(e) {
    e.preventDefault();
    const modification = await ModifyPrintTemplate(dataTemplate._id, formData);
    if (modification.error) {
      setAlertMessage(modification.data.toString() || "Network Error");
    } else {
      setShowAlertSuccess(true);
      cleanForm();
      setShowSection ? setShowSection(false) : <></>;
    }
  }

  const deletePT = async (e) => {
    e.preventDefault();
    const deletion = await DeletePrintTemplate(dataTemplate._id);
    if (deletion.error) {
      setAlertMessage(deletion.data.toString() || "Network Error");
    } else {
      setShowAlertSuccess(true);
      cleanForm();
      setShowSection ? setShowSection(false) : <></>;
    }
  };

  const removeFileField = (fileType, index) => {
    setFormData({
      ...formData,
      [fileType]: formData[fileType].filter((_, i) => i !== index),
    });
    setFileValidity({
      ...fileValidity,
      [fileType]: fileValidity[fileType].filter((_, i) => i !== index),
    });
    fileInputRefs[fileType].current.splice(index, 1);
  };

  function renderFileInputs(fileType) {
    return formData[fileType].map((file, index) => (
      <div key={`${fileType}-${index}`}>
        {index != 0 && index != formData[fileType].length ? <hr /> : <></>}
        <Form.Group className="mb-3" key={index}>
          <Form.Label
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "5px 10px",
            }}
            size="sm"
          >
            <span>
              {fileType} {index + 1}
            </span>

            <Button
              variant="danger"
              size="sm"
              onClick={() => removeFileField(fileType, index)}
            >
              X
            </Button>
          </Form.Label>
          {/* {fileInputRefs[fileType].current[index] &&
          fileInputRefs[fileType].current[index].value ? (
            <div className="text-center font-italic">{file.name} </div>
          ) : (
            <>
              <Form.Control
                type="file"
                isInvalid={!fileValidity[fileType][index]}
                onChange={(e) => handleFileListChange(e, fileType, index)}
                ref={(el) => (fileInputRefs[fileType].current[index] = el)}
                size="sm"
                accept=".png, .jpg, .jpeg"
              />
              <Form.Control.Feedback type="invalid">
                Type de fichier invalide.
              </Form.Control.Feedback>
            </>
          )} */}
          <Form.Control
            size="sm"
            as="select"
            onChange={(e) => handleFileSelect(e, fileType, index)}
            isInvalid={!fileValidity[fileType][index]}
            key={index}
            value={file.name}
          >
            <option key={"-1"}>--- Select Print File ---</option>
            {printsFiles.map((file, index) => (
              <option key={index + "-opt"}>{file}</option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            Choisir un fichier ou supprimer la ligne !
          </Form.Control.Feedback>
        </Form.Group>
      </div>
    ));
  }

  useEffect(() => {
    getAllPrintFiles();
  }, []);

  useEffect(() => {
    if (dataTemplate) {
      console.log(dataTemplate);
      setFormData({
        name: dataTemplate.name || "",
        title: dataTemplate.title || "",
        icon: dataTemplate.icon || "",
        is_new: dataTemplate.is_new || false,
        files: dataTemplate.files || [],
        MVfiles: dataTemplate.MVfiles || [],
        DYSfiles: dataTemplate.DYSfiles || [],
      });
      const initFileValidity = (files) => files.map((file) => file.name !== "");

      setFileValidity({
        files: initFileValidity(dataTemplate.files || []),
        MVfiles: initFileValidity(dataTemplate.MVfiles || []),
        DYSfiles: initFileValidity(dataTemplate.DYSfiles || []),
      });
    }
  }, [dataTemplate]);

  return (
    <>
      <Container className="forms-deco" id="printTemplCreate">
        <Row>
          <Col>
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Ref</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      required
                      onChange={handleInputChange}
                      placeholder="ex:  sudoku-classique-1"
                      size="sm"
                      value={formData.name}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Titre</Form.Label>
                    <Form.Control
                      type="text"
                      name="title"
                      required
                      onChange={handleInputChange}
                      size="sm"
                      placeholder="ex:  sudoku"
                      value={formData.title}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Icône (Nom)</Form.Label>
                    <Form.Control
                      type="text"
                      name="icon"
                      required
                      onChange={handleInputChange}
                      size="sm"
                      placeholder="ex:  sudoku.png"
                      value={formData.icon}
                    />
                  </Form.Group>
                </Col>
                <Col
                  xs={2}
                  style={{ textAlign: "center", alignSelf: "center" }}
                >
                  <Form.Group className="">
                    <Form.Check
                      type="checkbox"
                      label="'New' label ?"
                      name="is_new"
                      onChange={handleInputChange}
                      size="sm"
                      // value={formData.is_new}
                      checked={formData.is_new}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
              <br />
              {/* --------------- FILES ---------------- */}
              <Form.Row>
                <Col className="files-up-btns-sect">
                  <div>
                    <Button
                      className="files-up-sect-btn"
                      onClick={() => addFileField("files")}
                      variant="outline-secondary"
                      size="sm"
                    >
                      Ajouter un <b> Fichier </b>(classique)
                    </Button>
                  </div>
                  <div className="files-up-btns-render">
                    {renderFileInputs("files")}
                  </div>
                </Col>

                <Col className="files-up-btns-sect">
                  <div>
                    <Button
                      className="files-up-sect-btn"
                      onClick={() => addFileField("MVfiles")}
                      variant="outline-secondary"
                      size="sm"
                    >
                      Ajouter <b>Fichier Mal-Voyants</b>
                    </Button>
                  </div>
                  <div className="files-up-btns-render">
                    {renderFileInputs("MVfiles")}
                  </div>
                </Col>

                <Col className="files-up-btns-sect">
                  <div>
                    <Button
                      className="files-up-sect-btn"
                      onClick={() => addFileField("DYSfiles")}
                      variant="outline-secondary"
                      size="sm"
                    >
                      Ajouter <b>Fichier Dyslexiques</b>
                    </Button>
                  </div>
                  <div className="files-up-btns-render">
                    {renderFileInputs("DYSfiles")}
                  </div>
                </Col>
              </Form.Row>
              {/* --------------- FORM BUTTONS ---------------- */}
              <Form.Row className="mt-4">
                {dataTemplate ? (
                  <Col className="text-right">
                    <Button variant="danger" onClick={deletePT} size="sm">
                      Delete
                    </Button>
                  </Col>
                ) : (
                  <></>
                )}
                <Col className="text-right">
                  <Button variant="warning" onClick={cleanForm} size="sm">
                    Clean
                  </Button>
                </Col>
                <Col className="text-left">
                  <Button variant="success" type="submit" size="sm">
                    {dataTemplate ? "Modifier" : "Créer"}
                  </Button>
                </Col>
              </Form.Row>
            </Form>
          </Col>
        </Row>
      </Container>
      <Alert
        show={showAlertFailed}
        key={"fileFail"}
        variant={"danger"}
        className="alertsForms"
      >
        <Alert.Heading>Network Error</Alert.Heading>
        <p>
          <br />
          <i> Something went wrong during your last action :(</i> <br />
          Maybe you have not the rights to do this...
          <br />
          Try to : <br />
          - Refresh the page and try again. <br />
          - Log out from this dashboard and login another time
          <br /> <br />
          If the problem persists, contact the{" "}
          <b>
            <a href="mailto: richard@lisaconnect.fr" target="_blank">
              administrator
            </a>
            .
          </b>
        </p>
        <div>{alertMessage}</div>
        <div className="d-flex justify-content-end">
          <Button
            onClick={() => setShowAlertFailed(false)}
            variant="outline-danger"
          >
            OK
          </Button>
        </div>
      </Alert>
      <Alert
        show={showAlertSuccess}
        variant="success"
        className="alertsForms"
        id="AlertSuccessFile"
      >
        <Alert.Heading>
          Prints Template successfully {dataTemplate ? "Updated" : "Created"}
        </Alert.Heading>
        <hr />
        <div className="d-flex justify-content-center">
          <Button
            onClick={() => setShowAlertSuccess(false)}
            variant="outline-success"
            size="sm"
            className="btn-icon-list"
          >
            OK
          </Button>
        </div>
      </Alert>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Alert, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { XapiURL, Xpress, XpressNoApi } from "../../APIs/api-xibo";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { BsXSquare } from "react-icons/bs";
import ConfirmationModal from "../ConfirmationModal";
import { handleCopy, rotateImg } from "../../services/Utils";

export default function FilesList() {
  const [files, setFiles] = useState([]);
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [parthToDelete, setParthToDelete] = useState("");

  const [showAlertSuccess, setShowAlertSuccess] = useState(false);
  const [showAlertFailed, setShowAlertFailed] = useState(false);

  async function getAllFiles() {
    try {
      const response = await XpressNoApi.get("/images");
      if (response.status === 200) {
        setFiles(response.data);
      }
    } catch (error) {
      console.log(error);
      setShowAlertFailed(true);
    }
  }

  const ImageDiv = (props) => {
    const data = props;
    return (
      <div className="IconesDivs" key={props}>
        <span onClick={() => handleCopy(data)}>
          <img
            className="iconsImgs"
            src={XapiURL + "/interfaces/image/" + props}
          />
          <div className="fadedbox">
            <div className="title text">
              <span onClick={() => handleCopy(data)}>{props}</span>
            </div>
          </div>
        </span>
        <BsXSquare
          className="delete-icons-interfaces"
          color={"red"}
          onClick={() => {
            setShowConfirm(true);
            setParthToDelete(props);
          }}
        />
      </div>
    );
  };

  async function deleteImgDB(filePath) {
    try {
      const response = await Xpress.delete("/bornes/images/delete/" + filePath);

      if (response.status === 200) {
        setShowAlertSuccess(true);
        getAllFiles();
      } else {
        setShowAlertFailed(true);
      }
    } catch (error) {
      setShowAlertFailed(true);
    }
  }

  const handleClick = () => {
    setShowConfirm(false);
    deleteImgDB(parthToDelete);
  };

  const closeConfirm = () => {
    setShowConfirm(false);
    setParthToDelete("");
  };

  useEffect(() => {
    getAllFiles();
  }, []);

  return (
    <div>
      <h4>
        <button
          className="btn interfSectionsH3"
          type="button"
          onClick={() => {
            setShow(!show);
            rotateImg("carret-iconsList2");
          }}
        >
          <h4 className="data-titles-tab">
            <span id="btn-title-span">
              Icons List {"  "}
              <OverlayTrigger
                key={"top"}
                placement={"top"}
                overlay={
                  <Tooltip id={`tooltip-top`}>
                    <strong>Cliquez</strong> sur le{" "}
                    <strong>nom de l'icone</strong> pour le{" "}
                    <strong>copier</strong>.
                  </Tooltip>
                }
              >
                <BsFillInfoCircleFill id="info-icons" />
              </OverlayTrigger>
            </span>
            <i
              className="bi bi-caret-down-fill rotate carret-iconsList"
              id={"carret-iconsList2"}
            ></i>
          </h4>
        </button>
      </h4>
      {show ? (
        <section id="iconsSection">
          {files.map((file) => {
            return ImageDiv(file);
          })}
        </section>
      ) : (
        <></>
      )}
      <section>
        <ConfirmationModal
          key={"confM"}
          show={showConfirm}
          closeModal={closeConfirm}
          message="Supprimer définitivement l'icone ?"
          confirm={handleClick}
        />
      </section>
      <section id="alerts-forms">
        <Alert
          show={showAlertFailed}
          key={"fileFail"}
          variant={"danger"}
          className="alertsForms"
        >
          <Alert.Heading>Network Error</Alert.Heading>
          <p>
            <br />
            <i> Something went wrong during your last action :(</i> <br />
            Maybe you have not the rights to do this...
            <br />
            Try to : <br />
            - Refresh the page and try again. <br />
            - Log out from this dashboard and login another time
            <br /> <br />
            If the problem persists, contact the{" "}
            <b>
              <a href="mailto: richard@lisaconnect.fr" target="_blank">
                administrator
              </a>
              .
            </b>
          </p>
          <div className="d-flex justify-content-end">
            <Button
              onClick={() => setShowAlertFailed(false)}
              variant="outline-danger"
            >
              OK
            </Button>
          </div>
        </Alert>
        <Alert
          show={showAlertSuccess}
          variant="success"
          className="alertsForms"
          id="AlertSuccessFile"
        >
          <Alert.Heading>Icon successfully Deleted</Alert.Heading>
          <hr />
          <div className="d-flex justify-content-center">
            <Button
              onClick={() => setShowAlertSuccess(false)}
              variant="outline-success"
              size="sm"
              className="btn-icon-list"
            >
              OK
            </Button>
          </div>
        </Alert>
      </section>
    </div>
  );
}

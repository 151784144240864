import React, { useRef } from "react";
// import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/Button";

import "../assets/css/Docboard.css";
// import { Form } from "react-bootstrap";

function ItemPrint(props) {
  const iPdf = useRef(null);

  const print = () => {
    iPdf.current.contentWindow.focus();
    iPdf.current.contentWindow.print();
  };

  return (
    <div
      onClick={(e) => props.onClick(iPdf.current)}
      className="item-print-container mb-4"
    >
      <iframe
        title="print"
        ref={iPdf}
        style={{ display: "none" }}
        src={props.pdf}
        id={props.idPdf}
      ></iframe>
      <img className="item-print-img" loading="lazy" src={props.img} alt="print image item" />
      <Button className="item-print-button">Sélectionner</Button>
    </div>
  );
}

export default ItemPrint;

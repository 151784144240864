import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import "../assets/css/Docboard.css"
import { Form } from "react-bootstrap";

function InputModal(props) {
    return (
        <Modal
        show={props.show}
        onHide={props.closeModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.message}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
            <Form.Control type="text" maxLength="40" onChange={props.onChange} value={props.value} />
            </Form>
        </Modal.Body>
        <Modal.Footer>
        <Button className="mr-3" variant="primary" onClick={props.confirm}>
                Confirmer
            </Button>
            <Button variant="danger" onClick={props.closeModal}>
                Annuler
            </Button>
        </Modal.Footer>
      </Modal>
    );
}

export default InputModal;

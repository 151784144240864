import { useState } from "react";
import axios from "axios";
import GetIpClient from "./getIp";
import { XapiURL } from "../APIs/api-xibo";

async function DisplaysProofs(scheduleRes, mediaName, cookies, target) {
  const Xpress = axios.create({
    baseURL: `${XapiURL}/api`,
    withCredentials: true,
    crossDomain: true,
    // origin: "https://gestion.lisaconnect.fr",
    origin: "localhost:3000",
  });
  let clientIp = await GetIpClient();
  let videoName = mediaName;
  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;
  const time =
    current.getHours() +
    ":" +
    current.getMinutes() +
    ":" +
    current.getSeconds();

  let proof;
  // console.log("cookies");
  // console.log(cookies);
  try {
    proof = await Xpress.post(
      `/proof/new?userID=${cookies.user.userId}&userName=${cookies.user.userName}&ip=${clientIp}&videoID=${target.videoId}&videoName=${videoName}&date=${time} - ${date}&displayGroupID=${scheduleRes.data.displayGroups[0].displayGroupId}&displayGroupName=${scheduleRes.data.displayGroups[0].displayGroup}`
    );
    // console.log(proof);
    if (proof.status === 201 || proof.status === 200) {
      // console.log("Proof created");
      return true;
    } else {
      // console.log("Proof not created");
      return "Proof not created";
    }
  } catch (error) {
    return error;
  }
}

export default DisplaysProofs;

import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

import Container from "react-bootstrap/Container";
import "../../assets/css/Docboard.css";
import EndPaper from "../../components/EndPaper";
// import Xibo from "../../APIs/api-xibo";
import PrinterStatus from "../../components/PrinterStatus";
import ContactForm from "../../components/Printers/ContactsForm";
import { rotateImg } from "../../services/Utils";
import ContactFormVeryOld from "../../components/Printers/ContactsForm_very_old";
import ContactFormSimply from "../../components/Printers/ContactsForm_simplified";
import { ModalForms } from "../../components/GoogleForms/GoogleForm";

function AdminPrinter(props) {
  const [cleanedUsers, setCleanedUsers] = useState([]);
  // const [refresher, setRefresher] = useState(false);
  // const [data, setData] = useState([]);
  const [showContactForm, setShowContactForm] = useState(false);
  const [showPapierSect, setShowPapierSect] = useState(false);
  const [showPrintertSect, setShowPrintertSect] = useState(false);

  const [isAdm, setAdm] = useState(false);

  const [cookies] = useCookies([]);

  useEffect(() => {
    if (cookies === undefined) {
      // props.history.push("/xauth");
    } else if (
      cookies.user.userTypeId === 1 &&
      cookies.user.userName === "xibo_admin"
    ) {
      console.log("admin");
      setAdm(true);
    } else {
      console.log("not admin");
      setAdm(false);
    }
  }, []);

  return (
    <Container>
      <h1 className="mt-1 mb-5 ">Printers Dashboard</h1>

      <Container className="mt-1">
        <Container
          className="btn G-title interfSectionsH3"
          onClick={() => {
            setShowPrintertSect(!showPrintertSect);
            rotateImg("carret-printerList");
          }}
          id="printer-title-btn"
        >
          <h2
            className="data-titles-tab"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <span id="btn-title-span">Status Imprimantes</span>
            <i
              className="bi bi-caret-down-fill rotate h4"
              id={"carret-printerList"}
            ></i>
          </h2>
        </Container>
      </Container>
      {showPrintertSect ? (
        <Container>
          {" "}
          <PrinterStatus isAdmin={isAdm} />{" "}
        </Container>
      ) : (
        <></>
      )}
      <Container className="mt-1">
        <Container
          className="btn G-title interfSectionsH3"
          onClick={() => {
            setShowPapierSect(!showPapierSect);
            rotateImg("carret-paperList");
          }}
          id="paper-title-btn"
        >
          <h2
            className="data-titles-tab"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <span id="btn-title-span">Status Papier</span>
            <i
              className="bi bi-caret-down-fill rotate h4"
              id={"carret-paperList"}
            ></i>
          </h2>
        </Container>
      </Container>
      {showPapierSect ? (
        <Container>
          <EndPaper isAdmin={isAdm} />
        </Container>
      ) : (
        <></>
      )}
      <ModalForms />
    </Container>
  );
}

export default AdminPrinter;

import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import routes from '../routes';

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if(prop.layout === "/auth")
      {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          /> 
        );
      }
      return null;
    })}
    <Redirect from="/auth" to="/auth/login" />
  </Switch>
);

function Auth() {
  return (
    <div>
      <div>{switchRoutes}</div>
    </div>
  );
}

export default Auth;

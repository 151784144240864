import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import Button from "react-bootstrap/Button";
import BootstrapTable from "react-bootstrap-table-next";
import emailjs from "emailjs-com";
import "../assets/css/Docboard.css";
import Xibo, { UpdateAccessState } from "../APIs/api-xibo";
import ConfirmationModal from "./ConfirmationModal";
import { Container } from "react-bootstrap";
import VideoPlayer from "./VideoPlayer";
import { XiboURL } from "../APIs/api-xibo";

function WaitingAds(props) {
  const [dataSet, setDataSet] = useState([]);
  const [videos, setVideos] = useState([]);
  const [newDataToValid, setNewDataToValid] = useState(null);
  const [statutAttente, setStatutAttente] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [cookies] = useCookies("user");
  useEffect(() => {
    UpdateAccessState(setAccessToken);
    setAccessToken(cookies.access_token);
    getDataSet();
  }, []);

  useEffect(() => {
    getVideos();
  }, [dataSet]);

  const getVideos = async () => {
    let vids = [];
    try {
      for (let i = 0; i < dataSet.length; i++) {
        let vidName = dataSet[i].Annonce;
        let media = await Xibo.get("/library?media=" + vidName);
        vids.push({
          name: vidName,
          player: (
            <VideoPlayer
              idVid={dataSet[i]["Layout id"]}
              src={
                // "https://connexion.lisaconnect.fr/api/library/download/" +
                `${XiboURL}/api/library/download/` +
                media.data[0].mediaId +
                "/video?access_token=" +
                accessToken.split(" ")[1]
              }
            />
          ),
        });
      }
      setVideos(vids);
    } catch (err) {
      alert(err);
    }
  };

  const getDataSet = () => {
    Xibo.get("/dataset/data/2?length=1000").then(
      (res) => {
        let newData = [];
        for (let i = 0; i < res.data.length; i++) {
          newData.push(res.data[i]);
          let dt = new Date(newData[i]["Date de la demande"]);
          let option = { hour: "numeric", minute: "numeric" };
          let formattedDate = dt.toLocaleDateString("fr-FR", option);
          newData[i]["Date de la demande"] = formattedDate;
          newData[i].validate = (
            <div>
              <Button
                className="mr-2"
                variant="primary"
                id={newData[i].id + "validate"}
                onClick={(e) => handleClick(e, newData[i], "validé")}
              >
                Valider
              </Button>
              <Button
                variant="danger"
                id={newData[i].id + "reject"}
                onClick={() => {
                  setNewDataToValid(newData[i]);
                  setStatutAttente("rejeté");
                  setShowConfirm(true);
                }}
              >
                Rejeter
              </Button>
            </div>
          );
        }
        setDataSet(newData);
      },
      (err) => console.log(err)
    );
  };

  const displayVideo = (vidName) => {
    for (let i = 0; i < videos.length; i++) {
      if (videos[i].name === vidName) {
        return videos[i].player;
      }
    }
  };
  const expandRow = {
    renderer: (row) => <Container>{displayVideo(row.Annonce)}</Container>,
    showExpandColumn: false,
  };

  const columns = [
    { dataField: "User id", text: "ID user", style: { cursor: "pointer" } },
    { dataField: "User name", text: "Nom", style: { cursor: "pointer" } },
    { dataField: "Annonce", text: "Annonce", style: { cursor: "pointer" } },
    { dataField: "Layout id", text: "ID layout", style: { cursor: "pointer" } },
    {
      dataField: "Date de la demande",
      text: "Date demande",
      style: { cursor: "pointer" },
    },
    { dataField: "validate", text: "#", style: { cursor: "pointer" } },
  ];

  const sendMail = (templateId, email, annonce) => {
    let formattedName = annonce.split("__")[2];
    let variables = { to_email: email, annonce: formattedName };
    emailjs
      .send("mailjet_zghavip", templateId, variables)
      .then((res) => {})
      // Handle errors here however you like, or use a React error boundary
      .catch((err) => console.error("Error :", err));
  };

  const closeConfirm = () => {
    setShowConfirm(false);
  };

  const handleClick = (e, data = null, statut = null) => {
    if (data === null) data = newDataToValid;
    if (statut === null) statut = statutAttente;
    setStatutAttente(null);
    setNewDataToValid(null);
    let params = new URLSearchParams();
    params.append("name", data.Annonce);
    params.append("description", statut);

    let config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    Xibo.put("/layout/" + data["Layout id"], params, config).then(
      (resPutLayout) => {
        Xibo.delete("/dataset/data/2/" + data.id).then(
          (res) => {
            let templateId = "";
            if (statut === "validé") {
              templateId = "annonce_validated";

              //let groupIds = data.displayGroupIds.split(";");

              let dateNow = new Date();
              let minutes = ("0" + dateNow.getMinutes()).slice(-2);
              let seconds = ("0" + dateNow.getSeconds()).slice(-2);

              // let stringDateNow =
              //   dateNow.getFullYear() +
              //   "-" +
              //   dateNow.getMonth() +
              //   "-" +
              //   dateNow.getDate() +
              //   " " +
              //   dateNow.getHours() +
              //   ":" +
              //   minutes +
              //   ":" +
              //   seconds;
            } else if (statut === "rejeté") {
              templateId = "annonce_rejected";
            }
            setShowConfirm(false);
            sendMail(templateId, data.Email, data.Annonce);
            getDataSet();
          },
          (err) => console.log(err)
        );
      },
      (err) => console.log(err)
    );
  };
  if (dataSet.length > 0) {
    return (
      <div>
        <BootstrapTable
          keyField="id"
          data={dataSet}
          columns={columns}
          expandRow={expandRow}
          striped
          hover
        />
        <ConfirmationModal
          show={showConfirm}
          closeModal={closeConfirm}
          message="Rejeter l'annonce ?"
          confirm={handleClick}
        />
      </div>
    );
  } else {
    return (
      <div className="no-datafound-div">
        Aucune annonce en attente pour le moment.
      </div>
    );
  }
}

export default WaitingAds;

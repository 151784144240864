import React, { useEffect, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import Xibo, { Xpress } from "../../APIs/api-xibo";
import LoadingModal from "../LoadingModal";

export default function VideoAuto({
  index,
  autoData,
  templates,
  updateLayouts,
}) {
  const [autoMode, setAutoMode] = useState(false);
  const [autoModeSelected, setAutoModeSelected] = useState("");
  const [saved, setSaved] = useState(false);
  const [slotConfig, setSlotConfig] = useState(null);
  const [actualTemplate, setActualTemplate] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");

  async function getconfig() {
    try {
      const resData = await Xpress.get(
        `/auto/config/layout/${autoData.layoutId}`
      );
      setSlotConfig(resData.data);
      return true;
    } catch (error) {
      console.log(error);
      alert("Erreur lors de la récupération de la configuration");
      return false;
    }
  }

  async function saveSlotConfig(
    borne_id,
    borne_displayId,
    borne_displayGroupId,
    video_slot,
    boucle_template
  ) {
    const bodyContent = {
      borne_id: borne_id,
      borne_displayId: borne_displayId,
      borne_displayGroupId: borne_displayGroupId,
      video_slot: video_slot,
      boucle_template: boucle_template,
    };

    try {
      const save = await Xpress.post("/auto/config", bodyContent);
      return { success: true, data: save.data, error: null };
    } catch (error) {
      console.log(error);
      return { success: false, data: null, error: error };
    }
  }

  const saveModeForSlot = async () => {
    setShowLoading(true);
    let resDisplay = await Xibo.get("/display");
    let resDisplayGroup = await Xibo.get("/displaygroup");
    console.log("resDisplay", resDisplay);
    let displays = [];
    for (let i = 0; i < resDisplay.data.length; i++) {
      displays.push({
        borne_displayId: resDisplay.data[i].displayId,
        borne_id: resDisplay.data[i].display,
        borne_displayGroupId: resDisplayGroup.data[i].displayGroupId,
      });
    }
    if (displays.length > 1) {
      // pas normal on va dire MAINTENANT
      // console.log("plusieurs displays pour un compte", displays);
      console.log("plusieurs displaysGroup pour un médecin", displays);
      // ALERTE ADMIN
    }

    // for (let i = 0; i < displays.length; i++) {
    // const newSlot = await saveSlotConfig(
    //   displays[i].borne_id,
    //   displays[i].borne_displayId,
    //   index,
    //   autoModeSelected
    // );
    // }
    const saved = await saveSlotConfig(
      displays[0].borne_id,
      displays[0].borne_displayId,
      displays[0].borne_displayGroupId,
      index + 1,
      autoModeSelected
    );

    if (saved.success) {
      setShowLoading(false);
      updateLayouts();
    } else {
      setShowLoading(false);
      setAlertMessage("Erreur lors de la sauvegarde");
      setAlertType("danger");
      setShowAlert(true);
      console.error("Erreur lors de la sauvegarde", saved.error);
    }
  };

  const SavedBlock = () => (
    <div>
      <span>
        <b>Mode automatique activé</b>
      </span>
      <br />
      <br />
      <br />
      {actualTemplate && (
        <>
          <div>
            <i>Thème : </i>
            {actualTemplate.name}
          </div>
          <br />
          <div>
            <i>Récurrence :</i>{" "}
            {actualTemplate.dated ? "dates fixes" : actualTemplate?.dyn_rec}
          </div>
        </>
      )}
      <br />
    </div>
  );

  const AutoBlock = () => (
    <div>
      Passer en mode automatique{" "}
      <input
        type="checkbox"
        name={index + "-autoMode"}
        checked={autoMode}
        onChange={() => setAutoMode(!autoMode)}
        id={index + "-autoMode"}
        style={{ width: "20px", height: "20px" }}
      />
    </div>
  );

  // useEffect(() => {
  //   if (autoData && templates.length > 0) {
  //     if (!slotConfig) {
  //       getconfig().then((res) => {
  //         if (res) {
  //           setSaved(true);
  //         } else {
  //           setAlertMessage(
  //             "Erreur lors de la récupération de la configuration"
  //           );
  //           setAlertType("danger");
  //           setShowAlert(true);
  //         }
  //       });
  //     }
  //   }
  // }, [autoData, templates]);

  useEffect(() => {
    let isMounted = true; // Indicateur pour suivre l'état du montage du composant

    if (autoData && templates.length > 0 && !slotConfig) {
      getconfig().then((res) => {
        if (isMounted) {
          // Vérifie si le composant est toujours monté
          if (res) {
            setSaved(true);
          } else {
            setAlertMessage(
              "Erreur lors de la récupération de la configuration"
            );
            setAlertType("danger");
            setShowAlert(true);
          }
        }
      });
    }

    return () => {
      isMounted = false; // Met à jour l'indicateur lorsque le composant est démonté
    };
  }, [autoData, templates, slotConfig]);

  useEffect(() => {
    if (slotConfig && templates.length > 0) {
      const matchTemp = templates.find(
        (temp) => temp._id === slotConfig.boucle_template
      );
      if (matchTemp) {
        setActualTemplate(matchTemp);
      }
    }
  }, [slotConfig]);

  useEffect(() => {
    if (showAlert)
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);
  }, [showAlert]);

  return (
    <>
      {saved ? <SavedBlock /> : <></>}
      {!autoMode && !saved ? <AutoBlock /> : <> </>}
      <br />
      {autoMode ? (
        <section>
          <div>
            <Form>
              <Form.Group controlId={"form-" + index + "-autoMode"}>
                <Form.Label>Liste des thèmes</Form.Label>
                <Form.Control
                  defaultValue="--- Choisir ---"
                  as="select"
                  size="sm"
                  onChange={(e) => setAutoModeSelected(e.target.value)}
                >
                  <option style={{ fontStyle: "italic", textAlign: "center" }}>
                    --- Choisir ---
                  </option>
                  {templates.map((option, index) => {
                    return (
                      <option key={index} value={option._id}>
                        {option.name}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Form>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-around",
            }}
          >
            <Button onClick={() => setAutoMode(false)} variant="danger">
              Annuler
            </Button>
            <Button onClick={saveModeForSlot} variant="success">
              Save
            </Button>
          </div>
        </section>
      ) : (
        <></>
      )}
      <LoadingModal show={showLoading} />
      {showAlert && (
        <div className="alert-container-central">
          <Alert id="Annonces_Alert" variant={alertType}>
            <b>{alertMessage}</b>
          </Alert>
        </div>
      )}
    </>
  );
}

import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Badge from "react-bootstrap/Badge";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { BsTrash, BsFillInfoCircleFill } from "react-icons/bs";
import { XiboURL } from "../APIs/api-xibo";

import Xibo, { UpdateAccessState } from "../APIs/api-xibo";
import { useCookies } from "react-cookie";
import "../assets/css/Docboard.css";
import LoadingModal from "./LoadingModal";
import ConfirmationModal from "./ConfirmationModal";
import VideoPlayer from "./VideoPlayer";
import InputModal from "./InputModal";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import AnnoncesAuto from "./AnnoncesAuto";
import DisplaysProofs from "../services/DisplaysProofs";
import StopDisplaysProofs from "../services/StopDisplaysProofs";
import AnnonceTools from "./AnnonceTools";

function AnnoncesContainer(props) {
  const [cookies] = useCookies(["refresh_token"]);
  const [upload, setUpload] = useState(new Array(10));
  const [layouts, setLayouts] = useState([]);
  const [showLoading, setShowLoading] = useState({ show: false, message: "" });
  const [showLoading2, setShowLoading2] = useState({
    show: false,
    message: "",
  });
  const [showConfirm, setShowConfirm] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [statutAttenteDelete, setStatutAttenteDelete] = useState("");
  const [eventClickDelete, setEventClickDelete] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [currIndex, setCurrIndex] = useState(-1);
  const [inputValue, setInputValue] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [MediaName, setMediaName] = useState("");

  const [changeOrderMod, setChangeOrderMod] = useState(false);
  const [videoOrder, setVideoOrder] = useState(layouts);

  useEffect(() => {
    console.log("changeOrderMod : ", changeOrderMod);
  }, [changeOrderMod]);

  useEffect(() => {
    UpdateAccessState(setAccessToken);
    setAccessToken(cookies.access_token);
    getLayouts();
  }, []);

  useEffect(() => {
    if (showLoading.message !== "") {
      setAlertMessage(showLoading.message);
      let show = showLoading.show;
      setShowLoading({ show: show, message: "" });
    }
  }, [showLoading]);
  useEffect(() => {
    if (showLoading2.message !== "") {
      setAlertMessage(showLoading2.message);
      let show = showLoading2.show;
      setShowLoading2({ show: show, message: "" });
    }
  }, [showLoading2]);

  useEffect(() => {
    if (alertMessage !== "") {
      alert(alertMessage);
      setAlertMessage("");
    }
  }, [alertMessage]);

  useEffect(() => {
    checkLayoutLength();
    //  if (window.location.pathname == "/docboard/annonces") {
    //    // hideInput = "collapse";
    //    // add - buttons;
    //    document.getElementById("addButton").style.display = "none";
    //  }
  }, [layouts]);

  const checkLayoutLength = () => {
    if (layouts.length === 0 && window.location.pathname == "/adboard/home") {
      let target = document.getElementById("video-row-bloc");
      if (target) {
        // target.style.display = "none";
        // document.getElementById("novalidatedvideo").style.display = "unset";
      }
    } else {
      let target = document.getElementById("video-row-bloc");
      if (target) {
        target.style.display = "flex";
        document.getElementById("novalidatedvideo").style.display = "none";
      }
    }
  };

  const getLayouts = async () => {
    Xibo.get("/layout?embed=regions,playlists,widgets&start=0&length=500").then(
      (res) => {
        console.log(res.data);
        let retLayout = [];
        if (res.status == 200) {
          if (res.data.length > 10) {
            console.log("-------------------------------");
            console.log("Alert .Too many layouts");
            // console.log(res.data);
            console.log("-------------------------------");
          }

          for (let i = 0; i < res.data.length; i++) {
            if (
              res.data[i].publishedStatus == "Published" &&
              res.data[i].ownerId == cookies.user.userId
            ) {
              retLayout.push(res.data[i]);
            }
          }
          setLayouts(retLayout);
          setVideoOrder(retLayout);
        }
      }
    );
  };

  const handleChange = (e) => {
    let cloneUpload = upload.slice();
    let index = parseInt(e.target.id);
    cloneUpload[index] = e.target.files[0];
    setUpload(cloneUpload);
    //cutVid(e.target.files?.item(0));
  };

  const handleClickInput = (e) => {
    let index = parseInt(e.target.id);
    if (upload[index] == undefined || upload[index] == null) {
      alert("Veuillez sélectionner une vidéo en cliquant le bouton Parcourir.");
      return;
    }
    if (upload[index].type != "video/mp4") {
      alert("Seules les vidéos au format mp4 sont acceptées.");
      return;
    }
    setCurrIndex(index);
    let newInputValue = upload[index].name.split(".")[0].substring(0, 40);
    setInputValue(newInputValue);
    setShowInput(true);
    //libraryUpload(index, upload[index].name, upload[index]);
  };

  const closeConfirm = () => {
    setShowConfirm(false);
  };

  const closeInput = () => {
    setShowInput(false);
  };

  function dateNow() {
    let dateNow = new Date();
    let minutes = ("0" + dateNow.getMinutes()).slice(-2);
    let seconds = ("0" + dateNow.getSeconds()).slice(-2);
    let stringDateNow =
      dateNow.getFullYear() +
      "-" +
      dateNow.getMonth() +
      "-" +
      dateNow.getDate() +
      " " +
      dateNow.getHours() +
      ":" +
      minutes +
      ":" +
      seconds;
    console.log(stringDateNow);
    return stringDateNow;
  }

  async function CheckDisplayOrders(data) {
    // console.log("-------------- CheckDisplayOrders --------------");
    let resDisplay = await Xibo.get("/display");
    let displays = resDisplay.data;
    // console.log(displays);
    for (let index = 0; index < displays.length; index++) {
      // const element = displays[index];
      const scheduled = await Xibo.get(
        `/schedule/${displays[index].displayGroupId}/events?date=${dateNow()}`
      ).then((res) => {
        // console.log(res.data);
        for (let i = 0; i < res.data.events.length - 1; i++) {
          const currentEvent = res.data.events[i];
          const nextEvent = res.data.events[i + 1];
          const ecart = nextEvent.displayOrder - currentEvent.displayOrder;

          if (
            currentEvent.displayOrder % 2 === 0 &&
            nextEvent.displayOrder % 2 === 0
          ) {
            // console.log(
            //   "--- Deux vidéos d'annonceurs sont diffusées à la suite ! ---"
            // );
            // console.log("currentEvent  " + currentEvent.displayOrder);
            // console.log("target ?  " + (nextEvent.displayOrder - 1));
            // console.log("nextevent  " + nextEvent.displayOrder);
            // console.log("-----");

            // on verifie que l'écart entre les deux displayOrder n'est pas supérieur à 2
            if (ecart > 2) {
              alert(
                "Anomalie d'affichage détectée ! \n Veuillez contacter l'administrateur."
              );
            } else {
              // on display le LC default layout
            }
            //  break;
          }
        }
      });
    }
  }

  const handleClickDelete = () => {
    setShowConfirm(false);
    setShowLoading2({ show: true, message: "" });
    let e = eventClickDelete;
    let statutAttente = statutAttenteDelete;
    let layoutId = e.split("_")[0];
    let mediaId = e.split("_")[1];
    // Suppresion du layout qui display sur la borne (piste pour delete media annonceur)
    Xibo.delete("/layout/" + layoutId).then(
      async (res) => {
        // Regarder dans les layouts en cours de diffusion s'il y a deux layout.displayOrder
        CheckDisplayOrders(e);

        let finalfunction = await StopDisplaysProofs(
          mediaId,
          MediaName,
          cookies
        );
        if (finalfunction === true) {
          Xibo.get("/library?mediaId=" + mediaId).then(
            (resGetMedia) => {
              console.log(resGetMedia);
              if (
                resGetMedia &&
                resGetMedia.data &&
                resGetMedia.data.length > 0 &&
                resGetMedia.data[0].ownerId == cookies.user.userId
              ) {
                Xibo.delete("library/" + mediaId).then(
                  (res) => {
                    afterDeleteAnnonce(statutAttente, layoutId);
                  },
                  (err) => console.log(err)
                );
              } else {
                afterDeleteAnnonce(statutAttente, layoutId);
              }
            },
            (err) => console.log(err)
          );
        } else {
          // alert("Une erreur est survenue lors de la suppression de l'annonce.");
          alert(
            "Une erreur est survenue lors de l'enregistrement de l'ordre de suppression de l'annonce."
          );
        }
      },
      (err) => console.log(err)
    );
  };

  const afterDeleteAnnonce = async (statutAttente, layoutId) => {
    if (statutAttente) {
      let rowId = null;
      const resDataSet = await Xibo.get("/dataset/data/2?length=1000");
      for (let i = 0; i < resDataSet.data.length; i++) {
        let row = resDataSet.data[i];
        if (row["Layout id"] == layoutId) {
          rowId = row.id;
          break;
        }
      }
      if (rowId != null) {
        await Xibo.delete("/dataset/data/2/" + rowId);
      }
    }
    setShowLoading2({ show: false, message: "" });
    alert("Annonce supprimée.");
    getLayouts();
  };

  const libraryUpload = async (layoutIndex, medName, media) => {
    console.log("libraryUpload STARTING");
    let mediaName = "";
    inputValue == ""
      ? (mediaName = medName.split(".")[0])
      : (mediaName = inputValue);
    let layoutName =
      layoutIndex + 1 + "__-__" + mediaName + "__" + cookies.user.userId;
    const formData = new FormData();
    formData.append("files", media);
    formData.append("name", layoutName);
    // formData.append("widgetToDt", "2023-01-16 00:01:00");
    // formData.append("expires", "2023-01-14 00:01:00");

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    setShowLoading({ show: true, message: "" });

    try {
      const response = await Xibo.post("/library", formData, config);
      let description = "";
      props.annonceur
        ? (description = "en attente")
        : (description = "médecin");
      const formDataLayout = new FormData();
      formDataLayout.append("name", layoutName);
      formDataLayout.append("description", description);
      formDataLayout.append("resolutionId", 3);
      // formDataLayout.append("fromDt", 1673610841);
      // formDataLayout.append("toDt", 1673952061);

      const config2 = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };

      let permissions = await Xibo.get(
        "/user/permissions/media/" + response.data.files[0].mediaId
      );
      let permId = 0;
      for (let i = 0; i < permissions.data.length; i++) {
        if (permissions.data[i].group == "Users") {
          permId = permissions.data[i].groupId;
        }
      }

      let paramsPerm = new URLSearchParams();
      paramsPerm.append(`groupIds[${permId}][view]`, 1);

      await Xibo.post(
        "/user/permissions/media/" + response.data.files[0].mediaId,
        paramsPerm,
        config2
      );

      console.log("Following paramsPerm data : ");
      console.log(paramsPerm);

      const resLayout = await Xibo.post("/layout", formDataLayout, config);

      console.log("Following formDataLayout data : ");
      console.log(formDataLayout);
      console.log(formDataLayout.resolutionId);
      console.log(resLayout);

      const resLayoutbyParentId = await Xibo.get(
        "/layout?parentId=" +
          resLayout.data.layoutId +
          "&embed=regions,playlists"
      );

      const paramsBackground = new URLSearchParams();
      paramsBackground.append("backgroundColor", "#ffffff");
      const resChangeLayoutBackground = await Xibo.put(
        "/layout/background/" + resLayoutbyParentId.data[0].layoutId,
        paramsBackground,
        config2
      );

      console.log("Following resChangeLayoutBackground data : ");
      console.log(resChangeLayoutBackground);

      const formDataPlaylistAssign = new FormData();

      let duration = 18;
      response.data.files[0].duration <= 18
        ? (duration = response.data.files[0].duration)
        : (duration = 18);
      formDataPlaylistAssign.append("media[]", response.data.files[0].mediaId);
      formDataPlaylistAssign.append("duration", duration);
      const resPlaylistAssign = await Xibo.post(
        "/playlist/library/assign/" +
          resLayoutbyParentId.data[0].regions[0].regionPlaylist.playlistId,
        formDataPlaylistAssign,
        config
      );

      console.log("Following resPlaylistAssign data : ");
      console.log(resPlaylistAssign);

      const paramsPublish = new URLSearchParams();
      paramsPublish.append("publishNow", 1);
      const resPublishLayout = await Xibo.put(
        "/layout/publish/" + resLayoutbyParentId.data[0].parentId,
        paramsPublish,
        config2
      );

      console.log("Following resPublishLayout data : ");
      console.log(resPublishLayout);

      if (props.annonceur) {
        /*     Permet de catégoriser l'annonce en annonceur et demande la validation ADMIN      */
        const getBornes = await Xibo.get("/display");

        console.log("Following getBornes data : ");
        console.log(getBornes);

        let userBornes = "";
        for (let i = 0; i < getBornes.data.length; i++) {
          userBornes += getBornes.data[i].displayGroupId;
          if (i + 1 < getBornes.data.length) {
            userBornes += ";";
          }
        }
        // console.log("new user bornes : " + userBornes);

        const formDataSet = new FormData();
        formDataSet.append("dataSetColumnId_3", cookies.user.userId);
        formDataSet.append(
          "dataSetColumnId_7",
          cookies.user.lastName + " " + cookies.user.firstName
        );
        formDataSet.append("dataSetColumnId_5", layoutName);
        formDataSet.append("dataSetColumnId_6", resPublishLayout.data.layoutId);
        formDataSet.append("dataSetColumnId_4", new Date());
        formDataSet.append("dataSetColumnId_8", cookies.user.email);
        formDataSet.append("dataSetColumnId_9", userBornes);

        const resDataSet = await Xibo.post(
          "dataset/data/2",
          formDataSet,
          config
        );

        const formStats = new FormData();
        formStats.append("enableStat", "On");
        const responseStats = await Xibo.put(
          "/library/setenablestat/" + response.data.files[0].mediaId,
          formStats,
          config
        );

        console.log("Following resDataSet data : ");
        console.log(resDataSet);
      } else {
        //Fonctionnelle pour une diffusion globale des vidéos sur toutes les bornes du médecin, revoir le système pour une diffusion par borne
        let resDisplay = await Xibo.get("/display");

        let groupIds = [];
        let indexVideo = layoutIndex + 1;

        //formule pour toujours avoir un displayOrder impair
        let displayOrder = indexVideo * 2 - 1;
        for (let i = 0; i < resDisplay.data.length; i++) {
          groupIds.push(resDisplay.data[i].displayGroupId);
        }
        console.log(groupIds);
        const formDataSchedule = new FormData();
        formDataSchedule.append("eventTypeId", 1);
        formDataSchedule.append("displayOrder", displayOrder);
        formDataSchedule.append("isPriority", 0);
        formDataSchedule.append("campaignId", resPublishLayout.data.campaignId);
        formDataSchedule.append("displayGroupIds[]", groupIds);
        formDataSchedule.append("dayPartId", 2);
        await Xibo.post("/schedule", formDataSchedule, config);

        console.log("Following formDataSchedule data : ");
        console.log(formDataSchedule);
      }
      setShowLoading({ show: false, message: "Vidéo importée avec succès" });
      getLayouts();

      // console.log(response);
    } catch (error) {
      setShowLoading({ show: false, message: "Erreur innattendue." });
      console.log(error);
      console.log(error.message);
      console.log(error.response);
    }
  };

  // const fillContainer = () => {
  //   let colls = [];
  //   for (let i = 0; i < props.numberOfLayouts; i++) {
  //     let key = i + 1;
  //     let title = <b>{key + " - Aucune vidéo"}</b>;
  //     let statutAnnonce = "";
  //     let statutAttente = false;
  //     let hideInput = "";
  //     let hideDelBtn = "collapse";
  //     let displayVideo = "";
  //     let layId = i;
  //     let medId = i;
  //     let medName = "";

  //     if (layouts.length >= 10) {
  //       console.log("Nombre de vidéos maximum atteint");
  //       console.log(layouts);
  //       console.log("--------------------");
  //     }
  //     for (let j = 0; j < layouts.length; j++) {
  //       // console.log(layouts[j]);
  //       let layoutName = layouts[j].layout.split("__");
  //       let layoutIndex = layoutName[0];

  //       if (window.location.pathname == "/docboard/annonces") {
  //         // if (window.location.pathname == "/adboard/home") {
  //         hideInput = "collapse";
  //       }

  //       if (layoutIndex == key) {
  //         title = <b>{layoutName[0] + " - " + layoutName[2]}</b>;
  //         hideInput = "collapse";
  //         hideDelBtn = "";
  //         layId = layouts[j].layoutId;
  //         if (layouts[j].regions[0].regionPlaylist.widgets.length !== 0) {
  //           // console.log(
  //           //   "-------------------------------------!!!!!!!!!!!!!!!!!!!!!!!-------------------------------------"
  //           // );
  //           // console.log(layouts[j].regions[0].regionPlaylist.widgets);
  //           medId = layouts[j].regions[0].regionPlaylist.widgets[0].mediaIds[0];
  //         }
  //         // medId = layouts[j].regions[0].regionPlaylist.widgets[0].mediaIds[0];
  //         medName = layoutName[2];

  //         displayVideo = (
  //           <VideoPlayer
  //             idVid={j}
  //             src={
  //               `${XiboURL}/api/library/download/` +
  //               medId +
  //               "/video?access_token=" +
  //               accessToken.split(" ")[1]
  //             }
  //           />
  //         );

  //         if (props.annonceur) {
  //           switch (layouts[j].description) {
  //             case "en attente":
  //               statutAnnonce = (
  //                 <Badge variant="warning">En attente de validation</Badge>
  //               );
  //               statutAttente = true;
  //               break;
  //             case "validé":
  //               statutAnnonce = <Badge variant="success">Validé</Badge>;
  //               break;
  //             case "rejeté":
  //               statutAnnonce = (
  //                 <Badge style={{ fontSize: 8.3 }} variant="danger">
  //                   Rejeté - Contactez Lisa Connect pour plus d'informations
  //                 </Badge>
  //               );
  //               break;
  //           }
  //         }

  //         break;
  //       } else {
  //         displayVideo = "";
  //       }
  //     }
  //     colls.push(
  //       <Col xs={3} key={"col" + key} className="mb-2">
  //         <Card key={"card" + key} className="video-card">
  //           <Card.Header>
  //             <span>{title}</span>
  //             <Button
  //               className={hideDelBtn + " float-right"}
  //               size="sm"
  //               variant="danger"
  //               id={layId + "_" + medId + "_buttonDelete"}
  //               onClick={(e) => {
  //                 setMediaName(medName);
  //                 setStatutAttenteDelete(statutAttente);
  //                 setEventClickDelete(layId + "_" + medId);
  //                 setShowConfirm(true);
  //               }}
  //             >
  //               <BsTrash />
  //             </Button>
  //             <span> {statutAnnonce}</span>
  //           </Card.Header>
  //           <Card.Body key={"cardbody" + key} className="modal-center">
  //             {}
  //             <input
  //               className={hideInput + " input-video"}
  //               type="file"
  //               name={i + "file"}
  //               id={i + "file"}
  //               onChange={(e) => handleChange(e)}
  //             />
  //             {displayVideo}
  //           </Card.Body>
  //           <Card.Footer className={hideInput + " footer-add-btns"}>
  //             <Button
  //               variant="primary"
  //               id={i + "buttonUpload"}
  //               onClick={(e) => handleClickInput(e)}
  //               className={"add-buttons"}
  //             >
  //               Ajouter une vidéo
  //             </Button>
  //           </Card.Footer>
  //         </Card>
  //       </Col>
  //     );
  //   }
  //   // if (window.location.pathname == "/adboard/home") {
  //   //   console.log("OON EST DEDANS");
  //   //   // document.getElementsByName("input-video").forEach((el) => {
  //   //   //   el.classList.remove("collapse");
  //   //   // });
  //   //   if (document.getElementsByClassName("input-video").length > 0) {
  //   //     document.getElementsByClassName("input-video").forEach((el) => {
  //   //       el.classList.remove("collapse");
  //   //     });
  //   //   }
  //   //   // document.getElementsByName("footer-add-btns").forEach((el) => {
  //   //   //   console.log(el);
  //   //   //   el.classList.remove("collapse");
  //   //   // });
  //   //   if (document.getElementsByClassName("footer-add-btns").length > 0) {
  //   //     document.getElementsByClassName("footer-add-btns").forEach((el) => {
  //   //       el.classList.remove("collapse");
  //   //     });
  //   //   }
  //   //   let target = document.getElementsByClassName("footer-add-btns");
  //   //   console.log(target);
  //   // }

  //   let row = (
  //     <section>
  //       <Row id="video-row-bloc">{colls}</Row>
  //       <div
  //         className="novalidatedvideo"
  //         id="novalidatedvideo"
  //         style={{ marginLeft: "40px", display: "none" }}
  //       >
  //         <br /> <br />
  //         Aucune vidéo validée.
  //         <br /> <br />
  //         Contactez votre référent Lisa Connect ou envoyez un email à
  //         contact@lisaconnect.fr
  //       </div>
  //     </section>
  //   );

  //   // if (layouts.length == 0 && window.location.pathname == "/adboard/home") {
  //   //   // document.getElementsByClassName
  //   //   let target = document.getElementById("video-row-bloc");
  //   //   if (target) {
  //   //     target.style.display = "none";
  //   //     document.getElementById("novalidatedvideo").style.display = "unset";
  //   //   }
  //   // }

  //   // else {
  //   //   document.getElementById("novalidatedvideo").style.display = "none";
  //   // }
  //   // if (layouts.length == 0 && window.location.pathname == "/adboard/home") {
  //   //   row = (
  //   //     <Row style={{ width: "100vw", marginLeft: "40px" }}>
  //   //       <br /> <br />
  //   //       Aucune vidéo validée.
  //   //       <br /> <br />
  //   //       Contactez votre référent Lisa Connect ou envoyez un email à
  //   //       contact@lisaconnect.fr
  //   //     </Row>
  //   //   );
  //   // }
  //   return row;
  // };

  const fillContainer = () => {
    let colls = [];
    for (let i = 0; i < props.numberOfLayouts; i++) {
      let key = i + 1;
      let title = <b>{key + " - Aucune vidéo"}</b>;
      let statutAnnonce = "";
      let statutAttente = false;
      let hideInput = "";
      let hideDelBtn = "collapse";
      let displayVideo = "";
      let layId = i;
      let medId = i;
      let medName = "";

      if (videoOrder.length >= 10) {
        console.log("Nombre de vidéos maximum atteint");
        console.log(videoOrder);
        console.log("--------------------");
      }
      for (let j = 0; j < videoOrder.length; j++) {
        // console.log(videoOrder[j]);
        let layoutName = videoOrder[j].layout.split("__");
        let layoutIndex = layoutName[0];

        if (window.location.pathname == "/docboard/annonces") {
          // if (window.location.pathname == "/adboard/home") {
          hideInput = "collapse";
        }

        if (layoutIndex == key) {
          title = <b>{layoutName[0] + " - " + layoutName[2]}</b>;
          hideInput = "collapse";
          hideDelBtn = "";
          layId = videoOrder[j].layoutId;
          if (videoOrder[j].regions[0].regionPlaylist.widgets.length !== 0) {
            // console.log(
            //   "-------------------------------------!!!!!!!!!!!!!!!!!!!!!!!-------------------------------------"
            // );
            // console.log(videoOrder[j].regions[0].regionPlaylist.widgets);
            medId =
              videoOrder[j].regions[0].regionPlaylist.widgets[0].mediaIds[0];
          }
          // medId = videoOrder[j].regions[0].regionPlaylist.widgets[0].mediaIds[0];
          medName = layoutName[2];

          displayVideo = (
            <VideoPlayer
              idVid={j}
              src={
                `${XiboURL}/api/library/download/` +
                medId +
                "/video?access_token=" +
                accessToken.split(" ")[1]
              }
            />
          );

          if (props.annonceur) {
            switch (videoOrder[j].description) {
              case "en attente":
                statutAnnonce = (
                  <Badge variant="warning">En attente de validation</Badge>
                );
                statutAttente = true;
                break;
              case "validé":
                statutAnnonce = <Badge variant="success">Validé</Badge>;
                break;
              case "rejeté":
                statutAnnonce = (
                  <Badge style={{ fontSize: 8.3 }} variant="danger">
                    Rejeté - Contactez Lisa Connect pour plus d'informations
                  </Badge>
                );
                break;
            }
          }

          break;
        } else {
          displayVideo = "";
        }
      }
      colls.push(
        <Col
          xs={3}
          key={"col" + key}
          className="mb-2"
          style={{ maxWidth: "400px" }}
        >
          <Card key={"card" + key} className="video-card">
            <Card.Header>
              <span>{title}</span>
              <Button
                className={hideDelBtn + " float-right"}
                size="sm"
                variant="danger"
                id={layId + "_" + medId + "_buttonDelete"}
                onClick={(e) => {
                  setMediaName(medName);
                  setStatutAttenteDelete(statutAttente);
                  setEventClickDelete(layId + "_" + medId);
                  setShowConfirm(true);
                }}
              >
                <BsTrash />
              </Button>
              <span> {statutAnnonce}</span>
            </Card.Header>
            <Card.Body key={"cardbody" + key} className="modal-center">
              {}
              <input
                className={hideInput + " input-video"}
                type="file"
                name={i + "file"}
                id={i + "file"}
                onChange={(e) => handleChange(e)}
              />
              {displayVideo}
            </Card.Body>
            <Card.Footer className={hideInput + " footer-add-btns"}>
              <Button
                variant="primary"
                id={i + "buttonUpload"}
                onClick={(e) => handleClickInput(e)}
                className={"add-buttons"}
              >
                Ajouter une vidéo
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      );
    }
    // if (window.location.pathname == "/adboard/home") {
    //   console.log("OON EST DEDANS");
    //   // document.getElementsByName("input-video").forEach((el) => {
    //   //   el.classList.remove("collapse");
    //   // });
    //   if (document.getElementsByClassName("input-video").length > 0) {
    //     document.getElementsByClassName("input-video").forEach((el) => {
    //       el.classList.remove("collapse");
    //     });
    //   }
    //   // document.getElementsByName("footer-add-btns").forEach((el) => {
    //   //   console.log(el);
    //   //   el.classList.remove("collapse");
    //   // });
    //   if (document.getElementsByClassName("footer-add-btns").length > 0) {
    //     document.getElementsByClassName("footer-add-btns").forEach((el) => {
    //       el.classList.remove("collapse");
    //     });
    //   }
    //   let target = document.getElementsByClassName("footer-add-btns");
    //   console.log(target);
    // }

    let row = (
      <section>
        <Row id="video-row-bloc">{colls}</Row>
        <div
          className="novalidatedvideo"
          id="novalidatedvideo"
          style={{ marginLeft: "40px", display: "none" }}
        >
          <br /> <br />
          Aucune vidéo validée.
          <br /> <br />
          Contactez votre référent Lisa Connect ou envoyez un email à
          contact@lisaconnect.fr
        </div>
      </section>
    );

    // if (layouts.length == 0 && window.location.pathname == "/adboard/home") {
    //   // document.getElementsByClassName
    //   let target = document.getElementById("video-row-bloc");
    //   if (target) {
    //     target.style.display = "none";
    //     document.getElementById("novalidatedvideo").style.display = "unset";
    //   }
    // }

    // else {
    //   document.getElementById("novalidatedvideo").style.display = "none";
    // }
    // if (layouts.length == 0 && window.location.pathname == "/adboard/home") {
    //   row = (
    //     <Row style={{ width: "100vw", marginLeft: "40px" }}>
    //       <br /> <br />
    //       Aucune vidéo validée.
    //       <br /> <br />
    //       Contactez votre référent Lisa Connect ou envoyez un email à
    //       contact@lisaconnect.fr
    //     </Row>
    //   );
    // }
    return row;
  };

  const checkURL = () => {
    if (window.location.pathname == "/adboard/home") {
      console.log("OON EST DEDANS");
      // document.getElementsByName("input-video").forEach((el) => {
      //   el.classList.remove("collapse");
      // });
      if (document.getElementsByClassName("input-video").length > 0) {
        document.getElementsByClassName("input-video").forEach((el) => {
          el.classList.remove("collapse");
        });
      }
      // document.getElementsByName("footer-add-btns").forEach((el) => {
      //   console.log(el);
      //   el.classList.remove("collapse");
      // });
      if (document.getElementsByClassName("footer-add-btns").length > 0) {
        document.getElementsByClassName("footer-add-btns").forEach((el) => {
          el.classList.remove("collapse");
        });
      }
      let target = document.getElementsByClassName("footer-add-btns");
      console.log(target);
    }
  };

  const changeInput = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <div>
      <Container className="mt-1">
        {window.location.pathname == "/adboard/home" ? (
          <h1 style={{ marginTop: "20px", marginBottom: "20px" }}>
            Vos annonces
          </h1>
        ) : (
          <h1 style={{ marginTop: "20px", marginBottom: "20px" }}>
            {/* Importez vos annonces */}
            Vidéos en diffusion
          </h1>
        )}
        {window.location.pathname == "/docboard/annonces" ? (
          <p style={{ marginTop: "35px", marginBottom: "25px" }}>
            {/* <i>
              Seules les vidéos au format mp4 sont supportées. Il est recommandé
              de n'utiliser que des vidéos au format portrait (1080x1920).
            </i> */}
            {/* <BsFillInfoCircleFill style={{ color: "#6ebaa7" }} /> */}
            <BsFillInfoCircleFill style={{ color: "#f8b5a8" }} />
            <i> </i>{" "}
            <i>
              {" "}
              Pour importer des vidéos que vous avez créées, faîtes les parvenir
              par email à{" "}
              <a
                href="mailto:media@lisaconnect.fr"
                style={{ color: "#6ebaa7" }}
              >
                {" "}
                media@lisaconnect.fr
              </a>{" "}
              avec les numéros des bornes concernées.
            </i>
          </p>
        ) : (
          <></>
        )}
        {/* <AnnoncesAuto /> */}
        {fillContainer()}
        {props.annonceur && (
          <p style={{ fontSize: 10 }}>
            {/* <i>
              Avant d'être diffusées vos annonces seront soumises à la
              validation des administrateurs dans un délai de 48 heures après
              leur mise en ligne.
            </i> */}
          </p>
        )}
      </Container>
      <ConfirmationModal
        show={showConfirm}
        closeModal={closeConfirm}
        message="Supprimer votre annonce ?"
        confirm={handleClickDelete}
      />
      <LoadingModal show={showLoading2.show} />
      <LoadingModal show={showLoading.show} />
      <InputModal
        show={showInput}
        message="Nommez votre annonce"
        onChange={changeInput}
        closeModal={closeInput}
        value={inputValue}
        confirm={() => {
          closeInput();
          libraryUpload(currIndex, upload[currIndex].name, upload[currIndex]);
        }}
      />
    </div>
  );
}

export default AnnoncesContainer;
